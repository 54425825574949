import axios from "axios";
import { useDispatch, useSelector } from "react-redux";


const API_URL = process.env.REACT_APP_BASE_PATH;

const IsLogin = () => {
  const { isLoggedIn } = useSelector(state => state.auth);
  return isLoggedIn;
};

const register = (username, email, password) => {
  return axios.post(API_URL + "UserSignUp", {
    username,
    email,
    password,
  });
};

const login = (email, password, facebook) => {
  return axios
    .post(API_URL + "weblogin", {
      email,
      password,
      facebook
    })
    .then((response) => {
        if(!response.error){
        localStorage.setItem("user", JSON.stringify(response));
        for (const [key, value] of Object.entries(response.data)) {
          localStorage.setItem(key, value);
        }
      }
      return response;
    });
};

const logout = () => {
  localStorage.clear();
};

export default {
  register,
  login,
  logout,
  IsLogin
};