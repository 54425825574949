import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Homepage from './Pages/Homepage';
import Error404 from './Pages/Error';
import Login from './Pages/Login';
import Register from './Pages/Register';
import Profile from './Pages/Profile';
import Forgot from './Pages/Forgot';
import Contacts from './Pages/Contacts';
import PageContent from "./Pages/PageContent";
import RideListing from "./Pages/service/Ride/RideListing";
import StayListing from "./Pages/service/Stay/StayListing";
import StayDetail from "./Pages/service/Stay/StayDetail";
import GuideListing from "./Pages/service/Guide/GuideListing";
import GuideDetail from "./Pages/service/Guide/GuideDetail";
import RideDetail from "./Pages/service/Ride/RideDetail";
import PackageDetail from "./Pages/service/Package/PackageDetail";
import PilgrimDetail from "./Pages/service/Pilgrim/PackageDetail";
import Booking from "./Pages/Common/Booking";
import Payment from "./Pages/Common/Payment";
import PackageListing from "./Pages/service/Package/PackageListing";
import CustomPackage from "./Pages/service/Package/CustomPackage";
import PilgrimListing from "./Pages/service/Pilgrim/PackageListing";
import InfoPageListing from "./Pages/InfoPages/InfoPageListing";
import InfoPageDetail from "./Pages/InfoPages/InfoPageDetail";
import Rating from "./Pages/Common/Rating";
import Complain from "./Pages/Common/Complain";
import HelplineListing from "./Pages/Helpline/HelplineListing";
import UserContent from "./Pages/Member/UserContent";


class Markup extends Component {
    render() {
        return (
            <BrowserRouter basename="/react">
                <div className="page-wraper">
                    <Switch>
                        <Route path='/' exact component={Homepage} />
                        <Route path='/error' exact component={Error404} />
                        <Route path='/login' exact component={Login} />
                        <Route path='/register' exact component={Register} />
                        <Route path='/profile' exact component={Profile} />
                        <Route path='/forgot' exact component={Forgot} />
                        <Route path='/contact' component={Contacts} />
                        <Route path='/custompackage' component={CustomPackage} />
                        <Route path="/page/:title/:id" component={PageContent} />
                        <Route exact path="/member/:id" component={UserContent} />
                        <Route exact path="/member/:id/:category" component={UserContent} />
                        <Route exact path="/ride/:title/:catid/:id" component={RideListing} />
                        <Route exact path="/stay/:title/:catid/:id" component={StayListing} />
                        <Route exact path="/guide/:title/:catid/:id" component={GuideListing} />
                        <Route exact path="/Trip+Packages/:title/:catid/:id" component={PackageListing} />
                        <Route exact path="/Package/:title/:catid/:id" component={PackageListing} />
                        <Route exact path="/Pilgrimage+trip/:title/:catid/:id" component={PilgrimListing} />
                        <Route exact path="/Pilgrim/:title/:catid/:id" component={PilgrimListing} />
                        <Route path="/staydetail/:title/:id/:infoid" component={StayDetail} />
                        <Route path="/ridedetail/:title/:id/:infoid" component={RideDetail} />
                        <Route path="/guidedetail/:title/:id/:infoid" component={GuideDetail} />
                        <Route path="/packagedetail/:title/:id/:infoid" component={PackageDetail} />  
                        <Route path="/pilgrimdetail/:title/:id/:infoid" component={PilgrimDetail} />            
                        <Route path="/booking/:category/:id" component={Booking} />
                        <Route path="/payment/:booking_id" component={Payment} />
                        <Route path="/rating/:booking_id/:user_to" component={Rating} />
                        <Route path="/complain/:id" component={Complain} />
                        <Route path="/helpline" component={HelplineListing} />
                        <Route path="/infopage/:title/:id" component={InfoPageListing} ></Route>
                        <Route path="/InfoPageDetail/:title/:id" component={InfoPageDetail} ></Route>

                    </Switch>
                </div>
            </BrowserRouter>

        )
    }
}

export default Markup;