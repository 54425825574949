import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";

import Profile from "./../Profile";
import Account from "./Account";
import MyBookingList from "./MyBookingList";
import MyServiceOrder from "./MyServiceOrder";
import Invite from "./Invite";
import CommingSoon from "../Common/CommingSoon";

import Notification from "./Notification";
import MyInfoAdd from "../InfoPages/MyInfoAdd";
import MyInfoList from "../InfoPages/MyInfoList";
import Dashboard from "./Dashboard";
import Header from './../../Layout/Header';
import Footer from './../../Layout/Footer';


class UserContent extends React.Component {
  constructor(props) {
    super(props);
    var login = false;
    if (parseInt(localStorage.getItem("id")) > 0) {
      login = true;
    }
    this.state = { page: null, login: login };
    this.setState({ page: props.match.params.id });
  }
  pageload(page) {
    const { classes } = this.props;
    const { state } = this;
    var page = this.props.match.params.id;
    if (page === "dashboard") {
      return <Dashboard params={this.props.match.params}></Dashboard>;
    } else if (page === "profile") {
      return <Profile params={this.props.match.params}></Profile>;
    } else if (page === "account") {
      return <Account params={this.props.match.params}></Account>;
    } else if (page === "mybooking") {
      return <MyBookingList params={this.props.match.params}></MyBookingList>;
    } else if (page === "myorder") {
      return <MyServiceOrder params={this.props.match.params}></MyServiceOrder>;
    } else if (page === "invite") {
      return <Invite params={this.props.match.params}></Invite>;
    } else if (page === "notification") {
      return <Notification params={this.props.match.params}></Notification>;
    } else if (page === "infopages") {
      return <MyInfoList params={this.props.match.params}></MyInfoList>;
    } else if (page === "infopageadd") {
      return <MyInfoAdd params={this.props.match.params}></MyInfoAdd>;
    } else {
      return <CommingSoon params={this.props.match.params}></CommingSoon>;
    }
  }
  render() {
    if (!this.state.login) {
      return <Redirect to="/login" />;
    }
    return (
      <>
        <Header />
        <div className="section-full content-inner bg-white contact-style-1">
          <div className="container">
            {this.pageload()}
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default UserContent;
