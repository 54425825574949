import React, { Component } from 'react'
import Select from 'react-select';
import Autocomplete from 'react-google-autocomplete';
import ImageUploader from 'react-images-upload';
import axios from 'axios';
import Header from '../Layout/Header';
import Footer from './../Layout/Footer';


class Profile extends React.Component {
  state = { value: 0 };
  constructor(props) {
    super(props);
    this.state.info = [];
    this.state.pictures = [];
    this.state.cat_list = [];
    this.state.selected = null;
    this.state.fileupload = null;
    var picture = localStorage.getItem("picture");
    if (picture && picture.includes("profiles")) {
      this.state.picture = process.env.REACT_APP_BASE_PATH + picture;
    }
    this.state.form = null;
    var user_id = localStorage.getItem("id");
    this.state.id = user_id;
    this.getList();
    //this.getInfo(user_id);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.file_upload = this.file_upload.bind(this);
  }

  getInfo = (id) => {

    const request = new Request(process.env.REACT_APP_BASE_PATH + "user/" + id, { method: "GET" });
    fetch(request).then(res => {
      res.json().then(obj => {
        this.setState({ info: obj });
        var currency_id = obj.currency_id ? obj.currency_id.id : 1;
        var item = this.GetSelectVal(currency_id);
        this.setState({ selected: item });
        return obj;
      });
    }).catch(err => {
      console.log("Error in Get menu", err);
    });
  };
  GetSelectVal(val) {
    var result = null;
    for (var i = 0; i < this.state.cat_list.length; i++) {
      if (this.state.cat_list[i].value == val) {
        result = this.state.cat_list[i];
      }
    }
    return result;
  }
  getList = () => {
    const request = new Request(process.env.REACT_APP_BASE_PATH + "Currency?status=true", { method: "GET" });
    fetch(request).then(res => {
      res.json().then(obj => {
        var listItems = [];
        Object.keys(obj).forEach(function (key) {
          listItems.push({ "value": obj[key].id, "label": obj[key].title });
        });
        this.setState({ cat_list: listItems });
        this.getInfo(this.state.id);
      });
    }).catch(err => {
      console.log("Error in Get menu", err);
    });
  };

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ form: event.target });
    if (this.state.fileupload) {
      this.file_upload(this.state.fileupload);
    } else {
      var post_data = new FormData(event.target);
      this.update_profile(post_data);
    }
  };
  file_upload(fileupload) {
    const filedata = new FormData()
    filedata.append('fileupload', fileupload);
    axios.post(process.env.REACT_APP_BASE_PATH + "uploadprofileimg", filedata, {}).then(data => { // then print response status
      var post_data = new FormData(this.state.form);
      var picture = data.data.filename;
      post_data.append("picture", picture);
      localStorage.setItem("picture", picture);
      if (picture && picture.includes("profiles")) {
        this.setState({ picture: process.env.REACT_APP_FILE_PATH + picture });
      }
      this.update_profile(post_data);
    })
  }
  update_profile(post_data) {
    const request = new Request(process.env.REACT_APP_BASE_PATH + "user/" + this.state.id, { method: "PUT", body: post_data });
    fetch(request).then(res => {
      res.json().then(json => {
        if (json.message) {
          this.setState({ message: <div class="danger">{json.message}</div> });
        } else {
          this.setState({ message: <div class="success">Account updated successfully!</div> });
        }
      });
    }).catch(err => {
      this.setState({ message: <div class="danger">{err.message}</div> });
      console.log("Error in Get menu", err.message);
    });
  };
  onChangeHandler(event) {
    this.setState({ fileupload: event[0] });
  }
  handleChange = selectedOption => {
    this.setState({ selected: selectedOption });
  };
  render() {
    return (
      <form id="myform" onSubmit={this.handleSubmit}>
        <div class="myform1">
          <h2 class="m-b10">Edit Profile</h2>
          {this.state.message}
          <input type="hidden" value={this.state.id} id="id" name="id"></input>
          <div className="form-group">
            <label>Name</label>
            <input type="text" defaultValue={this.state.info.name} id="name" name="name" className="form-control" placeholder="First name" required />
          </div>

          <div className="form-group">
            <label>Picture</label>
            <ImageUploader
              withIcon={false}
              singleImage={true}
              name="fileupload"
              id="fileupload"
              buttonText='Choose images'
              onChange={this.onChangeHandler}
              withPreview={true}
              imgExtension={['.jpg', '.png', '.jpeg']}
              maxFileSize={1240000}
            />
          </div>
          <div className="form-group">
            <label>Mobile</label>
            <input type="phone" defaultValue={this.state.info.mobile} id="mobile" name="mobile" className="form-control" placeholder="Enter mobile" required />
          </div>

          <div className="form-group">
            <label>Email</label>
            <input type="email" value={this.state.info.email} id="email" name="email" className="form-control" placeholder="Enter email" required />
          </div>
          <div className="form-group">
            <label>Currency</label>
            <Select id="currency_id" name="currency_id" options={this.state.cat_list}
              value={this.state.selected}
              onChange={this.handleChange}
            />
          </div>

          <div className="form-group">
            <label>Address</label>
            <div class="input-group">
              <div class="input-group-addon"><span class="fa fa-map-marker"></span></div>
              <Autocomplete
                name="address"
                id="address"
                class="form-control"
                defaultValue={this.state.info.address}
                onPlaceSelected={(place) => {
                  this.setState({
                    info: {
                      latitude: place.geometry.location.lat(),
                      longitude: place.geometry.location.lng(),
                      city: place.address_components[place.address_components.length - 3].long_name,
                      country: place.address_components[place.address_components.length - 1].long_name
                    }
                  });
                }}
                types={['(regions)']}
                autocomplete="off"
                componentRestrictions={{ country: "pk" }} />
              <input type="hidden" defaultValue={this.state.info.latitude} id="latitude" name="latitude" className="form-control" required />
              <input type="hidden" defaultValue={this.state.info.longitude} id="longitude" name="longitude" className="form-control" required />
            </div>
          </div>
          <div className="form-group">
            <label>City</label>
            <input type="text" defaultValue={this.state.info.city} id="city" name="city" className="form-control" placeholder="Enter city" required />
          </div>
          <div className="form-group">
            <label>Country</label>
            <input type="text" id="country" defaultValue={this.state.info.country} name="country" className="form-control" placeholder="Enter country" required />
          </div>

          <div className="form-group">
            <label>Password</label>
            <input type="password" defaultValue={this.state.info.password} id="password" name="password" className="form-control" placeholder="Enter password" required />
          </div>

          <button type="submit" className="btn btn-primary btn-block">Submit</button>
        </div>
        <div class="myform1 col-lg-4 col-md-6 col-sm-12">
          <img class="profile_pic" src={this.state.picture} ></img>
        </div>
      </form>
    );
  }
}


export default (Profile);
