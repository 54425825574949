import React from 'react'
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import { Link } from 'react-router-dom';
import Header from '../Layout/Header';
import Footer from './../Layout/Footer';
import Tab2 from './../Pages/Tab2';
import common from '../services/common';
import HomeRideListing from './service/Ride/HomeRideListing';
import HomeStayListing from './service/Stay/HomeStayListing';
import HomeGuideListing from './service/Guide/HomeGuideListing';
import HomePackageListing from './service/Package/HomePackageListing';

var bg1 = require('./../../images/background/bg1.jpg').default;
var bg2 = require('./../../images/background/bg2.jpg').default;
var bg3 = require('./../../images/background/bg3.jpg').default;

class Homepage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { slide_list: [] };
        common.home_slider().then((result) =>
            this.setState({ slide_list: result })
        );
    }
    render() {

        var ItemList = this.state.slide_list;
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
        };

        return (
            <div>

                <Header />
                <Slider className="slider-wrapper" {...settings}>
                    {ItemList.map((item, index) => (
                        <div
                            key={index}
                            className="slider-content"
                            style={{ background: `url('${process.env.REACT_APP_FILE_PATH + "/uploads/picture/" + item.picture}') no-repeat center center` }}
                        >
                            <div className="inner">
                                <h1 className="react-slide-title">{item.title}</h1>
                            </div>
                        </div>
                    ))}
                </Slider>
                <div className="content-block" id="page_content">
                    <Tab2 />

                    <div className="section-full bg-white content-inner-2">
                        <div className="container">
                            <div className="section-head style1 text-black text-center">
                                <h2>Latest Package</h2>
                                <div className="dlab-separator bg-primary"></div>
                            </div>
                            <HomePackageListing />
                        </div>
                    </div>

                    <div className="section-full content-inner bg-img-fix overlay-black-middle" style={{ backgroundImage: "url(" + bg1 + ")" }}>
                        <div className="container">
                            <div className="video-banner-box">
                                <div>
                                    <h2 className="title">Latest Service Posted</h2>
                                    <h4 className="sub-title">Book your car, bus or any vehicle today</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full content-inner bg-gray">
                        <div className="container">
                            <HomeRideListing />
                        </div>
                    </div>

                    <div className="section-full content-inner bg-img-fix overlay-black-middle" style={{ backgroundImage: "url(" + bg2 + ")" }}>
                        <div className="container">
                            <div className="video-banner-box">
                                <div>
                                    <h2 className="title">Latest Stay</h2>
                                    <h4 className="sub-title">Book your hotel, home or camp today</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full content-inner bg-gray">
                        <div className="container">
                            <HomeStayListing />
                        </div>
                    </div>

                    <div className="section-full content-inner bg-img-fix overlay-black-middle" style={{ backgroundImage: "url(" + bg3 + ")" }}>
                        <div className="container">
                            <div className="video-banner-box">
                                <div>
                                    <h2 className="title">Latest Guides</h2>
                                    <h4 className="sub-title">Get information about traveling and places</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-full content-inner bg-gray">
                        <div className="container">
                            <HomeGuideListing />
                        </div>
                    </div>

                </div>
                <Footer />

            </div>
        )
    }
}
export default Homepage;