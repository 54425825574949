import React, { Component } from 'react'
import RideFilter from './RideFilter';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Header from './../../../Layout/Header';
import Footer from './../../../Layout/Footer';
import ListBox1 from './../../ListBox1';

class RideListing extends React.Component {
  constructor(props){
    super(props);
    props.match.params.class = "col-lg-12 col-md-12 col-sm-12 col-xs-12";
    var search_filter = this.props.location.search_filter ? this.props.location.search_filter : {};//if data posted from home page filter
    this.state = {page: props.match.params.id, list:[],filter_data:[], distace:null, search_filter: search_filter};

    this.handlerlisting = this.handlerlisting.bind(this);
    this.RideSearching  = this.RideSearching.bind(this);

  }
  RideSearching(post_data , pre_state) {
    this.setState({filter_data: pre_state});
    console.log("pre search state", pre_state);

    const request = new Request(process.env.REACT_APP_BASE_PATH+"RideFilter" , {method: "POST", body: post_data});
    fetch(request).then(res => {
    res.json().then(json => {	
        console.log('json...', json);
        this.setState({list: json});	            
      });
    }).catch(err => {
        this.setState({message: <div class="danger">{err.message}</div>});
        console.log("Error in Get menu", err.message);
    });
  };

  handlerlisting( ItemList ) {
    console.log("set sta is called", ItemList);
    var picture = "";
    var booked_label = "";
    if(ItemList && ItemList.length > 0){
      return ItemList.map( (item) => {
        picture = item.picture ? item.picture.split(",")[0] : "upload/default.png";
        picture = process.env.REACT_APP_FILE_PATH+picture;
        booked_label = "";
        if(item.booked > 0){
          if(item.subcategory_id == 6){ //share ride
            if(item.booked >= item.person){
              booked_label = <span class='label label-danger'>Booked</span>;
            }
          } else {
            booked_label = <span class='label label-danger'>Booked</span>;
          }
        }
        var rent_price = "";
        var remaining = item.seats - item.booked;
        if(item.rent_type == 1){ //KM
          rent_price =  <ul className="icon-box-list list-col-4">
              <li><span>From 0-25/KM: {item.price}</span></li>
              <li><span>From 25-50/KM: {item.price_25_50_km}</span></li>
              <li><span>From 50_500/KM: {item.price_50_500_km}</span></li>
            </ul>;
        } else if(item.rent_type == 3) { //Seat
          rent_price =  item.price_seat+'/Seat';  
        } else { //day
          rent_price =  item.price_day+'/Day';   
        }
        var param = {
          picture: picture, 
          title: item.company,
          category: item.category,
          price: rent_price,
          username: item.user_name,
          booked: item.booked,
          remaining: remaining,
          item_list: item.city.split(",").slice(0,4),
          rating: 3,
          link: {pathname:"/Ridedetail/"+item.category+"/"+item.subcategory_id+"/"+item.id, state: this.state.filter_data}
        }

        return (
          <ListBox1 param={param} key={item.id} />
        )
      })
    }
  }
  render() {
    return (
      <>
        <Header />
        <div className="listing-details-head">
          <div className="container">
            <div className="hotel-booking">
            <RideFilter page="listing" params={this.props.match.params} RideSearching={this.RideSearching} search_filter={this.state.search_filter}></RideFilter>
            </div>
            <div className="hotel-booking">
              <div class="row">{this.handlerlisting(this.state.list)}</div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default (RideListing);
