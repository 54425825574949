import React from 'react'
import ListBox1 from './../../ListBox1';

class HomeRideListing extends React.Component {
  constructor(props){
    super(props);
    this.state = {page:null};
    this.setState({list:[]});

    this.handlerlisting = this.handlerlisting.bind(this);
    this.RideSearching  = this.RideSearching.bind(this);

    this.RideSearching();
  }
  RideSearching() {
    var post_data  = new FormData();
    post_data.set("limit", "4");
    const request = new Request(process.env.REACT_APP_BASE_PATH+"RideFilter" , {method: "POST", body: post_data});
    fetch(request).then(res => {
    res.json().then(json => {	
        this.setState({list: json});	            
      });
    }).catch(err => {
        this.setState({message: <div class="danger">{err.message}</div>});
        console.log("Error in Get menu", err.message);
    });
  };

  handlerlisting( ItemList ) {
    var picture = "";
    if(ItemList && ItemList.length > 0){
      return ItemList.map( (item) => {
        picture = item.picture ? item.picture.split(",")[0] : "upload/default.png";
        picture = process.env.REACT_APP_FILE_PATH+picture;

        var remaining = item.seats - item.booked;
        var rent_price = "";
        if(item.rent_type == 1){ //KM
          rent_price =  <ul className="icon-box-list list-col-4">
              <li><span>From 0-25/KM: {item.price}</span></li>
              <li><span>From 25-50/KM: {item.price_25_50_km}</span></li>
              <li><span>From 50_500/KM: {item.price_50_500_km}</span></li>
            </ul>          
        } else if(item.rent_type == 3) { //Seat
          rent_price =  item.price_seat+'/Seat';  
        } else { //day
          rent_price =  item.price_day+'/Day';   
        }

        var param = {
          picture: picture, 
          title: item.company,
          category: item.category,
          price: rent_price,
          username: item.user_name,
          booked: item.booked,
          remaining: remaining,
          type : "ride",
          item_list: item.city.split(",").slice(0,4),
          rating: 3,
          link: {pathname:"/Ride/"+item.category?.replaceAll(' ', '+')+"/"+1+'/'+item.subcategory_id, state: this.state.filter_data}
        }

        return (
          <ListBox1 param={param} key={item.id} />
        )
      })
    }
  }
  render() {
    return (
        <div class="row">
          { this.handlerlisting(this.state.list) }
        </div>
    );
  }
}

export default (HomeRideListing);
