import axios from "axios";
const API_URL = process.env.REACT_APP_BASE_PATH;

class common {
    static home_slider = () => {
        return axios
            .get(API_URL + "slides?category=0")
            .then((response) => {
                console.log("get ", response.data);
                return response.data;
            });
    };
    static user_id = () => {
        var user_id = 0;
        var data = localStorage.getItem("user");
        console.log("OK", data);
        if (data) {
            user_id = (JSON.parse(data)).data.id;
            console.log("OK", JSON.parse(data));
        }
        return user_id;
    };
    static user_name = () => {
        var user_id = 0;
        var data = localStorage.getItem("user");
        if (data) {
            user_id = (JSON.parse(data)).data.name;
        }
        return user_id;
    };
    static getCurrencyValue = (currency) => {
        return currency === 'Rs' ? 'PKR' : currency
    }
}
export default common;