import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link,Redirect } from "react-router-dom";
import Login from "./../Login";
import Signup from "./../Register";
import { Widget,addResponseMessage, addLinkSnippet, addUserMessage,toggleWidget } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';

export default class Chat extends React.Component {
    constructor(props){
        super(props);
        this.props = props;
        this.state = {timer:null,open:false,param: props.param, user_info: {},user_list:[], user_id:localStorage.getItem("id")};
        this.ShowWidget = this.ShowWidget.bind(this);
        this.toggleChat = this.toggleChat.bind(this);
        this.UserChatList = this.UserChatList.bind(this);
        this.StartInterval = this.StartInterval.bind(this);
        this.StopInterval = this.StopInterval.bind(this);
        this.CheckNewChatMessage = this.CheckNewChatMessage.bind(this);
        this.StartInterval();
    };


    StartInterval(){
      var timer = setInterval(function() {
        this.CheckNewChatMessage(this.state.user_info);
        this.setState({timer: timer});
      }.bind(this), 5000);
    }

    StopInterval(){
      clearInterval(this.state.timer);
      this.setState({timer: null});
    }


    CheckNewChatMessage(user_info){
      if(user_info.id  && user_info.id > 0){
        const request = new Request(process.env.REACT_APP_BASE_PATH+"ChatMessage/"+this.state.user_id+"/"+user_info.id,{method: "GET"});
          fetch(request).then(res => res.json()).then(
          (result) => {
            if(result.length > 0){
              result.forEach(chat => {     
                addResponseMessage(chat.message);                 
              })
            }
          },
          (error) => {
              this.setState({ error });
          })
      }
    }
    toggleChat(user_info){
      this.setState({user_info: user_info, open: true});
      toggleWidget();
      
      //clear previous chat
      setTimeout(function(){ 
        var elements = document.getElementsByClassName("rcw-message");
        while(elements.length > 0){
            elements[0].remove();
        }
        this.UserChatList(user_info);  
      }.bind(this), 1000);
    }
    UserChatList(user_info){
        const request = new Request(process.env.REACT_APP_BASE_PATH+"ChatList/"+this.state.user_id+"/"+user_info.id,{method: "GET"});
        fetch(request).then(res => res.json()).then(
        (result) => {
            this.setState({user_list: result});
            result.forEach(chat => {     
              if(chat.user_by == this.state.user_id){
                addResponseMessage(chat.message);
              } else {
                addUserMessage(chat.message);
              }                  
            })
        },
        (error) => {
            this.setState({ error });
        })
    }

    handleNewUserMessage = (newMessage) => {
      console.log(`New message incoming! ${newMessage}`);
      // Now send the message throught the backend API
      //addResponseMessage(response);
      var post_data  = new FormData();  
      post_data.append("message", newMessage);
      post_data.append("user_by", localStorage.getItem("id"));
      post_data.append("user_to", this.state.user_info.id);
      const request = new Request(process.env.REACT_APP_BASE_PATH+"Chating" , {method: "POST", body: post_data});
      fetch(request).then(res => {
        res.json().then(json => {});
      }).catch(err => {
        console.log("Error in Get menu", err.message);
      });
    }

    ShowWidget(){
      var chatbox = "";
      if(this.state.open == true){
        chatbox =   
        <Widget 
            handleNewUserMessage={this.handleNewUserMessage}
            title= {this.state.user_info.name}
            subtitle={false}
        />
      }
      return chatbox;
    }


    render() {
        const { classes } = this.props;
        return (
          <div>
            {this.ShowWidget()}
          </div>
        );
  }
}
