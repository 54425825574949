import React, { Component } from "react";
import {Link,Redirect  } from "react-router-dom";
import StarRatings from 'react-star-ratings';
var moment = require('moment');


export default class Rating extends Component {
    state = {value: 0};
    constructor(props){
        super(props);
        var user_to = this.props.match.params ? this.props.match.params.user_to : 0;
        var booking_id = this.props.match.params ? this.props.match.params.booking_id : 0;
        var id = this.props.match.params ? this.props.match.params.id : 0;
        this.state = {rating:0,message: "" ,booking_id : booking_id,user_to:user_to, id:id};
        this.handleSubmit = this.handleSubmit.bind(this);
        this.changeRating = this.changeRating.bind(this);
    };
    handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.target);
        data.append("datetime", moment().format("YYYY-MM-DD"));
        data.append("rating", this.state.rating);
        data.append("user_by", localStorage.getItem("id"));
        data.append("booking_id", this.state.booking_id);
        data.append("user_to", this.state.user_to);
        this.SubmitData(data);

    };
    SubmitData = (data) => {
        const request = new Request(process.env.REACT_APP_BASE_PATH+"rating", {method: "POST", body: data});
        fetch(request).then(res => {
        res.json().then(json => {	
                if(json.error){
                    this.setState({message: <div class="danger">{json.message}</div>});
                } else {
                    this.setState({message: <div class="success">congratulation! successfully posted</div>});
                }			            
            });
        }).catch(err => {
            this.setState({message: <div class="danger">{err.message}</div>});
        });
    }
    changeRating(newRating, name ) {
        this.setState({rating: newRating});
    }
    
    render() {
        const { classes, drawerOpen, menuOptions } = this.props
        if(this.state.message != ""){
            return (
                <div class="myform1 col-lg-8 col-md-12 col-sm-12">
                    <h3>Rating and Comments</h3>
                    {this.state.message}
                </div>
            )
        } else {
            return (
                <form id="myform" onSubmit={this.handleSubmit} >
                    <div class="myform1 col-lg-8 col-md-12 col-sm-12">
                        <h3>Rating and Comments</h3>
                        {this.state.message}
                        <div className="form-group">
                            <StarRatings
                            rating={this.state.rating}
                            starRatedColor="blue"
                            starHoverColor="orange"
                            changeRating={this.changeRating}
                            numberOfStars={5}
                            name='rating'
                            />
                        </div>
                        <div className="form-group">
                            <label>Comments</label>
                            <textarea id="comments" className="form-control" name="comments" data="" />
                        </div>
                        <button type="submit" className="btn btn-primary btn-block">Submit</button>
                    </div>
                </form>
            );
        }
    }
}