import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import Select from 'react-select';
import CKEditor from 'ckeditor4-react';
import Autocomplete from 'react-google-autocomplete';
import ImageUploader from 'react-images-upload';
import axios from 'axios';
import Calendar from 'react-calendar-multiday'


class MyRideAdd extends React.Component {
  state = { value: 0 };
  constructor(props) {
    super(props);
    var user_id = localStorage.getItem("id");
    this.state = { user_id: user_id, subcat_list: [], items: [], location_title: 'From City', address: '', redirect: false, from_city: '', to_city: '', show_to_city: true, fileupload: [], cat_list: [] };
    this.getList();

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.file_upload = this.file_upload.bind(this);
    this.onEditorChange1 = this.onEditorChange1.bind(this);
    this.getSubList = this.getSubList.bind(this);
  }

  reactToChange = (ob) => {
    this.setState({ avilable: ob.selected });
  }

  onReset() {
    this.setState({
      channels: {},
      currentChannel: 0
    })
  }


  getInfo = (id) => {
    const request = new Request(process.env.REACT_APP_BASE_PATH + "UserAccount?user_id=" + id, { method: "GET" });
    fetch(request).then(res => {
      res.json().then(json => {
        var obj = json.length > 0 ? json[0] : json;
        this.setState({ info: obj });
        if (json.length > 0) {
          this.setState({ pk: obj.id });
          var item = this.GetSelectVal(obj.subcategory_id);
          this.setState({ subcategory_id: item });
        }
      });
    }).catch(err => {
      console.log("Error in Get menu", err);
    });
  };
  GetSelectVal(val) {
    var result = null;
    for (var i = 0; i < this.state.cat_list.length; i++) {
      if (this.state.cat_list[i].value == val) {
        result = this.state.cat_list[i];
      }
    }
    return result;
  }
  getList = () => {
    const request = new Request(process.env.REACT_APP_BASE_PATH + "InfoCategory?status=true", { method: "GET" });
    fetch(request).then(res => {
      res.json().then(obj => {
        var listItems = [];
        Object.keys(obj).forEach(function (key) {
          listItems.push({ "value": obj[key].id, "label": obj[key].title });
        });
        this.setState({ cat_list: listItems });
      });
    }).catch(err => {
      console.log("Error in Get menu", err);
    });
  };
  getSubList = (cat_id) => {
    const request = new Request(process.env.REACT_APP_BASE_PATH + "InfoSubCategory?status=true&category_id=" + cat_id, { method: "GET" });
    fetch(request).then(res => {
      res.json().then(obj => {
        var listItems = [];
        Object.keys(obj).forEach(function (key) {
          listItems.push({ "value": obj[key].id, "label": obj[key].title });
        });
        this.setState({ subcat_list: listItems });
      });
    }).catch(err => {
      console.log("Error in Get menu", err);
    });
  };

  handleSubmit(event) {
    event.preventDefault();
    this.refs.btnsubmit.setAttribute("disabled", "disabled");
    this.setState({ form: event.target });
    var post_data = new FormData(event.target);
    if (this.state.fileupload) {
      this.file_upload(this.state.fileupload);
    } else {
      var post_data = new FormData(event.target);
      this.save_data(post_data);
    }
  };
  file_upload(fileupload) {
    const filedata = new FormData()
    filedata.append('fileupload', fileupload);
    axios.post(process.env.REACT_APP_BASE_PATH + "uploadprofileimg", filedata, {}).then(data => { // then print response status
      var post_data = new FormData(this.state.form);
      var picture = data.data.filename;
      post_data.append("picture", picture);
      this.save_data(post_data);
    })
  }

  save_data(post_data) {
    var url_id = "";
    var method = "POST";//for insert
    if (this.state.pk > 0) {
      url_id = "/" + this.state.pk;
      method = "PUT";
    }
    post_data.append("user_id", this.state.user_id);
    post_data.append("from_city", this.state.from_city);
    post_data.append("to_city", this.state.to_city);
    post_data.append("description", this.state.description);

    const request = new Request(process.env.REACT_APP_BASE_PATH + "InfoPages" + url_id, { method: method, body: post_data });
    fetch(request).then(json => {
      if (json.message) {
        this.setState({ message: <div class="danger">{json.message}</div> });
      } else {
        this.setState({ message: <div class="success">updated successfully!</div> });
      }
    }).catch(err => {
      this.setState({ message: <div class="danger">{err.message}</div> });
      console.log("Error in Get menu", err.message);
    });
  }
  handleChangeCategory = selected => {
    console.log(selected);
    if (selected.value == 1) {
      this.setState({ selected: selected, show_to_city: true, location_title: "From City" });
    } else {
      this.setState({ selected: selected, show_to_city: false, location_title: "Location" });
    }
    this.getSubList(selected.value);
  };
  handleChangeSubCategory = selected => {
    console.log(selected);
    this.setState({ selected: selected });
  };


  onChangeHandler(event) {
    this.setState({ fileupload: event[0] });
  }

  onEditorChange1(evt) {
    this.setState({
      description: evt.editor.getData()
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to='/member/infopages' />
    }
    var to_city = "";
    if (this.state.show_to_city) {
      to_city = <div className="form-group">
        <label>Destination City</label>
        <div class="input-group">
          <div class="input-group-addon"><span class="fa fa-map-marker"></span></div>
          <Autocomplete
            name="to_address"
            id="to_address"
            class="form-control"
            onPlaceSelected={(place) => {
              this.setState({ to_city: place.address_components[0].long_name });
            }}
            types={['(regions)']}
            autocomplete="off" />
        </div>
      </div>
    }
    return (
      <div class="panel panel-primary">
        <div class="panel-body">
          <div class="ramadan-packages print-hajj-packages">
            <h2 class="m-b10">Add Your Information Page</h2>
            <form id="myform" onSubmit={this.handleSubmit}>
              <div class="myform1 col-lg-12 col-md-12 col-sm-12">
                {this.state.message}

                <div className="form-group">
                  <label>Select Category</label>
                  <Select id="category_id" name="category_id" options={this.state.cat_list} required
                    onChange={this.handleChangeCategory} />
                </div>

                <div className="form-group">
                  <label>Sub Category</label>
                  <Select id="subcategory_id" name="subcategory_id" options={this.state.subcat_list} required
                    onChange={this.handleChangeSubCategory} />
                </div>

                <div className="form-group">
                  <label>Picture</label>
                  <ImageUploader
                    withIcon={false}
                    singleImage={false}
                    buttonText='Choose images'
                    label='Upload 1 files max size 4 MB'
                    onChange={this.onChangeHandler}
                    withPreview={true}
                    imgExtension={['.jpg', '.png', '.jpeg']}
                    maxFileSize={1240000}
                  />
                </div>

                <div className="form-group">
                  <label>{this.state.location_title}</label>
                  <div class="input-group">
                    <div class="input-group-addon"><span class="fa fa-map-marker"></span></div>
                    <Autocomplete
                      name="from_address"
                      id="from_address"
                      class="form-control"
                      onPlaceSelected={(place) => {
                        this.setState({ from_city: place.address_components[0].long_name });
                      }}
                      types={['(regions)']}
                      autocomplete="off" />
                  </div>
                </div>

                {to_city}
                <div className="form-group">
                  <label>Youtub Video</label>
                  <input type="text" id="video" name="video" className="form-control" />
                </div>
                <div className="form-group">
                  <label>Description</label>
                  <CKEditor id="description" name="description" data="" onChange={this.onEditorChange1} />
                </div>

                <button type="submit" ref="btnsubmit" className="btn btn-primary btn-block">Submit</button>
              </div>

            </form>
          </div>

        </div>
      </div>
    );
  }

}


export default (MyRideAdd);
