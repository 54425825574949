import React, { Component } from 'react'
import Popup from "reactjs-popup";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Chat from "../Common/Chat";



class UserPopUp extends React.Component {
  state = {value: 0};
  constructor(props){
    super(props);
    this.state = {info:[],open:false, param: props.param};    
        
    this.getInfo = this.getInfo.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.UserChat = this.UserChat.bind(this);
  }
  UserChat(user_info){
    this.refs.child.toggleChat(user_info);
    this.closeModal();
  }
  openModal() {
    this.getInfo(this.state.param.id);
    this.setState({ open: true });
  }
  closeModal() {
    this.setState({ open: false });
  }
  getInfo = (id) => {
		const request = new Request(process.env.REACT_APP_BASE_PATH+"user/"+id, {method: "GET"});
		fetch(request).then(res => {
					res.json().then(obj => {					            
                        this.setState({ info: obj });
						return obj;
					});
			}).catch(err => {
				console.log("Error in Get menu", err);
			});
  };
  
  render() {
    var photo = process.env.REACT_APP_BASE_PATH+"upload/default.png"; 
    
    if(typeof this.state.info.picture !== undefined){
        photo = process.env.REACT_APP_BASE_PATH+this.state.info.picture;
    }
    var btn_group = "";
    var complain_url = "/complain/"+this.state.info.id;

    if(this.state.info.id != localStorage.getItem("id")){ //same user
      btn_group = <div>
        <div class="btn-group" role="group" aria-label="Basic example">
          <button type="button" class="btn btn-danger"><Link  to={complain_url}>Complain</Link></button>
          <button type="button" class="btn btn-success" onClick={() => this.UserChat({id:this.state.info.id, name: this.state.info.name})}>Chat</button>
        </div>
      </div>
    }
    return (
        <div>
            <Chat ref="child"></Chat> 
            <a href="#" onClick={this.openModal}>{this.state.param.name}</a>
            <Popup open={this.state.open} closeOnDocumentClick onClose={this.closeModal} >
            <div className="modal">
                <a className="close" onClick={this.closeModal}>&times;</a>
                <div class="col-lg-12">
                    <div class="well well-sm">
                        <div class="row">
                            <div class="col-lg-4">
                                <img src={photo} alt="" class="img-rounded img-responsive" />
                                {btn_group}
                            </div>
                            <div class="col-lg-8">
                                <h4>{this.state.info.name}</h4>
                                <small></small>
                                <p>
                                    <i class="fa fa-envelope"></i> {this.state.info.email}  <br />
                                    <i class="fa fa-phone"></i> {this.state.info.mobile}  <br />
                                    <i class="fa fa-map-marker"></i>Address: {this.state.info.address}  <br />
                                    <i class="fa fa-map-marker"></i>City: {this.state.info.city}  <br />
                                    <i class="fa fa-map-marker"></i>Country: {this.state.info.country}  <br />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Popup>
      </div>
    );
  }
}


export default (UserPopUp);
