import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Select from 'react-select';
import Header from './../../Layout/Header';
import Footer from './../../Layout/Footer';
import common from './../../services/common';

var moment = require('moment');

class Payment extends React.Component {
  state = { value: 0 };
  constructor(props) {
    super(props);
    var payment_list = [
      { "value": "mobi_cash", "label": "Jazz Cash" },
      { "value": "visa_card", "label": "Visa Card" },
      { "value": "easy_paisa", "label": "Easy Paisa Mobile" },
      { "value": "bank_transfer", "label": "Bank Transfer" }
    ];

    this.state.info = [];
    this.state.booking_id = this.props.match.params ? this.props.match.params.booking_id : 0;
    this.state.payment_list = payment_list;
    this.state.form = null;
    this.state.method = "";

  

    this.state.amount = 0;
    this.NameChange = this.NameChange.bind(this);
    this.EmailChange = this.EmailChange.bind(this);
    this.MobileChange = this.MobileChange.bind(this);

    if(this.state.booking_id > 0){
      this.getInfo(this.state.booking_id);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.CryptoJS = window.CryptoJS;
      this.getCurrencyRate(this.state.info.currency_id);
    }
  }
  getInfo = (id) => {
    const request = new Request(process.env.REACT_APP_BASE_PATH + "Booking/" + id, { method: "GET" });
    fetch(request).then(res => {
      res.json().then(obj => {
        var paid = obj.paid > 0 ? obj.total - obj.paid : Math.ceil(process.env.REACT_APP_PAID_PERCENT*obj.total);
        obj.name = obj.name ? obj.name : obj.user_id.name;
        obj.email = obj.email ? obj.email : obj.user_id.email;
        obj.mobile = obj.mobile ? obj.mobile : obj.user_id.mobile;

        this.setState({ info: obj, amount:paid });
        return obj;
      });
    }).catch(err => {
      console.log("Error in Get menu", err);
    });
  };

  getCurrencyRate(currency_id) {
    const request = new Request(process.env.REACT_APP_BASE_PATH + "currency/" + currency_id, { method: "GET" });
    fetch(request).then(res => {
      res.json().then(json => {
        this.setState({ currency_rate: json.rate });
      })
    })
  }
  NameChange(event) {  
    this.setState({info:{name: event.target.value}});  
  }
  EmailChange(event) {  
    this.setState({info:{email: event.target.value}});  
  }
  MobileChange(event) {  
    this.setState({info:{mobile: event.target.value}});  
  }


  handleSubmit(event) {
    event.preventDefault();
    this.setState({ form: event.target });
    var post_data = new FormData(event.target);
    var json = this.state.info;
    var paid = this.state.amount;
    var method = this.state.method;
    var service = "ride";
    if (json.service_id == 2) {
      service = "stay";
    } else if (json.service_id == 3) {
      service = "guide";
    } else if (json.service_id == 4) {
      service = "trip";
    } else if (json.service_id == 5) {
      service = "pilgrim";
    }

    if(method == "easy_paisa" || method == "visa_card" || method == "bank_transfer" || method == "mobi_cash"){
      var form = document.createElement("form");
      var path = method == "mobi_cash" || method == "visa_card" ?  process.env.REACT_APP_JAZZ_CASH : process.env.REACT_APP_EASY_PAISA;
      form.setAttribute("method", "post");
      form.setAttribute("action", path);
      form.setAttribute("id", "childform");
      form.appendChild(this.create_form_element("amount", paid));
      form.appendChild(this.create_form_element("order_id", json.id));
      form.appendChild(this.create_form_element("from", "web"));
      form.appendChild(this.create_form_element("payment_method", this.state.method));
      form.appendChild(this.create_form_element("name", this.state.info.name));
      form.appendChild(this.create_form_element("email", this.state.info.email));
      form.appendChild(this.create_form_element("mobile", this.state.info.mobile));
      document.body.appendChild(form);
      form.submit();
    }
    this.setState({ message: <div class="success">Please wait redirecting to payment system!</div> });

  };
  create_form_element(key, value) {
    var hiddenField = document.createElement("input");
    hiddenField.setAttribute("type", "hidden");
    hiddenField.setAttribute("name", key);
    hiddenField.setAttribute("value", value);
    return hiddenField;
  }
  
  render() {
    
    var booking_info = this.state.info.id ? <div class="booking_info">
                                          <div>Booking# {this.state.info.id} </div>
                                          <div>Amount: {this.state.info.currency_id.title} {this.state.amount}</div>
                                        </div> : "";
    return (
      <>
        <Header />
        <div className="listing-details-head">
          <div className="container">
            <div className="hotel-booking">
              {this.state.message}
              {booking_info}
              <form id="myform" onSubmit={this.handleSubmit}>
                <div class="myform1 col-lg-10 col-md-12 col-sm-12">
                  {this.state.message}
                  <div className="form-group">
                    <label>Name</label>
                    <input type="text" class="form-control" value={this.state.info.name} onChange={this.NameChange} name="name" />
                  </div>
                  <div className="form-group">
                    <label>Email</label>
                    <input type="email" class="form-control" value={this.state.info.email} onChange={this.EmailChange} name="email"  />
                  </div>
                  <div className="form-group">
                    <label>Mobile</label>
                    <input type="mobile" class="form-control" value={this.state.info.mobile} onChange={this.MobileChange} name="mobile"  />
                  </div>
                  <div className="form-group">
                    <label>Payment Method</label>
                    <Select id="method" name="method" options={this.state.payment_list} 
                    onChange={value =>this.setState({method:value.value})} />
                  </div>
                  <button type="submit" className="btn btn-primary btn-block">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default (Payment);
