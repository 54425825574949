import React, { Component } from 'react';
import Carousel from "react-bootstrap/Carousel";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Calendar from 'react-calendar-multiday';
import YouTube from 'react-youtube';
import Header from './../../../Layout/Header';
import Footer from './../../../Layout/Footer';
import { TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share";
var moment = require('moment');

class GuideDetail extends React.Component {
  state = { value: 0 };
  constructor(props) {
    super(props);
    var id = this.props.match.params ? this.props.match.params.infoid : 0;
    var allow_book = props.location.state && parseInt(localStorage.getItem("id")) > 0 ? true : false;
    var today = new Date();
    var date = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
    var from_date = props.location.state ? props.location.state.date : date;
    var to_date = moment(from_date, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD");

    this.state = { activeTab: "1", id: id, from_date: from_date, to_date: to_date, quantity: 1, message: "", info: { city_list: [], date_list: [] }, date_array: [], booked_days: {}, pre_state: props.location.state, allow_book: allow_book, show_book: false };
    this.getInfo(id);
    this.AddView(id);
    console.log("tnsbay pre guide", props.location.state);
    this.CheckBooking = this.CheckBooking.bind(this);
    this.SetInput = this.SetInput.bind(this);

  }
  toggle = tab => {
    this.setState({ activeTab: tab });
  }
  componentDidUpdate(prevProps) {
    var id = this.props.match.params ? this.props.match.params.infoid : 0;
    var pre_id = prevProps.match.params ? prevProps.match.params.infoid : 0;
    if (id !== pre_id) {
      this.getInfo(id);
    }
  }
  CheckBooking(evt) {
    console.log("tnsbay clicked", this.state.date_array, this.state.booked_days, this.state.from_date, this.state.to_date);
    var date = "";
    var booked_days = JSON.parse(this.state.booked_days);
    var html_messsage = [];
    var show_book = true;
    for (var i = 0; i < this.state.quantity; i++) {
      date = moment(this.state.from_date, "YYYY-MM-DD").add(i, 'days').format("YYYY-MM-DD");
      if (!this.state.date_array.includes(date)) {
        show_book = false;
        html_messsage.push("Date: " + date + " is not avaible");
      } else if (date in booked_days) {
        show_book = false;
        html_messsage.push("Date: " + date + " is already booked");
      }
    }
    html_messsage = html_messsage.join(", ");
    this.setState({ show_book: show_book, message: show_book ? <div class="success">Congratulation! Booking is available, you can book now!</div> : <div class="danger">{html_messsage}</div> });
  }
  getInfo = (id) => {
    const request = new Request(process.env.REACT_APP_BASE_PATH + "GuideInfo/" + id, { method: "GET" });
    fetch(request).then(res => {
      res.json().then(obj => {
        var date_array = [];
        obj.date_list.forEach(dateinfo => {
          date_array.push((dateinfo["date"].split("T"))[0]);
        })
        this.setState({ info: obj, date_array: date_array, booked_days: JSON.stringify(obj.booked_days) });
        return obj;
      });
    }).catch(err => {
      console.log("Error in Get menu", err);
    });
  };
  AddView = (id) => {
    const request = new Request(process.env.REACT_APP_BASE_PATH + "AddView/3/" + id, { method: "GET" });
    fetch(request).then(res => {
      console.log(res);
    }).catch(err => {
      console.log("Error in Get menu", err);
    });
  };
  SetInput(e) {
    const quantity = e.target.name == "quantity" ? e.target.value : this.state.quantity;
    const from_date = e.target.name == "from_date" ? e.target.value : this.state.from_date;
    var to_date = moment(from_date, "YYYY-MM-DD").add(quantity, 'days').format("YYYY-MM-DD");
    this.setState({ to_date: to_date,[e.target.name]: e.target.value });
  }
  handlerlisting(ItemList) {
    if (ItemList && ItemList.length > 0) {
      return ItemList.map((item) => {
        return (
          <li>
            <span className="check-km" title="Light Gallery Grid 1">
              <div className="dlab-post-thum"><img src={item} alt="" /></div>
            </span>
          </li>
        )
      })
    }
  }

  render() {
    var price = (this.state.info.price * this.state.quantity);
    console.log(this.state.info.price + "price is called" + this.state.quantity);
    var price_title = this.state.info.subcategory_id == 16 ? "Per Person" : "Per Day";
    var picture_array = this.state.info.picture ? this.state.info.picture.split(',') : [];
    var photo_gallary = [];
    picture_array.forEach(photo => {
      photo_gallary.push(process.env.REACT_APP_FILE_PATH + photo);
    })
    var date_array = [];
    if (this.state.info.date_list) {
      this.state.info.date_list.forEach(dateinfo => {
        date_array.push(dateinfo.date);
      })
    }
    var gallery = "";
    if (photo_gallary.length > 0) {
      gallery = <ul>{this.handlerlisting(photo_gallary)}</ul>
    }

    const video_option = {
      height: '390',
      width: '640',
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 0
      }
    };
    var video = "";
    if (this.state.info.video && this.state.info.video != "") {
      var videoid = this.state.info.video;
      var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      var match = videoid.match(regExp);
      videoid = (match && match[2].length == 11) ? match[2] : false;

      video = <YouTube
        videoId={videoid}
        opts={video_option}
        onReady={this._onReady}
      />
    }

    var shareUrl = window.location.href;
    var bookpath = <button type="button" class="btn btn-info">{this.state.info.currency} {price}</button>
    if (!this.state.show_book) {
      bookpath =
        <div className="tour-booking-form">
          <div className="tour-booking-head">
            <span>{this.state.info.currency} {price} <small style={{"font-size":"14px"}}>{price_title}</small></span>
          </div>
          <form className="row sp15">
            <div class="form-group col-md-12">
              <span class="input-group-addon">Days</span>
              <input type="number" onChange={this.SetInput} class="form-control mytextbox" name="quantity" defaultValue={this.state.quantity} ></input>
            </div>
            <div class="form-group col-md-12">
              <span class="input-group-addon">Date</span>
              <input type="date" onChange={this.SetInput} class="form-control mytextbox" name="from_date" defaultValue={this.state.from_date} ></input>
            </div>
            <div class="form-group col-md-12">
              <span class="input-group-addon">Date To</span>
              <input type="date" disabled="true" onChange={this.SetInput} class="form-control mytextbox" name="to_date" value={this.state.to_date} ></input>
            </div>
            <div class="input-group-btn col-md-12">
              <button type="button" onClick={this.CheckBooking} class="btn btn-success">Check <span class="fa fa-caret-right"></span></button>
            </div>
          </form>
        </div>

    } else {
      bookpath =
        <div className="tour-booking-form">
          <div className="tour-booking-head">
            <span>{this.state.info.currency} {price} <small style={{"font-size":"14px"}}>{price_title}</small></span>
          </div>
          <form className="row sp15">

            <div class="form-group col-md-12">
              <span class="input-group-addon">Days</span>
              <input type="number" disabled="true" onChange={this.SetInput} class="form-control mytextbox" name="days" defaultValue={this.state.quantity} ></input>
            </div>
            <div class="form-group col-md-12">
              <span class="input-group-addon">Date From</span>
              <input type="date" disabled="true" class="form-control mytextbox" name="date" defaultValue={this.state.date} ></input>
            </div>
            <div class="form-group col-md-12">
              <span class="input-group-addon">Date To</span>
              <input type="date" disabled="true" class="form-control mytextbox" name="date_to" defaultValue={this.state.date_to} ></input>
            </div>
            <div class="input-group-btn col-md-12">
              <Link class="right" to={{ pathname: "/Booking/Guide/" + this.state.info.id, state: { price: price, quantity: this.state.quantity, pre_state: this.state.pre_state, info: this.state.info, from_date: this.state.from_date,to_date: this.state.to_date } }} >
                <button type="button" class="btn btn-success">Book <span class="fa fa-caret-right"></span></button>
              </Link>
            </div>

          </form>
        </div>
    }

    return (
      <div>
        <Header />
        <div className="listing-details-head">
          <div className="container">
            <div className="listing-info-box">
              <div className="listing-info">
                <div className="listing-info-left">
                  <h3 className="title">{this.state.info.title}</h3>
                </div>
                <div className="listing-info-right text-center">
                  <FacebookShareButton url={shareUrl}>
                    {" "}
                    <FacebookIcon size={32} round={true} />{" "}
                  </FacebookShareButton>
                  <TwitterShareButton url={shareUrl}>
                    {" "}
                    <TwitterIcon size={32} round={true} />{" "}
                  </TwitterShareButton>
                  <LinkedinShareButton url={shareUrl}>
                    {" "}
                    <LinkedinIcon size={32} round={true} />{" "}
                  </LinkedinShareButton>
                  <EmailShareButton url={shareUrl}>
                    {" "}
                    <EmailIcon size={32} round={true} />{" "}
                  </EmailShareButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="listing-details-nav">
          <div className="container">
            <ul className="listing-nav nav justify-content-center">
              <li>
                <Link className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => { this.toggle('1'); }}><i className="la la-home"></i><span>Home</span>
                </Link>
              </li>
              <li>
                <Link className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggle('2'); }}><i className="la la-file-text"></i><span>Description</span>
                </Link>
              </li>
              <li>
                <Link className={classnames({ active: this.state.activeTab === '4' })}
                  onClick={() => { this.toggle('4'); }}><i className="la la-image"></i><span>Photos</span>
                </Link>
              </li>
              <li>
                <Link className={classnames({ active: this.state.activeTab === '5' })}
                  onClick={() => { this.toggle('5'); }}><i className="la la-video-camera"></i><span>Videos</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>


        <div className="section-full listing-details-content">
          <div className="container">
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <div className="row">
                  <div className="col-xl-8 col-lg-7 col-md-12">
                    <div className="content-box">
                      <div className="content-header">
                        <h3 className="title"><i className="la la-star-o m-r5"></i> Information</h3>
                      </div>
                      <div className="content-body row">
                        <div class="icon-content col-md-6">
                          <h6 class="text-uppercase m-tb0 dlab-tilte">Category</h6>
                          <p>{this.state.info.category}</p>
                        </div>

                        <div class="icon-content col-md-6">
                          <h6 class="text-uppercase m-tb0 dlab-tilte">City</h6>
                          <p>{this.state.info.city_title}</p>
                        </div>

                        <div class="icon-content col-md-6">
                          <h6 class="text-uppercase m-tb0 dlab-tilte">Posted By</h6>
                          <p>{this.state.info.user_name}</p>
                        </div>
                      </div>
                      <Calendar
                        reset={false}
                        selected={date_array}
                        isMultiple={true}
                      />
                    </div>
                    <div className="content-box">
                      <div className="content-header">
                        <h3 className="title"><i className="la la-file-text m-r5"></i> description</h3>
                      </div>
                      <div className="content-body"
                        dangerouslySetInnerHTML={{
                          __html: this.state.info.description,
                        }} >
                      </div>
                    </div>
                    <div className="content-box">
                      <div className="content-header">
                        <h3 className="title"><i className="la la-file-text m-r5"></i> Food Plain</h3>
                      </div>
                      <div className="content-body"
                        dangerouslySetInnerHTML={{
                          __html: this.state.info.food_plain,
                        }} >
                      </div>
                    </div>

                    <div className="content-box">
                      <div className="content-header">
                        <h3 className="title"><i className="la la-file-image-o"></i> photos</h3>
                      </div>
                      <div className="content-body">
                        <div className="widget widget_gallery gallery-grid-4 lightgallery">
                          {gallery}
                        </div>
                      </div>
                    </div>
                    <div className="content-box">
                      <div className="content-header">
                        <h3 className="title"><i className="la la-video-camera m-r5"></i>videos</h3>
                      </div>
                      <div className="content-body">
                        <div className="widget widget_video video-grid-4">
                          <ul>
                            <li>
                              <div className="dlab-post-thum video-bx">
                                {video}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-5 col-md-12 sticky-top">
                    <aside className="side-bar listing-side-bar">
                      <div className="content-box">
                        <div className="content-header">
                          <h3 className="title"><i className="la la-bullhorn m-r5"></i>Booking Panel</h3>
                        </div>
                        <div className="content-body">
                          {this.state.message}
                          {bookpath}
                        </div>
                      </div>
                    </aside>
                  </div>

                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="content-box">
                      <div className="content-header">
                        <h3 className="title">description</h3>
                      </div>
                      <div className="content-body"
                        dangerouslySetInnerHTML={{
                          __html: this.state.info.description,
                        }} >
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-12">
                    <div className="content-box">
                      <div className="content-header">
                        <h3 className="title"><i className="la la-file-text m-r5"></i> Food Plain</h3>
                      </div>
                      <div className="content-body"
                        dangerouslySetInnerHTML={{
                          __html: this.state.info.food_plain,
                        }} >
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>

              <TabPane tabId="4">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="content-box">
                      <div className="content-header">
                        <h3 className="title">photos</h3>
                      </div>
                      <div className="content-body">
                        <div className="widget widget_gallery gallery-grid-4 lightgallery">
                          {gallery}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="5">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="content-box">
                      <div className="content-header">
                        <h3 className="title">videos</h3>
                      </div>
                      <div className="content-body">
                        <div className="widget widget_video video-grid-4">
                          <ul>
                            <li>
                              <div className="dlab-post-thum video-bx">
                                {video}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </div>
        </div>
        <Footer />
      </div>
    );


  }
}


export default (GuideDetail);
