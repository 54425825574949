import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import RideFilter from './service/Ride/RideFilter';
import GuideFilter from './service/Guide/GuideFilter';
import StayFilter from './service/Stay/StayFilter';
import PackageFilter from './service/Package/PackageFilter';

const Tab2 = (props) => {
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    return (
        <div>
            <div className="section-full search-filter">
                <div className="container">
                    <Nav pills>
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === '1' })}
                                onClick={() => { toggle('1'); }}>Ride</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === '2' })}
                                onClick={() => { toggle('2'); }}>Stay</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === '3' })}
                                onClick={() => { toggle('3'); }}>Guide</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === '4' })}
                                onClick={() => { toggle('4'); }}>Trip Package</NavLink>
                        </NavItem>
                    </Nav>
                </div>
            </div>

            <div className="container">
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <div class="hotel-booking"><RideFilter page="home" /></div>
                    </TabPane>
                    <TabPane tabId="2">
                        <div class="hotel-booking"><StayFilter page="home" /></div>
                    </TabPane>
                    <TabPane tabId="3">
                        <div class="hotel-booking"><GuideFilter page="home" /></div>
                    </TabPane>
                    <TabPane tabId="4">
                        <div class="hotel-booking"><PackageFilter page="home" /></div>
                    </TabPane>
                </TabContent>
            </div>
        </div>
    );
}

export default Tab2;