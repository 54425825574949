import React from 'react';
import { Table, Button, Alert } from 'react-bootstrap';
import UserPopUp from '../Common/UserPopUp';
import { Link } from "react-router-dom";

class MyServiceOrder extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    var user_id = localStorage.getItem("id");
    var category_id = 1;
    if(props.params.category == "stay"){
      category_id = 2;
    } else if(props.params.category == "guide"){
      category_id = 3;
    }
    
    this.state = {
      error: null,
      user_id: user_id,
      category_id: category_id,
      products: [],
      response: {}
    }
    this.getInfo = this.getInfo.bind(this);
    this.getInfo();
  }

  componentDidUpdate(prevProps) {
    console.log("tnsbay my booking", this.props.params.category, prevProps.params.category);
    if (this.props.params.category !== prevProps.params.category) {
      var category_id = 1;
      if(this.props.params.category == "stay"){
        category_id = 2;
      } else if(this.props.params.category == "guide"){
        category_id = 3;
      }
      this.setState({category_id: category_id});
      this.state.category_id = category_id;

      this.getInfo();
    }
  }
  getInfo = () => {
    const request = new Request(process.env.REACT_APP_BASE_PATH+"MyBooking?user_by="+this.state.user_id+"&category_id="+this.state.category_id, {method: "GET"});
    fetch(request)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({products: result});
        },
        (error) => {
          this.setState({ error });
        }
      )
  }

  deleteProduct(productId) {
    if(window.confirm("Are you sure you want to delete? ")){
      const request = new Request(process.env.REACT_APP_BASE_PATH+"Ride/"+productId , {method: "DELETE", body: {}});
      fetch(request).then(res => {
      res.json().then(json => {	
              this.componentDidMount();
        });
      }).catch(err => {
          this.setState({message: <div class="danger">{err.message}</div>});
      });
    }
  }

  render() {
    const { error, products} = this.state;

    if(this.state.category_id == 1) {
      return(<div class="panel panel-primary">
      <div class="panel-body table-responsive">
        <div>
        <div class="page_heading"><span >Ride Order List</span></div>
        {this.state.response.message && <Alert variant="info">{this.state.response.message}</Alert>}
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Title</th>
              <th>Category</th>
              <th>Total</th> 
              <th>Paid</th> 
              <th>Method</th> 
              <th>Source - Destination</th>
              <th>Date</th>
              <th>User By</th> 
              <th>Status</th>
              <th>Payment</th>
            </tr>
          </thead>
          <tbody>
            {products.map(product => (
              <tr key={product.id}>
                <td>{product.id}</td>
                <td>{product.service}</td>
                <td>{product.category}</td>
                <td>{product.total}</td>
                <td>{product.paid}</td>
                <td>{product.method}</td>
                <td>{product.dest_address} - {product.dest_address}</td>
                <td>{product.date_from}</td>
                <td><UserPopUp param={{id: product.user_id_by, name: product.user_name}}></UserPopUp> </td>
                <td><span class="badge badge-info">{product.status}</span></td>
                <td><span class="badge badge-info">{product.payment_status}</span></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
    </div>)
    } else if(this.state.category_id == 2) {
      return(<div class="panel panel-primary">
      <div class="panel-body table-responsive">
        <div>
        <div class="page_heading"><span >Stay Order List</span></div>
        {this.state.response.message && <Alert variant="info">{this.state.response.message}</Alert>}
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Title</th>
              <th>Category</th>
              <th>Total</th> 
              <th>Paid</th> 
              <th>Method</th> 
              <th>City</th>
              <th>Country</th>
              <th>Date</th>
              <th>User By</th> 
              <th>Status</th>
              <th>Payment</th>
            </tr>
          </thead>
          <tbody>
            {products.map(product => (
              <tr key={product.id}>
                <td>{product.id}</td>
                <td>{product.service}</td>
                <td>{product.category}</td>
                <td>{product.total}</td>
                <td>{product.paid}</td>
                <td>{product.method}</td>
                <td>{product.city}</td>
                <td>{product.country}</td>
                <td>{product.date_from}-{product.date_to}</td>
                <td><UserPopUp param={{id: product.user_id_by, name: product.user_name}}></UserPopUp> </td>
                <td><span class="badge badge-info">{product.status}</span></td>
                <td><span class="badge badge-info">{product.payment_status}</span></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
    </div>)
    } else if(this.state.category_id == 3) {
      return(
        <div class="panel panel-primary">
          <div class="panel-body table-responsive">
            <div>
            <div class="page_heading"><span >Guide Order List</span></div>
            {this.state.response.message && <Alert variant="info">{this.state.response.message}</Alert>}
            <Table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Title</th>
                  <th>Category</th>
                  <th>Total</th> 
                  <th>Paid</th> 
                  <th>Method</th> 
                  <th>City</th>
                  <th>Country</th>
                  <th>Date</th>
                  <th>User By</th> 
                  <th>Status</th>
                  <th>Payment</th>
                </tr>
              </thead>
              <tbody>
                {products.map(product => (
                  <tr key={product.id}>
                    <td>{product.id}</td>
                    <td>{product.service}</td>
                    <td>{product.category}</td>
                    <td>{product.total}</td>
                    <td>{product.paid}</td>
                    <td>{product.method}</td>
                    <td>{product.city}</td>
                    <td>{product.country}</td>
                    <td>{product.date_from}-{product.date_to}</td>
                    <td><UserPopUp param={{id: product.user_id_by, name: product.user_name}}></UserPopUp> </td>
                    <td><span class="badge badge-info">{product.status}</span></td>
                    <td><span class="badge badge-info">{product.payment_status}</span></td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
        </div>
      )
    }
  }
}

export default MyServiceOrder;