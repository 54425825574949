import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";

export default class ContactInfo extends Component {
    constructor(props) {
        super(props);
        this.state = { company_info: {} };
        this.getList();
    }
    getList = () => {
        const request = new Request(process.env.REACT_APP_BASE_PATH + "setting?tag[]=contact_email&tag[]=company_address&tag[]=contact_mobile&tag[]=facebook&tag[]=youtube&tag[]=linkedin&tag[]=instagram&tag[]=twitter", { method: "GET" });
        fetch(request).then(res => {
            res.json().then(obj => {
                var company_info = {};
                for (const [key, value] of Object.entries(obj)) {
                    console.log(`${key}: ${value}`);
                    company_info[value.tag] = value.value;
                }
                console.log("OK", company_info);
                this.setState({ company_info: company_info });
                return company_info;
            });
        }).catch(err => {
            console.log("Error in Get menu", err);
        });
    };


    render() {
        return (
            <>
                <div className="widget widget_getintuch">
                    <ul className="info-contact">
                        <li>
                            <span>
                                <i className="fa fa-map-marker"></i> {this.state.company_info.company_address}
                            </span>
                        </li>
                        <li>
                            <span>
                                <a href={"tel:" + this.state.company_info.contact_mobile}><i className="fa fa-phone"></i> {this.state.company_info.contact_mobile} </a>
                            </span>
                        </li>
                        <li>
                            <span>
                                <a href={"mailto:" + this.state.company_info.contact_email}><i className="fa fa-envelope-o"></i> {this.state.company_info.contact_email}</a>
                            </span>
                        </li>
                    </ul>
                </div>
                <ul className="list-inline">
                    <li><a target="_blank" href={this.state.company_info.facebook} className='site-button facebook sharp'><i className="fa fa-facebook"></i></a></li>
                    <li><a target="_blank" href={this.state.company_info.instagram} className='instagram site-button sharp'><i className="fa fa-instagram"></i></a></li>
                    <li><a target="_blank" href={this.state.company_info.linkedin} className='linkedin site-button sharp'><i className="fa fa-linkedin"></i></a></li>
                    <li><a target="_blank" href={this.state.company_info.twitter} className='twitter site-button sharp'><i className="fa fa-twitter"></i></a></li>
                    <li><a target="_blank" href={this.state.company_info.youtube} className='youtube site-button sharp'><i className="fa fa-youtube"></i></a></li>
                </ul>
            </>
        );
    }
}