import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import YouTube from 'react-youtube';
import Calendar from "react-calendar-multiday";
import Header from './../../../Layout/Header';
import Footer from './../../../Layout/Footer';
import { TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share";
var moment = require('moment');

class PackageDetail extends React.Component {
  state = { value: 0 };
  constructor(props) {
    super(props);
    var today = new Date();
    var date = today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + ("0" + today.getDate()).slice(-2);

    var id = this.props.match.params ? this.props.match.params.infoid : 0;
    var allow_book = parseInt(localStorage.getItem("id")) > 0 ? true : false;
    this.state = {
      activeTab: "1", id: id, message: "", show_book: false, date_array: [],
      pre_state: props.location.state,
      info: { date_list: [] },
      allow_book: allow_book,
      from_date: '',
      to_date: '',
      quantity: 1,
      adult: 1,
      child: 0,
      infant: 0,
      couple: 0,
      price: 0
    };
    this.getInfo(id);
    this.AddView(id);

    this.AdultChange = this.AdultChange.bind(this);
    this.ChildChange = this.ChildChange.bind(this);
    this.InfantChange = this.InfantChange.bind(this);
    this.CoupleChange = this.CoupleChange.bind(this);
    this.CheckBooking = this.CheckBooking.bind(this);
  }
  toggle = tab => {
    this.setState({ activeTab: tab });
  }
  componentDidUpdate(prevProps) {
    var id = this.props.match.params ? this.props.match.params.infoid : 0;
    var pre_id = prevProps.match.params ? prevProps.match.params.infoid : 0;
    if (id !== pre_id) {
      this.getInfo(id);
    }
  }
  getInfo = (id) => {
    const request = new Request(process.env.REACT_APP_BASE_PATH + "PackageInfo/" + id, { method: "GET" });
    fetch(request).then(res => {
      res.json().then(obj => {
        obj.available_seats = parseInt(obj.folding_seats) + parseInt(obj.business_seats) - parseInt(obj.booked);
        obj.departure_date = obj.departure_date.split("T")[0];
        this.setState({ info: obj, from_date: obj.departure_date, to_date: obj.departure_date });
        return obj;
      });
    }).catch(err => {
      console.log("Error in Get menu", err);
    });
  };
  CheckBooking(evt) {
    if (this.state.quantity <= this.state.info.available_seats) {
      this.setState({ show_book: true });
      this.setState({
        message: (
          <div class="success">
            Congratulation! is available, you can book now!
          </div>
        ),
      });
    } else {
      this.setState({
        message: <div class="danger">Required number of seats are not available!</div>,
      });
    }
  }
  AddView = (id) => {
    const request = new Request(process.env.REACT_APP_BASE_PATH + "AddView/4/" + id, { method: "GET" });
    fetch(request).then(res => {
      console.log(res);
    }).catch(err => {
      console.log("Error in Get menu", err);
    });
  };


  AdultChange(evt) {
    var quantity = parseInt(evt.target.value) + parseInt(this.state.child) + parseInt(this.state.infant) + 2 * parseInt(this.state.couple);
    this.setState({ adult: evt.target.value, quantity: quantity });

  }
  ChildChange(evt) {
    var quantity = parseInt(this.state.adult) + parseInt(evt.target.value) + parseInt(this.state.infant) + 2 * parseInt(this.state.couple);
    this.setState({ child: evt.target.value, quantity: quantity });
  }
  InfantChange(evt) {
    var quantity = parseInt(this.state.adult) + parseInt(this.state.child) + parseInt(evt.target.value) + 2 * parseInt(this.state.couple);
    this.setState({ infant: evt.target.value, quantity: quantity });
  }
  CoupleChange(evt) {
    var quantity = parseInt(this.state.adult) + parseInt(this.state.child) + parseInt(this.state.infant) + 2 * parseInt(evt.target.value);
    this.setState({ couple: evt.target.value, quantity: quantity });
  }


  _onReady(event) {
    event.target.pauseVideo();
  }
  handlerlisting(ItemList) {
    if (ItemList && ItemList.length > 0) {
      return ItemList.map((item) => {
        return (
          <li>
            <span className="check-km" title="Light Gallery Grid 1">
              <div className="dlab-post-thum"><img src={item} alt="" /></div>
            </span>
          </li>
        )
      })
    }
  }
  render() {
    var days = 1;//(moment(this.state.to_date).diff(moment(this.state.from_date)));
    //days =  moment.duration(days).asDays()+1;
    var price = 0;
    price += this.state.info.price * this.state.adult;
    price += this.state.info.child_price * this.state.child;
    price += this.state.info.infant_price * this.state.infant;
    price += this.state.info.couple_price * this.state.couple;
    this.state.price = price;

    var photo_gallary = [];

    var picture_array = this.state.info.picture_visiting ? this.state.info.picture_visiting.split(',') : [];
    picture_array.forEach(photo => {
      photo_gallary.push(process.env.REACT_APP_FILE_PATH + photo);
    })

    picture_array = this.state.info.stay_picture ? this.state.info.stay_picture.split(',') : [];
    picture_array.forEach(photo => {
      photo_gallary.push(process.env.REACT_APP_FILE_PATH + photo);
    })

    picture_array = this.state.info.ride_picture ? this.state.info.ride_picture.split(',') : [];
    picture_array.forEach(photo => {
      photo_gallary.push(process.env.REACT_APP_FILE_PATH + photo);
    })

    picture_array = this.state.info.picture_vahicle ? this.state.info.picture_vahicle.split(',') : [];
    picture_array.forEach(photo => {
      photo_gallary.push(process.env.REACT_APP_FILE_PATH + photo);
    })

    var gallery = "";
    if (photo_gallary.length > 0) {
      gallery = <ul>{this.handlerlisting(photo_gallary)}</ul>
    }


    var date_array = [];
    if (this.state.info.date_list) {
      this.state.info.date_list.forEach(dateinfo => {
        date_array.push(dateinfo.date);
      })
    }

    var shareUrl = window.location.href;
    var bookpath = (
      <button
        type="button"
        class="btn btn-info"
        style={{ marginRight: "10px" }}
      >
        {this.state.info.currency} {price}
      </button>
    );
    if (!this.state.show_book) {
      bookpath = (
        <div className="tour-booking-form">
          <div className="tour-booking-head">
            <span>{this.state.info.currency} {price}</span>
          </div>
          <form className="row sp15">
            <div class="form-group col-md-12">
              <span class="input-group-addon">Bussiness (Adult)</span>
              <input onChange={this.AdultChange} type="number" class="form-control mytextbox" name="adult" defaultValue={this.state.adult} ></input>
            </div>

            <div class="form-group col-md-12">
              <span class="input-group-addon">Folding (Child)</span>
              <input onChange={this.ChildChange} type="number" class="form-control mytextbox" name="child" defaultValue={this.state.child} ></input>
            </div>

            <div class="form-group col-md-12">
              <span class="input-group-addon">Infant</span>
              <input onChange={this.InfantChange} type="number" class="form-control mytextbox" name="infant" defaultValue={this.state.infant} ></input>
            </div>

            <div class="form-group col-md-12">
              <span class="input-group-addon">Couple</span>
              <input onChange={this.CoupleChange} type="number" class="form-control mytextbox" name="couple" defaultValue={this.state.couple} ></input>
            </div>
            <div class="form-group col-md-12">
              <span class="input-group-addon">Required Seats</span>
              <input
                type="number"
                readonly
                class="form-control mytextbox"
                name="quantity"
                value={this.state.quantity}
              ></input>
            </div>
          
            <div class="input-group-btn">
              <button
                type="button"
                onClick={this.CheckBooking}
                class="btn btn-success"
              >
                Check <span class="fa fa-caret-right"></span>
              </button>
            </div>

          </form>
        </div>
      );
    } else {
      bookpath = (
        <div className="tour-booking-form">
          <div className="tour-booking-head">
            <span>{this.state.info.currency} {price}</span>
          </div>
          <form className="row sp15">
            <div class="form-group col-md-12">
              <span class="input-group-addon">Bussiness (Adult)</span>
              <input disabled="true" type="number" class="form-control mytextbox" name="adult" defaultValue={this.state.adult} ></input>
            </div>

            <div class="form-group col-md-12">
              <span class="input-group-addon"># Foliding (Child)</span>
              <input disabled="true" type="number" class="form-control mytextbox" name="child" defaultValue={this.state.child} ></input>
            </div>

            <div class="form-group col-md-12">
              <span class="input-group-addon">Infant</span>
              <input disabled="true" type="number" class="form-control mytextbox" name="infant" defaultValue={this.state.infant} ></input>
            </div>

            <div class="form-group col-md-12">
              <span class="input-group-addon">Couple</span>
              <input disabled="true" type="number" class="form-control mytextbox" name="couple" defaultValue={this.state.couple} ></input>
            </div>

            <div class="form-group col-md-12">
              <span class="input-group-addon">Required Seats</span>
              <input
                type="number"
                readonly
                class="form-control mytextbox"
                name="quantity"
                value={this.state.quantity}
              ></input>
            </div>

            <div class="input-group-btn">
              <Link
                class="right"
                to={{
                  pathname: "/Booking/Package/" + this.state.info.id,
                  state: {
                    pre_state: this.state.pre_state,
                    info: this.state.info,
                    from_date: this.state.from_date,
                    price: this.state.price,
                    quantity: this.state.quantity,
                    adult: this.state.adult,
                    child: this.state.child,
                    infant: this.state.infant,
                    couple: this.state.couple
                  },
                }}
              >
                <button type="button" class="btn btn-success">
                  Book <span class="fa fa-caret-right"></span>
                </button>
              </Link>
            </div>
          </form>
        </div>
      );
    }

    var accom = <span class="fa fa-remove danger"></span>
    if (this.state.info.accommodation == 1) {
      accom = <span class="fa fa-ok success"></span>
    }

    var insur = <span class="fa fa-remove danger"></span>
    if (this.state.info.insurance == 1) {
      insur = <span class="fa fa-ok success"></span>
    }

    const video_option = {
      height: '390',
      width: '640',
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 0
      }
    };
    var video = "";
    if (this.state.info.tour_details && this.state.info.tour_details != "") {
      var videoid = this.state.info.tour_details;
      var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      var match = videoid.match(regExp);
      videoid = (match && match[2].length == 11) ? match[2] : false;

      video = <YouTube
        videoId={videoid}
        opts={video_option}
        onReady={this._onReady}
      />

    }

    return (
      <div>
        <Header />
        <div className="listing-details-head">
          <div className="container">
            <div className="listing-info-box">
              <div className="listing-info">
                <div className="listing-info-left">
                  <h3 className="title">{this.state.info.title}</h3>
                </div>
                <div className="listing-info-right text-center">
                  <FacebookShareButton url={shareUrl}>
                    {" "}
                    <FacebookIcon size={32} round={true} />{" "}
                  </FacebookShareButton>
                  <TwitterShareButton url={shareUrl}>
                    {" "}
                    <TwitterIcon size={32} round={true} />{" "}
                  </TwitterShareButton>
                  <LinkedinShareButton url={shareUrl}>
                    {" "}
                    <LinkedinIcon size={32} round={true} />{" "}
                  </LinkedinShareButton>
                  <EmailShareButton url={shareUrl}>
                    {" "}
                    <EmailIcon size={32} round={true} />{" "}
                  </EmailShareButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="listing-details-nav">
          <div className="container">
            <ul className="listing-nav nav justify-content-center">
              <li>
                <Link className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => { this.toggle('1'); }}><i className="la la-home"></i><span>Home</span>
                </Link>
              </li>
              <li>
                <Link className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggle('2'); }}><i className="la la-file-text"></i><span>Description</span>
                </Link>
              </li>
              <li>
                <Link className={classnames({ active: this.state.activeTab === '4' })}
                  onClick={() => { this.toggle('4'); }}><i className="la la-image"></i><span>Photos</span>
                </Link>
              </li>
              <li>
                <Link className={classnames({ active: this.state.activeTab === '5' })}
                  onClick={() => { this.toggle('5'); }}><i className="la la-video-camera"></i><span>Videos</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>


        <div className="section-full listing-details-content">
          <div className="container">
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <div className="row">
                  <div className="col-xl-8 col-lg-7 col-md-12">
                    <div className="content-box">
                      <div className="content-header">
                        <h3 className="title"><i className="la la-star-o m-r5"></i> Information</h3>
                      </div>
                      <div className="content-body row">
                        <div class="icon-content col-md-6">
                          <h6 class="text-uppercase m-tb0 dlab-tilte">Adult Price</h6>
                          <p>{this.state.info.price}</p>
                        </div>
                        <div class="icon-content col-md-6">
                          <h6 class="text-uppercase m-tb0 dlab-tilte">Child Price</h6>
                          <p>{this.state.info.child_price}</p>
                        </div>
                        <div class="icon-content col-md-6">
                          <h6 class="text-uppercase m-tb0 dlab-tilte">Infant Price</h6>
                          <p>{this.state.info.infant_price}</p>
                        </div>
                        <div class="icon-content col-md-6">
                          <h6 class="text-uppercase m-tb0 dlab-tilte">Couple Price</h6>
                          <p>{this.state.info.couple_price}</p>
                        </div>
                        <div class="icon-content col-md-6">
                          <h6 class="text-uppercase m-tb0 dlab-tilte">Available Bussiness Seats</h6>
                          <p>{this.state.info.business_seats}</p>
                        </div>
                        <div class="icon-content col-md-6">
                          <h6 class="text-uppercase m-tb0 dlab-tilte">Available Folding Seats</h6>
                          <p>{this.state.info.folding_seats}</p>
                        </div>
                        <div class="icon-content col-md-6">
                          <h6 class="text-uppercase m-tb0 dlab-tilte">Booked Seats</h6>
                          <p>{this.state.info.booked}</p>
                        </div>
                        <div class="icon-content col-md-6">
                          <h6 class="text-uppercase m-tb0 dlab-tilte">Total Available Seats</h6>
                          <p>{this.state.info.available_seats}</p>
                        </div>
                        <div class="icon-content col-md-6">
                          <h6 class="text-uppercase m-tb0 dlab-tilte">Departure Date</h6>
                          <p>{this.state.info.departure_date}</p>
                        </div>
                        <div class="icon-content col-md-6">
                          <h6 class="text-uppercase m-tb0 dlab-tilte">City</h6>
                          <p>{this.state.info.city_name}</p>
                        </div>
                        <div class="icon-content col-md-6">
                          <h6 class="text-uppercase m-tb0 dlab-tilte">Category</h6>
                          <p>{this.state.info.category}</p>
                        </div>
                        <div class="icon-content col-md-6">
                          <h6 class="text-uppercase m-tb0 dlab-tilte">Departure Cities</h6>
                          <p>{this.state.info.fromcity}</p>
                        </div>
                      </div>
      
                    </div>
                    <div className="content-box">
                      <div className="content-header"> <h3 className="title">Description</h3></div>
                      <div className="content-body" dangerouslySetInnerHTML={{ __html: this.state.info.description }}></div>

                      <div className="content-header"><h3 className="title">Stay Details</h3></div>
                      <div className="content-body" dangerouslySetInnerHTML={{ __html: this.state.info.stay_details }}></div>

                      <div className="content-header"><h3 className="title">Ride Details</h3></div>
                      <div className="content-body" dangerouslySetInnerHTML={{ __html: this.state.info.ride_details }}></div>

                      <div className="content-header"><h3 className="title">Food Details</h3></div>
                      <div className="content-body" dangerouslySetInnerHTML={{ __html: this.state.info.food_details }}></div>

   
                      <div className="content-header"><h3 className="title">Tour Schedule</h3></div>
                      <div className="content-body" dangerouslySetInnerHTML={{ __html: this.state.info.tour_shedule }}></div>
                    </div>

                    <div className="content-box">
                      <div className="content-header">
                        <h3 className="title"><i className="la la-file-image-o"></i> photos</h3>
                      </div>
                      <div className="content-body">
                        <div className="widget widget_gallery gallery-grid-4 lightgallery">
                          {gallery}
                        </div>
                      </div>
                    </div>
                    <div className="content-box">
                      <div className="content-header">
                        <h3 className="title"><i className="la la-video-camera m-r5"></i>videos</h3>
                      </div>
                      <div className="content-body">
                        <div className="widget widget_video video-grid-4">
                          <ul>
                            <li>
                              <div className="dlab-post-thum video-bx">
                                {video}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-5 col-md-12 sticky-top">
                    <aside className="side-bar listing-side-bar">
                      <div className="content-box">
                        <div className="content-header">
                          <h3 className="title"><i className="la la-bullhorn m-r5"></i>Booking Panel</h3>
                        </div>
                        <div className="content-body">
                          {this.state.message}
                          {bookpath}
                        </div>
                      </div>
                    </aside>
                  </div>

                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="content-box">

                      <div className="content-header"> <h3 className="title">Description</h3></div>
                      <div className="content-body" dangerouslySetInnerHTML={{ __html: this.state.info.description }}></div>

                      <div className="content-header"><h3 className="title">Stay Details</h3></div>
                      <div className="content-body" dangerouslySetInnerHTML={{ __html: this.state.info.stay_details }}></div>

                      <div className="content-header"><h3 className="title">Ride Details</h3></div>
                      <div className="content-body" dangerouslySetInnerHTML={{ __html: this.state.info.ride_details }}></div>

                      <div className="content-header"><h3 className="title">Food Details</h3></div>
                      <div className="content-body" dangerouslySetInnerHTML={{ __html: this.state.info.food_details }}></div>

    
                      <div className="content-header"><h3 className="title">Tour Schedule</h3></div>
                      <div className="content-body" dangerouslySetInnerHTML={{ __html: this.state.info.tour_schedule }}></div>


                    </div>
                  </div>
                </div>
              </TabPane>

              <TabPane tabId="4">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="content-box">
                      <div className="content-header">
                        <h3 className="title">photos</h3>
                      </div>
                      <div className="content-body">
                        <div className="widget widget_gallery gallery-grid-4 lightgallery">
                          {gallery}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="5">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="content-box">
                      <div className="content-header">
                        <h3 className="title">videos</h3>
                      </div>
                      <div className="content-body">
                        <div className="widget widget_video video-grid-4">
                          <ul>
                            <li>
                              <div className="dlab-post-thum video-bx">
                                {video}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}


export default (PackageDetail);
