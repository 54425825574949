import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import Select from 'react-select';
import Header from './../../Layout/Header';
import Footer from './../../Layout/Footer';
import ListBox1 from './../ListBox1';
import ListBox2 from './../ListBox2';
import common from './../../services/common';

var moment = require('moment');



class Booking extends React.Component {
  state = { value: 0 };
  constructor(props) {
    super(props);
    this.state.info = [];
    this.state.booking_id = 0;
    this.state.pre_state = props.location.state;
    this.state.category = this.props.match.params ? this.props.match.params.category : "";
    this.state.id = this.props.match.params ? this.props.match.params.id : "";
    this.state.form = null;
    this.state.currency_rate = 1;
    console.log("Pre state booking", this.state.pre_state);
    //for testing
    //this.state.pre_state = {"price":1000,"info":{"id":12,"subcategory_id":14,"user_id":2,"title":null,"food_plain":"","price":1000,"video":"","from_time":null,"to_time":null,"picture":"/uploads/services/1615435655.jpeg,/uploads/services/16154356551.jpeg,/uploads/services/16154356552.jpeg","available":null,"mobile":null,"email":null,"user_by":null,"description":"No videonso far","status":"Approved","createdAt":1615435678,"updatedAt":null,"view":8,"date":null,"category":"Tour Guide","user_name":"M Tariq","currency_id":1,"currency":"Rs","city_list":[],"date_list":[{"date":"2021-03-11"},{"date":"2021-03-12"},{"date":"2021-03-13"},{"date":"2021-03-18"},{"date":"2021-03-19"},{"date":"2021-03-16"},{"date":"2021-03-15"},{"date":"2021-03-14"},{"date":"2021-03-17"},{"date":"2021-03-20"}]},"date":"2021-03-16"};

    if (this.state.pre_state) {
      var price = this.state.pre_state.price; //this.state.category == "Ride" ? this.state.pre_state.person*this.state.pre_state.info.price*(this.state.pre_state.pre_state ? this.state.pre_state.pre_state.distance.value/1000 : 0) : this.state.pre_state.info.price;
      var paid = Math.ceil(process.env.REACT_APP_PAID_PERCENT * price);
      price = Math.ceil(price);
      this.state.displayprice = price;
      this.state.price = price;
      this.state.paid = paid;
      this.state.user_id = common.user_id();
      this.handleSubmit = this.handleSubmit.bind(this);
      this.UpsertBooking = this.UpsertBooking.bind(this);
      this.CryptoJS = window.CryptoJS;
      this.getInfo = this.getInfo.bind(this);
      this.onEditorChange1 = this.onEditorChange1.bind(this);
      //var tDate = Cdate.getFullYear()+''+(1+Cdate.getMonth())+''+Cdate.getDate()+''+Cdate.getHours()+''+Cdate.getMinutes()+''+Cdate.getSeconds();
      this.getCurrencyRate(this.state.pre_state.info.currency_id);
      console.log("tnsbay pre booking", this.state.pre_state, moment().format('YYYYMMDDHHmmss'));
    }
  }
  getCurrencyRate(currency_id) {
    const request = new Request(process.env.REACT_APP_BASE_PATH + "currency/" + currency_id, { method: "GET" });
    fetch(request).then(res => {
      res.json().then(json => {
        this.setState({ currency_rate: json.rate });
        console.log(json);
      })
    })
  }
  getInfo = (item) => {
    var price = this.state.price;
    var paid = this.state.paid;
    var picture = item.picture ? item.picture.split(",")[0] : "upload/default.png";
    picture = process.env.REACT_APP_FILE_PATH + picture;
    var total_title = "Total Days";
    var amount_info = <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <p class="primary">Total Amount: {common.getCurrencyValue(item.currency)} <span class="primary priceMarkupTxt_0">{price}</span></p>
      <p class="primary">{process.env.REACT_APP_PAID_PERCENT * 100}% Advance Payment: {common.getCurrencyValue(item.currency)} <span class="primary priceMarkupTxt_0">{paid}</span></p>
      <p class="primary">Total Days: <span class="primary priceMarkupTxt_0">{this.state.pre_state.quantity}</span></p>
      <p class="primary">Date: <span class="primary priceMarkupTxt_0">{this.state.pre_state.date}</span></p>
    </div>;
    if (this.state.category == "Guide") {
      var amount_info = <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <p class="primary">Total Amount: {common.getCurrencyValue(item.currency)} <span class="primary priceMarkupTxt_0">{price}</span></p>
        <p class="primary">{process.env.REACT_APP_PAID_PERCENT * 100}% Advance Payment: {common.getCurrencyValue(item.currency)} <span class="primary priceMarkupTxt_0">{paid}</span></p>
        <p class="primary">Total No Days: <span class="primary priceMarkupTxt_0">{this.state.pre_state.quantity}</span></p>
        <p class="primary">Date: <span class="primary priceMarkupTxt_0">{this.state.pre_state.from_date}</span></p>
      </div>;
      picture = item.picture ? item.picture.split(",")[0] : "upload/default.png";
      picture = process.env.REACT_APP_FILE_PATH + picture;
      var param = {
        picture: picture,
        title: item.category,
        category: item.category,
        price: item.price,
        username: item.user_name,
        //item_list: item.city.split(",").slice(0,4),
        booked: item.booked > 0 ? "YES" : "NO",
        remaining: item.booked > 0 ? "NO" : "YES",
        type: "guide",
        rating: 3,
        link: "#"
      }
      return (
        <div class="row">
          <ListBox1 param={param} key={item.id} />
          {amount_info}
        </div>
      )
    } else if (this.state.category == "Ride") {
      

      picture = item.picture ? item.picture.split(",")[0] : "upload/default.png";
      picture = process.env.REACT_APP_FILE_PATH + picture;

      var remaining = item.seats - item.booked;
      var rent_price = "";
      if (item.rent_type == 1) { //KM
        total_title = "Total KM";
        rent_price = <ul className="icon-box-list list-col-4">
          <li><span>From 0-25/KM: {item.price}</span></li>
          <li><span>From 25-50/KM: {item.price_25_50_km}</span></li>
          <li><span>From 50_500/KM: {item.price_50_500_km}</span></li>
        </ul>
      } else if (item.rent_type == 3) { //Seat
        total_title = "Total Seat";
        rent_price = item.price_seat + '/Seat';
      } else { //day
        total_title = "Total Days";
        rent_price = item.price_day + '/Day';
      }
      var amount_info = <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <p class="primary">Total Amount: {common.getCurrencyValue(item.currency)} <span class="primary priceMarkupTxt_0">{price}</span></p>
        <p class="primary">{process.env.REACT_APP_PAID_PERCENT * 100}% Advance Payment: {common.getCurrencyValue(item.currency)} <span class="primary priceMarkupTxt_0">{paid}</span></p>
        <p class="primary">{total_title}: <span class="primary priceMarkupTxt_0">{this.state.pre_state.quantity}</span></p>
        <p class="primary">Date: <span class="primary priceMarkupTxt_0">{this.state.pre_state.from_date}</span></p>
      </div>;

      var param = {
        picture: picture,
        title: item.company,
        category: item.category,
        price: rent_price,
        username: item.user_name,
        booked: item.booked,
        remaining: remaining,
        type: "ride",
        //item_list: item.city.split(",").slice(0, 4),
        rating: 3,
        link: "#"
      }
      return (
        <div class="row">
          <ListBox1 param={param} key={item.id} />
          {amount_info}
        </div>
      )
    } else if (this.state.category == "Stay") {
      var total_title = "Rooms";
      if (this.state.pre_state.info.subcategory_id == 7) {
        total_title = "BED";
      } else if (this.state.pre_state.info.subcategory_id == 11) {
        total_title = "CAMP";
      }      
      var amount_info = <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <p class="primary">Total Amount: {common.getCurrencyValue(item.currency)} <span class="primary priceMarkupTxt_0">{price}</span></p>
        <p class="primary">{process.env.REACT_APP_PAID_PERCENT * 100}% Advance Payment: {common.getCurrencyValue(item.currency)} <span class="primary priceMarkupTxt_0">{paid}</span></p>
        <p class="primary">Total Days: <span class="primary priceMarkupTxt_0">{this.state.pre_state.quantity}</span></p>
        <p class="primary">Booked {total_title}: <span class="primary priceMarkupTxt_0">{this.state.pre_state.number}</span></p>
        <p class="primary">Date: <span class="primary priceMarkupTxt_0">{this.state.pre_state.from_date}</span></p>
        <p class="primary">Adult: <span class="primary priceMarkupTxt_0">{this.state.pre_state.adult}</span></p>
        <p class="primary">Child: <span class="primary priceMarkupTxt_0">{this.state.pre_state.child}</span></p>
      </div>;

      picture = item.room_picture ? item.room_picture.split(",")[0] : "upload/default.png";
        picture = process.env.REACT_APP_FILE_PATH+picture;
        var remaining = item.rooms - item.booked;
        var param = {
          picture: picture, 
          title: item.title,
          category: item.category,
          price: item.service_price +'/night',
          username: item.user_name,
          address: item.address,
          booked: item.booked,
          remaining: remaining,
          type : "stay",
          rating: 3,
          link: "#"
        }
      return (
        <div class="row">
          <ListBox1 param={param} key={item.id} />
          {amount_info}
        </div>
      )
    } else if (this.state.category == "Package") {

      var amount_info = <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <p class="primary">Total Amount: {common.getCurrencyValue(item.currency)} <span class="primary priceMarkupTxt_0">{price}</span></p>
        <p class="primary">{process.env.REACT_APP_PAID_PERCENT * 100}% Advance Payment: {common.getCurrencyValue(item.currency)} <span class="primary priceMarkupTxt_0">{paid}</span></p>
        {/* <p class="primary">Date: <span class="primary priceMarkupTxt_0">{this.state.pre_state.from_date}</span></p> */}
        <p class="primary">Adult: <span class="primary priceMarkupTxt_0">{this.state.pre_state.adult}</span></p>
        <p class="primary">Child: <span class="primary priceMarkupTxt_0">{this.state.pre_state.child}</span></p>
        <p class="primary">Infant: <span class="primary priceMarkupTxt_0">{this.state.pre_state.infant}</span></p>
        <p class="primary">Couple: <span class="primary priceMarkupTxt_0">{this.state.pre_state.couple}</span></p>
      </div>;

      picture = item.ride_picture ? item.ride_picture : "upload/default.png";
      picture = process.env.REACT_APP_FILE_PATH + picture;

      var param = {
        picture: picture,
        title: item.title,
        category: item.category,
        username: item.user_name,
        booked: item.booked,
        remaining: item.business_seats + item.folding_seats - item.booked,
        price: item.price,
        couple_price: item.couple_price,
        child_price: item.child_price,
        infant_price: item.infant_price,
        item_list: item.fromcity.split(",").slice(0,4),
        date: item.departure_date.split("T")[0],
        link: "#"
      }

      return (
        <div class="row">
          <ListBox2 param={param} key={item.id} />
          {amount_info}
        </div>
      )
    } else if (this.state.category == "Pilgrim") {

      var amount_info = <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <p class="primary">Total Amount: {common.getCurrencyValue(item.currency)} <span class="primary priceMarkupTxt_0">{price}</span></p>
        <p class="primary">{process.env.REACT_APP_PAID_PERCENT * 100}% Advance Payment: {common.getCurrencyValue(item.currency)} <span class="primary priceMarkupTxt_0">{paid}</span></p>
        {/* <p class="primary">Total Days: <span class="primary priceMarkupTxt_0">{this.state.pre_state.quantity}</span></p> */}
        <p class="primary">Date: <span class="primary priceMarkupTxt_0">{this.state.pre_state.from_date}</span></p>
        <p class="primary">Adult: <span class="primary priceMarkupTxt_0">{this.state.pre_state.adult}</span></p>
        <p class="primary">Child: <span class="primary priceMarkupTxt_0">{this.state.pre_state.child}</span></p>
        <p class="primary">Infant: <span class="primary priceMarkupTxt_0">{this.state.pre_state.infant}</span></p>
      </div>;

      picture = item.ride_picture ? item.ride_picture : "upload/default.png";
      picture = process.env.REACT_APP_FILE_PATH + picture;

      var param = {
        picture: picture,
        title: item.title,
        category: item.category,
        username: item.user_name,
        booked: item.booked,
        package:"pilgrim",
        remaining: item.business_seats + item.folding_seats - item.booked,
        price: item.price,
        couple_price: item.couple_price,
        child_price: item.child_price,
        infant_price: item.infant_price,
        item_list: item.fromcity.split(",").slice(0,4),
        date: item.departure_date.split("T")[0],
        link: "#"
      }

      return (
        <div class="row">
          <ListBox2 param={param} key={item.id} />
          {amount_info}
        </div>
      )
    }
  };

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ form: event.target });
    var post_data = new FormData(event.target);
    post_data.append("subcategory_id", this.state.id);
    post_data.append("status", "pending");
    post_data.append("currency_id", this.state.pre_state.info.currency_id ? this.state.pre_state.info.currency_id : 1);
    if (this.state.category == "Guide") {
      if (this.state.pre_state.pre_state) {
        post_data.append("city", this.state.pre_state.pre_state.city);
        post_data.append("country", this.state.pre_state.pre_state.country);
        post_data.append("src_address", this.state.pre_state.pre_state.address);
        post_data.append("src_latitude", this.state.pre_state.info.latitude);
        post_data.append("src_longitude", this.state.pre_state.info.longitude);
      }

      post_data.append("quantity", this.state.pre_state.quantity);
      post_data.append("dest_address", "");
      post_data.append("dest_latitude", "");
      post_data.append("dest_longitude", "");
      post_data.append("service_id", 3);
      post_data.append("total", this.state.price);
      //post_data.append("paid", this.state.paid);
      post_data.append("user_id", this.state.user_id);
      post_data.append("date_from", this.state.pre_state.date);
      post_data.append("date_to", "");
    } else if (this.state.category == "Ride") {
      post_data.append("city", this.state.pre_state.pre_state.from_city);
      post_data.append("country", this.state.pre_state.pre_state.from_country);
      post_data.append("src_address", this.state.pre_state.pre_state.ride_from);
      post_data.append("src_latitude", this.state.pre_state.pre_state.from_lat);
      post_data.append("src_longitude", this.state.pre_state.pre_state.from_lng);
      post_data.append("dest_address", this.state.pre_state.pre_state.ride_to);
      post_data.append("dest_latitude", this.state.pre_state.pre_state.to_lat);
      post_data.append("dest_longitude", this.state.pre_state.pre_state.to_lng);
      post_data.append("service_id", 1);
      post_data.append("total", this.state.price);
      //post_data.append("paid", this.state.paid);
      post_data.append("user_id", this.state.user_id);
      //post_data.append("person", this.state.pre_state.person);
      post_data.append("date_from", this.state.pre_state.from_date);
      post_data.append("date_to", this.state.pre_state.to_date);
      post_data.append("two_way", this.state.pre_state.two_way);
      post_data.append("quantity", this.state.pre_state.quantity);
      post_data.append("number", this.state.pre_state.quantity);
      post_data.append("type", this.state.pre_state.rent_type);
    } else if (this.state.category == "Stay") {
      if (this.state.pre_state.pre_state) {
        post_data.append("country", this.state.pre_state.pre_state.country);
      }
      post_data.append("city", this.state.pre_state.info.city_name);
      post_data.append("src_address", this.state.pre_state.info.address);
      post_data.append("src_latitude", this.state.pre_state.info.latitude);
      post_data.append("src_longitude", this.state.pre_state.info.longitude);
      post_data.append("dest_address", "");
      post_data.append("dest_latitude", "");
      post_data.append("dest_longitude", "");
      post_data.append("service_id", 2);
      post_data.append("total", this.state.price);
      //post_data.append("paid", this.state.paid);
      post_data.append("user_id", this.state.user_id);
      post_data.append("date_from", this.state.pre_state.from_date);
      post_data.append("date_to", this.state.pre_state.to_date);
      post_data.append("quantity", this.state.pre_state.quantity);
      post_data.append("adult", this.state.pre_state.adult);
      post_data.append("child", this.state.pre_state.child);
      post_data.append("number", this.state.pre_state.number);

    } else if (this.state.category == "Package") {
      post_data.append("quantity", this.state.pre_state.quantity);
      post_data.append("city", this.state.pre_state.pre_state ?  this.state.pre_state.pre_state.city  :"");
      post_data.append("country", this.state.pre_state.info.fromcity ? this.state.pre_state.info.fromcity : "");
      post_data.append("src_address", this.state.pre_state.info.fromcity ? this.state.pre_state.info.fromcity : "");
      post_data.append("src_latitude", "");
      post_data.append("src_longitude", "");
      post_data.append("dest_address", this.state.pre_state.info.tocity ? this.state.pre_state.info.tocity : "");
      post_data.append("dest_latitude", "");
      post_data.append("dest_longitude", "");
      post_data.append("service_id", 4);
      post_data.append("total", this.state.price);
      //post_data.append("paid", this.state.paid);
      post_data.append("user_id", this.state.user_id);
      post_data.append("date_from", this.state.pre_state.info.FromDate);
      post_data.append("date_to", this.state.pre_state.info.ToDate);
      post_data.append("adult", this.state.pre_state.adult);
      post_data.append("child", this.state.pre_state.child);
      post_data.append("infant", this.state.pre_state.infant);
      post_data.append("couple", this.state.pre_state.couple);

    } else if (this.state.category == "Pilgrim") {
      post_data.append("quantity", this.state.pre_state.quantity);
      post_data.append("city", this.state.pre_state.pre_state ?  this.state.pre_state.pre_state.city  :"");
      post_data.append("country", this.state.pre_state.info.fromcity ? this.state.pre_state.info.fromcity : "");
      post_data.append("src_address", this.state.pre_state.info.fromcity ? this.state.pre_state.info.fromcity : "");
      post_data.append("src_latitude", "");
      post_data.append("src_longitude", "");
      post_data.append("dest_address", this.state.pre_state.info.tocity ? this.state.pre_state.info.tocity : "");
      post_data.append("dest_latitude", "");
      post_data.append("dest_longitude", "");
      post_data.append("service_id", 5);
      post_data.append("total", this.state.price);
      //post_data.append("paid", this.state.paid);
      post_data.append("user_id", this.state.user_id);
      post_data.append("date_from", this.state.pre_state.info.FromDate);
      post_data.append("date_to", this.state.pre_state.info.ToDate);
      post_data.append("adult", this.state.pre_state.adult);
      post_data.append("child", this.state.pre_state.child);
      post_data.append("infant", this.state.pre_state.infant);
      post_data.append("couple", this.state.pre_state.couple);
    }
    this.UpsertBooking(post_data);
  };

  UpsertBooking(post_data) {
    const request = new Request(process.env.REACT_APP_BASE_PATH + "UpsertBooking", { method: "POST", body: post_data });
    fetch(request).then(res => {
      res.json().then(json => {
        this.state.booking_id = json.id;
        this.setState({ message: <div class="success">Request sent successfully!</div> });
      });
    }).catch(err => {
      this.setState({ message: <div class="danger">{err.message}</div> });
      console.log("Error in Get menu", err.message);
    });
  };

  create_form_element(key, value) {
    var hiddenField = document.createElement("input");
    hiddenField.setAttribute("type", "hidden");
    hiddenField.setAttribute("name", key);
    hiddenField.setAttribute("value", value);
    return hiddenField;
  }
  onEditorChange1(evt) {
    this.setState({
      description: evt.editor.getData()
    });
  }
  render() {
    if (this.state.booking_id > 0) {
      return <Redirect to={{ pathname: "/Payment/" + this.state.booking_id, amount: this.state.paid }} />
    }
    var booking_info = this.state.pre_state ? this.getInfo(this.state.pre_state.info) : "";
    return (
      <>
        <Header />
        <div className="listing-details-head">
          <div className="container">
            <div className="hotel-booking">
              {this.state.message}
              {booking_info}
              <form id="myform" onSubmit={this.handleSubmit}>
                <div class="myform1 col-lg-10 col-md-12 col-sm-12">
                  {this.state.message}
                  <button type="submit" className="btn btn-primary btn-block">CONFIRM BOOKING</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
    //}
  }
}


export default (Booking);
