import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../Layout/Header';
import Footer from './../Layout/Footer';
import ContactInfo from './Common/ContactInfo';

var bg3 = require('./../../images/banner/bnr1.jpg').default;
class Contacts extends Component {
    constructor(props){
        super(props);        
        var type = this.props.match ? this.props.match.params.type : 0;
        var description = type == "package" ? "I want customize package please find the package detail below" : "asdasdsa";
        this.state = {message : "",description:description};
        this.handleSubmit = this.handleSubmit.bind(this);
    };
    handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.target);
        data.append("description", this.state.description);
        this.SubmitData(data);

    };
    SubmitData = (data) => {
        const request = new Request(process.env.REACT_APP_BASE_PATH+"webcontact", {method: "POST", body: data});
        fetch(request).then(res => {
        res.json().then(json => {	
                if(json.error){
                    this.setState({message: <div class="danger">{json.message}</div>});
                } else {
                    this.setState({message: <div class="success">{json.message}</div>});
                }			            
            });
        }).catch(err => {
            this.setState({message: <div class="danger">{err.message}</div>});
        });
    }
    render() {
        return (
            <div>
                <Header />
                <div className="dlab-bnr-inr overlay-black-middle" style={{ backgroundImage: "url(" + bg3 + ")", backgroundSize: 'cover' }}>
                    <div className="container">
                        <div className="dlab-bnr-inr-entry">
                            <h1 className="text-white">Contact Us</h1>
                        </div>
                    </div>
                </div>
                <div className="section-full content-inner bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <ContactInfo />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="p-a30 m-b30 	bg-gray clearfix">
                                    <h4>Send Message Us</h4>
                                    <div className="dzFormMsg">{this.state.message}</div>
                                    <form id="myform" onSubmit={this.handleSubmit}>
                                        <input type="hidden" value="Contact" name="dzToDo" />
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <input name="name" type="text" required className="form-control" placeholder="Your Name" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <input name="email" type="email" className="form-control" required placeholder="Your Email Id" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <textarea name="description" rows="4" className="form-control" required placeholder="Your Message..." defaultValue={this.state.description} ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <button name="submit" type="submit" value="Submit" className="site-button "> <span>Submit</span> </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
export default Contacts;