import React, { Component } from 'react'
import Select from 'react-select';
import { trackPromise } from 'react-promise-tracker';

class Account extends React.Component {
  state = {value: 0};
  constructor(props){
    super(props);
    var user_id = localStorage.getItem("id");
    this.state = {cat_list:[], info: [], user_id: user_id, pk:0, selected: null};
    this.getList();
    this.handleSubmit = this.handleSubmit.bind(this);    
  }
  getInfo = (id) => {
    const request = new Request(process.env.REACT_APP_BASE_PATH+"UserAccount?user_id="+id, {method: "GET"});
    trackPromise(
		fetch(request).then(res => {
                res.json().then(json => {	
                    var obj = json.length > 0 ? json[0]: json;			
                    this.setState({ info: obj });
                    if(json.length > 0){
                      this.setState({pk: obj.id});
                      var item = this.GetSelectVal(obj.category_id);
                      this.setState({selected: item});
                    }	  
                });
        }).catch(err => {
            console.log("Error in Get menu", err);
        })
    )
  };
  GetSelectVal(val){
    var result = null;
    for(var i = 0; i < this.state.cat_list.length; i++){
      if(this.state.cat_list[i].value == val){
         result = this.state.cat_list[i];
      }
    }
    return result;
  }
  getList = () => {
    const request = new Request(process.env.REACT_APP_BASE_PATH+"AccountCategory?status=true", {	method: "GET"});
    fetch(request).then(res => {
      res.json().then(obj => {		
        var listItems = [];
        Object.keys(obj).forEach(function(key) {
          listItems.push({"value":obj[key].id, "label":obj[key].title});
        });
        this.setState({ cat_list: listItems });
        this.getInfo(this.state.user_id);
      });
    }).catch(err => {
        console.log("Error in Get menu", err);
    });
};
  
handleSubmit(event) {
  event.preventDefault();
  var post_data  = new FormData(event.target);
  var url_id =  "";
  var method = "POST";//for insert
  if(this.state.pk > 0){
    url_id = "/"+this.state.pk;
    method = "PUT";
  }
  post_data.append("user_id", this.state.user_id);
  const request = new Request(process.env.REACT_APP_BASE_PATH+"UserAccount"+url_id , {method: method, body: post_data});
  fetch(request).then(res => {
  res.json().then(json => {	
      if(json.message){
          this.setState({message: <div class="danger">{json.message}</div>});
      } else {
          this.setState({pk: json.id});
          this.setState({message: <div class="success">Account updated successfully!</div>});
      }	            
    });
  }).catch(err => {
      this.setState({message: <div class="danger">{err.message}</div>});
      console.log("Error in Get menu", err.message);
  });
};
handleChange = selectedOption => {
  this.setState({selected:selectedOption});
};
  render() {
    return (
        <div class="panel panel-primary">
        <div class="panel-body">
            <div class="ramadan-packages print-hajj-packages">
                <div class="page_heading"><span >Payment Account</span></div>
                <form id="myform" onSubmit={this.handleSubmit}>
               
                  <div class="myform1 col-lg-4 col-md-6 col-sm-12">
                      {this.state.message}

                      <div className="form-group">
                        <label>Select Category</label>
                        <Select id="category_id" name="category_id" options={this.state.cat_list} 
                        value={this.state.selected}
                        onChange={this.handleChange}
                         />
                    </div>

                      <div className="form-group">
                          <label>Account Title</label>
                          <input type="text" defaultValue={this.state.info.title} id="title" name="title" className="form-control" placeholder="Enter Account Title" required />
                      </div>

                      <div className="form-group">
                          <label>Account Number</label>
                          <input type="number"defaultValue={this.state.info.number} id="number" name="number" className="form-control" placeholder="Enter Account Number" required />
                      </div>

                      <div className="form-group">
                          <label>Branch</label>
                          <input type="text" defaultValue={this.state.info.branch} id="branch" name="branch" className="form-control" placeholder="Enter Branch" required />
                      </div>

                      <button type="submit"  className="btn btn-primary btn-block">Submit</button>
                  </div>
                  
              </form>
            </div>
        </div>
    </div>
    );
  }
}


export default (Account);
