import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SimpleReactLightbox from 'simple-react-lightbox';
import { SRLWrapper } from 'simple-react-lightbox';
import CallBack from '../Pages/Common/CallBack';
import ContactInfo from '../Pages/Common/ContactInfo';

var bg3 = require('./../../images/background/bg6.jpg').default;

class Footer extends Component {
    render() {
        return (
            <footer className="site-footer style1">
                <div className="footer-top" style={{ backgroundImage: "url(" + bg3 + ")", backgroundSize: 'cover' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12 footer-col-4">
                                <h6 className="m-b15 h6 text-uppercase">Company Info</h6>
                                <div className="dlab-separator bg-white"></div>
                                <ContactInfo />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 footer-col-4">
                                <div className="widget  widget_tag_cloud">
                                    <h6 className="m-b15 h6 text-uppercase">About</h6>
                                    <div className="dlab-separator bg-white"></div>
                                    <div className="tagcloud">
                                        <Link to="/page/about_us/1" class="dark-grey-text">About Us</Link> <br />
                                        <Link to="/page/terms/2" class="dark-grey-text"> Terms & Conditions</Link> <br />
                                        <Link to="/page/privacy/3" class="dark-grey-text"> Privacy Policy</Link> <br />
                                        <Link to="/contact" class="dark-grey-text"> Contact Us</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 footer-col-4">
                                <CallBack />
                            </div>

                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 text-left"> <span>Copyright © 2021 AbzoTrip</span> </div>
                            <div />
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
export default Footer;