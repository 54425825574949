import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import Calendar from "react-calendar-multiday";
import YouTube from 'react-youtube';
import React, { useState } from 'react'
import Header from './../../../Layout/Header';
import Footer from './../../../Layout/Footer';
import { TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';


import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import common from "../../../services/common";

var moment = require("moment");
class StayDetail extends React.Component {
  state = { value: 0 };
  constructor(props) {
    super(props);

    var today = new Date();
    var date = today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + ("0" + today.getDate()).slice(-2);
    var from_date = props.location.state ? props.location.state.from_date : date;
    var to_date = props.location.state ? props.location.state.to_date : date;
    var id = this.props.match.params ? this.props.match.params.infoid : 0;
    var allow_book = props.location.state && parseInt(localStorage.getItem("id")) > 0 ? true : false;
    this.state = {
      id: id,
      activeTab: "1",
      message: "",
      show_book: false,
      rooms_list: [],
      rooms_selected: [],
      info: { date_list: [] },
      pre_state: props.location.state,
      allow_book: allow_book,
      from_date: from_date,
      to_date: to_date,
      booked_days: {},
      adult: 1,
      child: 0,
      price: 0,
      quantity: 1,
      service_title: 'ROOM'
    };

    this.getInfo(id);
    this.AddView(id);

    this.CheckBooking = this.CheckBooking.bind(this);
    this.SetInput = this.SetInput.bind(this);
  }
  toggle = tab => {
    this.setState({activeTab: tab});
  }
 
  componentDidUpdate(prevProps) {
    var id = this.props.match.params ? this.props.match.params.infoid : 0;
    var pre_id = prevProps.match.params ? prevProps.match.params.infoid : 0;
    if (id !== pre_id) {
      this.getInfo(id);
    }
  }
  CheckBooking(evt) {
    if (this.state.price == 0) {
      this.setState({ message: <div class="danger">You need to select atleast one room!</div> });
      return false;
    }
    var date = "";
    var booked_days = JSON.parse(this.state.booked_days);
    //console.log("tnsbay clicked", this.state.quantity, booked_days, this.state.from_date, this.state.to_date);

    var html_messsage = [];
    var show_book = true;
    for (var i = 0; i < this.state.quantity; i++) {
      date = moment(this.state.from_date, "YYYY-MM-DD").add(i, 'days').format("YYYY-MM-DD");
      this.state.rooms_selected.map((index, key) => {
        var date_array = this.state.rooms_list[index].availability ? this.state.rooms_list[index].availability.split(',') : [];
        var room_id = this.state.rooms_list[index].id;
        var booked_rooms = booked_days[date] ? booked_days[date] : [];
        if (!date_array.includes(date)) {
          show_book = false;
          html_messsage.push("Date: " + date + " is not avaible");
        } else if (room_id in booked_rooms) {
          show_book = false;
          html_messsage.push("Date: " + date + " is already booked for room#" + key + 1);
        }
      })
    }
    html_messsage = html_messsage.join(", ");
    this.setState({ show_book: show_book, message: show_book ? <div class="success">Congratulation! Booking is available, you can book now!</div> : <div class="danger">{html_messsage}</div> });

  }
  getInfo = (id) => {
    const request = new Request(
      process.env.REACT_APP_BASE_PATH + "StayInfo/" + id,
      { method: "GET" }
    );
    fetch(request)
      .then((res) => {
        res.json().then((obj) => {
          if (obj.subcategory_id == 7) {
            this.setState({ service_title: 'BED' });
          } else if (obj.subcategory_id == 11) {
            this.setState({ service_title: 'CAMP' });
          }
          obj.check_in = moment(obj.check_in, ["HH:mm"]).format("hh:mm a");
          obj.check_out = moment(obj.check_out, ["HH:mm"]).format("hh:mm a");
          this.setState({ info: obj, rooms_list: obj.rooms_list, booked_days: JSON.stringify(obj.booked_days) });
          return obj;
        });
      })
      .catch((err) => {
        //console.log("Error in Get menu", err);
      });
  };
  AddView = (id) => {
    const request = new Request(
      process.env.REACT_APP_BASE_PATH + "AddView/2/" + id,
      { method: "GET" }
    );
    fetch(request)
      .then((res) => {
        //console.log(res);
      })
      .catch((err) => {
        //console.log("Error in Get menu", err);
      });
  };

  SetInput(e) {
    this.setState({ [e.target.name]: e.target.value });
    const quantity = e.target.name == "quantity" ? e.target.value : this.state.quantity;
    const from_date = e.target.name == "from_date" ? e.target.value : this.state.from_date;
    var to_date = moment(from_date, "YYYY-MM-DD").add(quantity, 'days').format("YYYY-MM-DD");
    this.setState({ to_date: to_date,[e.target.name]: e.target.value });
  }

  handlerlisting(ItemList) {
    if (ItemList && ItemList.length > 0) {
      return ItemList.map((item) => {
        return (
          <li>
            <span className="check-km" title="Light Gallery Grid 1">
                <div className="dlab-post-thum"><img src={item} alt="" /></div>
            </span>
          </li>
        );
      });
    }
  }
  onToggle(index, e) {
    let newItems = this.state.rooms_selected.slice();
    if (newItems.includes(index)) {
      newItems.splice(newItems.indexOf(index), 1);
    } else {
      newItems.push(index);
    }
    this.setState({ rooms_selected: newItems })
  }
  render() {
    let to_date = moment(new Date(this.state.to_date)); // yyyy-MM-dd
    let from_date = moment(new Date(this.state.from_date)); // yyyy-MM-dd

    var years = to_date.diff(from_date, 'years');
    var months = to_date.diff(from_date, 'months') + years * 12;
    var days = 1 + to_date.diff(from_date, 'days');
    var weeks = parseInt(days / 7);
    days = days % 7;

    var price = 0;
    this.state.rooms_selected.map((val) => {
      price += this.state.rooms_list[val].price;
    })
    price *= this.state.quantity;

    this.state.price = price;
    var picture_array = this.state.info.picture ? this.state.info.picture.split(",") : [];
    var photo_gallary = [];
    picture_array.forEach((photo) => {
      photo_gallary.push(process.env.REACT_APP_FILE_PATH + photo);
    });

    var shareUrl = window.location.href;
    var gallery = "";
    if (photo_gallary.length > 0) {
      gallery = <ul>{this.handlerlisting(photo_gallary)}</ul>;
    }

    const video_option = {
      height: '390',
      width: '640',
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 0
      }
    };
    var video = "";
    if (this.state.info.video && this.state.info.video != "") {
      var videoid = this.state.info.video;
      var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      var match = videoid.match(regExp);
      videoid = (match && match[2].length == 11) ? match[2] : false;

      video = <YouTube
        videoId={videoid}
        opts={video_option}
        onReady={this._onReady}
      />
    }

    var room_html_list = this.state.rooms_list.map((val, key) => {
      var picture_array = val.picture ? val.picture.split(",") : ['upload/default.png'];
      var photo = process.env.REACT_APP_FILE_PATH + picture_array[0];
      var date_array = val.availability.split(',');
      var facilities = val.facilities ? JSON.parse(val.facilities) : [];
      var facilities_html = facilities.map((val2, key2) => {
        return <li><span>{val2}</span></li>
      })
      return <div className="col-lg-12 col-md-12 col-sm-12" key={key}>
        <div className="listing-bx event-listing m-b10">
          <div className="listing-media">
            <img src={photo} alt="" />
          </div>
          <div className="listing-info">
            <h3 className="title">S/No. {val.id} - {this.state.service_title} No. {key + 1}</h3>
            <ul className="event-meta">
              <li className="event-date">{common.getCurrencyValue(this.state.info.currency)} {val.price}/night</li>
            </ul>
              <h3 className="title"> Facilities: </h3>
            <ul className="icon-box-list list-col-4">{facilities_html}</ul>
            <p>{val.details}</p>
            <Calendar
              reset={false}
              selected={date_array}
              isMultiple={true}
            />
          </div>
        </div>
      </div>
    })

    //console.log(picture_array, photo_gallary, gallery, this.state.rooms_list);

    var booking_list = this.state.rooms_list.map((val, key) => {
      return <li class="list-group-item" key={key}><label><input disabled={this.state.show_book ? true : false} type="checkbox" onChange={this.onToggle.bind(this, key)} value={key} checked={this.state.rooms_selected.includes(key) ? true : false} /> {this.state.service_title} No. {key + 1} - PKR {val.price}</label></li>
    })

    var bookpath = (
      <button
        type="button"
        class="btn btn-info"
        style={{ marginRight: "10px" }}
      >
        {common.getCurrencyValue(this.state.info.currency)} {price}
      </button>
    );
    if (!this.state.show_book) {
      bookpath = (
        <div>
          <ul class="list-group">{booking_list}</ul>
          <div className="tour-booking-form">
            <div className="tour-booking-head">
              <span>{common.getCurrencyValue(this.state.info.currency)} {price}</span>
            </div>
            <form className="row sp15">
              <div className="form-group col-md-12">
                <span class="input-group-addon">Days</span>
                <input onChange={this.SetInput} type="number" class="form-control mytextbox" name="quantity" defaultValue={this.state.quantity} ></input>
              </div>
              <div className="form-group col-md-12">
                <span class="input-group-addon">Date From</span>
                <input
                  type="date"
                  onChange={this.SetInput}
                  class="form-control mytextbox"
                  name="from_date"
                  defaultValue={this.state.from_date}
                ></input>
              </div>
              <div className="form-group col-md-12">
                <span class="input-group-addon">Date To</span>
                <input
                  type="date"
                  onChange={this.SetInput}
                  class="form-control mytextbox"
                  name="to_date"
                  disabled="true"
                  value={this.state.to_date}
                ></input>
              </div>
              <div className="form-group col-md-12">

                <span class="input-group-addon"># Adult</span>
                <input onChange={this.SetInput} type="number" class="form-control mytextbox" name="adult" defaultValue={this.state.adult} ></input>
              </div>
              <div className="form-group col-md-12">
                <span class="input-group-addon"># Child</span>
                <input onChange={this.SetInput} type="number" class="form-control mytextbox" name="child" defaultValue={this.state.child} ></input>
              </div>
              <div class="input-group-btn">
                <button
                  type="button"
                  onClick={this.CheckBooking}
                  class="btn btn-success"
                >
                  Check <span class="fa fa-caret-right"></span>
                </button>
              </div>
            </form>
          </div >
        </div>
      );
    } else {
      var rooms_selected = this.state.rooms_selected.map(function(entry) {
        return entry+1;
      });
      bookpath = (
        <div className="tour-booking-form">
          <div className="tour-booking-head">
            <span>{common.getCurrencyValue(this.state.info.currency)} {price}</span>
          </div>
          <form className="row sp15">
            <div className="form-group col-md-12">
              <span class="input-group-addon">Days</span>
              <input disabled="true" type="number" class="form-control mytextbox" name="quantity" value={this.state.quantity} ></input>
            </div>
            <div className="form-group col-md-12">
              <span class="input-group-addon">Date From</span>
              <input
                type="date"
                disabled="true"
                class="form-control mytextbox"
                name="from_date"
                value={this.state.from_date}
              ></input>
            </div>
            <div className="form-group col-md-12">
              <span class="input-group-addon">Date To</span>
              <input
                type="date"
                class="form-control mytextbox"
                name="to_date"
                disabled="true"
                value={this.state.to_date}
              ></input>

            </div>
            <div className="form-group col-md-12">
              <span class="input-group-addon"># Adult</span>
              <input disabled="true" type="number" class="form-control mytextbox" name="adult" value={this.state.adult} ></input>
            </div>
            <div className="form-group col-md-12">
              <span class="input-group-addon"># Child</span>
              <input disabled="true" type="number" class="form-control mytextbox" name="child" value={this.state.child} ></input>
            </div>
            <div class="input-group-btn col-md-12">
              <Link
                class="right"
                to={{
                  pathname: "/Booking/Stay/" + this.state.info.id,
                  state: {
                    pre_state: this.state.pre_state,
                    info: this.state.info,
                    from_date: this.state.from_date,
                    to_date: this.state.to_date,
                    price: this.state.price,
                    quantity: this.state.quantity,
                    number: rooms_selected.join(","),
                    adult: this.state.adult,
                    child: this.state.child
                  },
                }}
              >
                <button type="button" class="btn btn-success">
                  Book <span class="fa fa-caret-right"></span>
                </button>
              </Link>
            </div>
          </form>
        </div >
      );
    }


    return (
      <div>
        <Header />
        <div className="listing-details-head">
          <div className="container">
            <div className="listing-info-box">
              <div className="listing-info">
                <div className="listing-info-left">
                  <h3 className="title">{this.state.info.title}</h3>
                </div>
                <div className="listing-info-right text-center">
                  <FacebookShareButton url={shareUrl}>
                    {" "}
                    <FacebookIcon size={32} round={true} />{" "}
                  </FacebookShareButton>
                  <TwitterShareButton url={shareUrl}>
                    {" "}
                    <TwitterIcon size={32} round={true} />{" "}
                  </TwitterShareButton>
                  <LinkedinShareButton url={shareUrl}>
                    {" "}
                    <LinkedinIcon size={32} round={true} />{" "}
                  </LinkedinShareButton>
                  <EmailShareButton url={shareUrl}>
                    {" "}
                    <EmailIcon size={32} round={true} />{" "}
                  </EmailShareButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="listing-details-nav">
          <div className="container">
            <ul className="listing-nav nav justify-content-center">
              <li>
                <Link className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => { this.toggle('1'); }}><i className="la la-home"></i><span>Home</span>
                </Link>
              </li>
              <li>
                <Link className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggle('2'); }}><i className="la la-file-text"></i><span>Description</span>
                </Link>
              </li>
              <li>
                <Link className={classnames({ active: this.state.activeTab === '3' })}
                  onClick={() => { this.toggle('3'); }}><i className="la la-list-alt"></i><span>Listing Rooms</span>
                </Link>
              </li>
              <li>
                <Link className={classnames({ active: this.state.activeTab === '4' })}
                  onClick={() => { this.toggle('4'); }}><i className="la la-image"></i><span>Photos</span>
                </Link>
              </li>
              <li>
                <Link className={classnames({ active: this.state.activeTab === '5' })}
                  onClick={() => { this.toggle('5'); }}><i className="la la-video-camera"></i><span>Videos</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>



        <div className="section-full listing-details-content">
          <div className="container">
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <div className="row">
                  <div className="col-xl-8 col-lg-7 col-md-12">
                    <div className="content-box">
                      <div className="content-header">
                        <h3 className="title"><i className="la la-star-o m-r5"></i> Information</h3>
                      </div>
                      <div className="content-body row">
                        <div class="icon-content col-md-6">
                          <h6 class="text-uppercase m-tb0 dlab-tilte">Category</h6>
                          <p>{this.state.info.category}</p>
                        </div>
                        <div class="icon-content col-md-6">
                          <h6 class="text-uppercase m-tb0 dlab-tilte">Check In</h6>
                          <p>{this.state.info.check_in}</p>
                        </div>
                        <div class="icon-content col-md-6">
                          <h6 class="text-uppercase m-tb0 dlab-tilte">Check Out</h6>
                          <p>{this.state.info.check_out}</p>
                        </div>
                        <div class="icon-content col-md-6">
                          <h6 class="text-uppercase m-tb0 dlab-tilte">City Name</h6>
                          <p>{this.state.info.city_name}</p>
                        </div>
                        <div class="icon-content col-md-6">
                          <h6 class="text-uppercase m-tb0 dlab-tilte">Posted By</h6>
                          <p>{this.state.info.user_name}</p>
                        </div>
                        <div class="icon-content col-md-6">
                          <h6 class="text-uppercase m-tb0 dlab-tilte">Address</h6>
                          <p>{this.state.info.address}</p>
                        </div>
                      </div>
                    </div>
                    <div className="content-box">
                      <div className="content-header">
                        <h3 className="title"><i className="la la-file-text m-r5"></i> description</h3>
                      </div>
                      <div className="content-body"
                        dangerouslySetInnerHTML={{
                          __html: this.state.info.description,
                        }} >
                      </div>
                    </div>
                    <div className="content-box">
                      <div className="content-header">
                        <h3 className="title"><i className="la la-list-alt m-r5"></i>Room Listing</h3>
                      </div>
                      {room_html_list}
                    </div>
                    <div className="content-box">
                      <div className="content-header">
                        <h3 className="title"><i className="la la-file-image-o"></i> photos</h3>
                      </div>
                      <div className="content-body">
                        <div className="widget widget_gallery gallery-grid-4 lightgallery">
                          {gallery}
                        </div>
                      </div>
                    </div>
                    <div className="content-box">
                      <div className="content-header">
                        <h3 className="title"><i className="la la-video-camera m-r5"></i>videos</h3>
                      </div>
                      <div className="content-body">
                        <div className="widget widget_video video-grid-4">
                          <ul>
                            <li>
                              <div className="dlab-post-thum video-bx">
                                {video}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>


                  </div>

                  <div className="col-xl-4 col-lg-5 col-md-12 sticky-top">
                    <aside className="side-bar listing-side-bar">
                      <div className="content-box">
                        <div className="content-header">
                          <h3 className="title"><i className="la la-bullhorn m-r5"></i>Booking Panel</h3>
                        </div>
                        <div className="content-body">
                          {this.state.message}
                          {bookpath}
                        </div>
                      </div>
                    </aside>
                  </div>

                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="content-box">
                      <div className="content-header">
                        <h3 className="title">description</h3>
                      </div>
                      <div className="content-body"
                        dangerouslySetInnerHTML={{
                          __html: this.state.info.description,
                        }} >
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="3">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="content-box">
                      <div className="content-header">
                        <h3 className="title">Room Listing</h3>
                      </div>
                      <div className="content-body">
                        {room_html_list}
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="4">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="content-box">
                      <div className="content-header">
                        <h3 className="title">photos</h3>
                      </div>
                      <div className="content-body">
                        <div className="widget widget_gallery gallery-grid-4 lightgallery">
                          {gallery}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="5">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="content-box">
                      <div className="content-header">
                        <h3 className="title">videos</h3>
                      </div>
                      <div className="content-body">
                        <div className="widget widget_video video-grid-4">
                          <ul>
                            <li>
                              <div className="dlab-post-thum video-bx">
                                {video}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>


            </TabContent>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default StayDetail;
