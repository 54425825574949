import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

// import {} from 'react-router-dom'



class Header extends Component {


    constructor(props) {
        super(props);
        this.state = { menuItems: [], user_list: [] };
        this.logoutClick = this.logoutClick.bind(this);
        this.getMenueList();
        this.UserChat = this.UserChat.bind(this);
        this.UserChatList = this.UserChatList.bind(this);
    }
    UserChat(user_info) {
        this.refs.child.toggleChat(user_info);
    }
    UserChatList(e) {
        const request = new Request(process.env.REACT_APP_BASE_PATH + "ChatUser/" + this.state.user_id, { method: "GET" });
        fetch(request).then(res => res.json()).then(
            (result) => {
                this.setState({ user_list: result });
            },
            (error) => {
                this.setState({ error });
            })
    }


    getMenueList = () => {
        const request = new Request(process.env.REACT_APP_BASE_PATH + "menue", {
            method: "GET"
        });
        fetch(request).then(res => {
            res.json().then(obj => {
                this.setState({ menuItems: obj });
                return obj;
            });
        }).catch(err => {
            console.log("Error in Get menu", err);
        })

    };
    handler(children) {
        const { classes } = this.props
        const { state } = this
        return children.map((subOption) => {
            if (!subOption.children) {
                return (
                    <li><Link to={subOption.url} className="dez-page"> {subOption.name}</Link></li>
                )
            }
            return (
                <li>
                    <a href="#">
                        <Dropdown>
                            <Dropdown.Toggle  variant="success3" id="dropdown-basic">{subOption.name}</Dropdown.Toggle>
                            <Dropdown.Menu>
                                {this.handlersub(subOption.children)}
                            </Dropdown.Menu>
                        </Dropdown>
                    </a>
                </li>
            )
        })
    }
    handlersub(children) {
        const { classes } = this.props
        const { state } = this
        return children.map((subOption) => {
            if (!subOption.children) {
                return (
                    <Dropdown.Item><Link to={subOption.url} className="dez-page"> {subOption.name}</Link></Dropdown.Item>       
                )
            }
        })
    }



    componentDidMount() {

        // sidebar open/close

        var btn = document.querySelector('.navicon');
        var aaa = document.querySelector('.myNavbar ');

        function toggleFunc() {
            return aaa.classList.toggle("show");
        }


        btn.addEventListener('click', toggleFunc);


        // Sidenav li open close
        var navUl = [].slice.call(document.querySelectorAll('.navbar-nav > li'));
        for (var y = 0; y < navUl.length; y++) {
            navUl[y].addEventListener('click', function () { checkLi(this) });
        }

        function checkLi(current) {
            navUl.forEach(el => el.classList.remove('open'));
            current.classList.add('open');
        }
    }
    logoutClick(e) {
        e.preventDefault();
        localStorage.clear();
        document.location = "/";
    }

    render() {
        const { user_list } = this.state;
        var IsLogin = localStorage.getItem("user") ? true : false;
        var name = localStorage.getItem("name");
        return (
            <header className="site-header mo-left header header-2">
                <div className="top-bar">
                    <div className="container-fluid">
                        <div className="row d-flex justify-content-between">
                            <div className="dlab-topbar-left mobile_hide">
                                <ul>
                                    <li>
                                        <Link to={'/'} className="site-button-link"> Home </Link>
                                    </li>
                                    <li>
                                        <Link className="site-button-link" to={'/contact'}>Contact</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="dlab-topbar-right top-login">


                                {IsLogin ? (
                                    <ul>
                                        <li><Link to='/member/notification' className="dez-page"><i className="fa fa-bell"></i> Notification</Link></li>
                                        <li>
                                            <Dropdown>
                                                <Dropdown.Toggle  variant="success3" id="dropdown-basic">{name}</Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item><Link to='/member/profile' className="dez-page">Edit Profile </Link></Dropdown.Item>
                                                    <Dropdown.Item><Link to='/member/mybooking/ride' className="dez-page"> Booking Ride</Link></Dropdown.Item>
                                                    <Dropdown.Item><Link to='/member/mybooking/stay' className="dez-page"> Booking Stay</Link></Dropdown.Item>
                                                    <Dropdown.Item><Link to='/member/mybooking/guide' className="dez-page"> Booking Guide</Link></Dropdown.Item>
                                                    <Dropdown.Item><Link to='/member/mybooking/package' className="dez-page"> Booking Package</Link></Dropdown.Item>
                                                    <Dropdown.Item><Link to='/member/mybooking/pilgrim' className="dez-page"> Booking Pilgrim</Link></Dropdown.Item>
                                                    <Dropdown.Item><Link to='/member/infopages' className="dez-page"> Info Pages</Link></Dropdown.Item>
                                                    <Dropdown.Item><Link to={'/login'} className="dez-page" onClick={this.logoutClick}>Logout</Link></Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </li>
                                    </ul>
                                ) : (
                                    <ul>
                                        <li><Link to={'/login'} className="site-button-link">Login</Link></li>
                                        <li><Link to={'/register'} className="site-button-link">Register</Link></li>
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sticky-header navbar-expand-lg main-bar-wraper">
                    <div className="main-bar clearfix onepage">
                        <div className="container-fluid clearfix">
                            <div className="logo-header mostion">
                                <Link to={'./'} ><img src={require('./../../images/logo.png').default} alt="" /></Link>
                            </div>

                            <button className="navbar-toggler collapsed navicon justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>

                            <div className="header-nav navbar-collapse collapse navbar myNavbar justify-content-center" id="navbarNavDropdown">
                                <ul className="nav navbar-nav">
                                    <li><Link to='/'>Home </Link></li>
                                    {this.handler(this.state.menuItems)}
                                    <li><Link to="/helpline" className="dez-page"> Abzo partner</Link></li>


                                </ul>
                            </div>


                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default Header;