import React from 'react'
import ListBox2 from './../../ListBox2';

class HomePackageListing extends React.Component {
  constructor(props){
    super(props);
    this.state = {page:null};
    this.setState({list:[]});

    this.handlerlisting = this.handlerlisting.bind(this);
    this.RideSearching  = this.RideSearching.bind(this);

    this.RideSearching();
  }
  RideSearching() {
    var post_data  = new FormData();
    post_data.set("limit", "4");
    const request = new Request(process.env.REACT_APP_BASE_PATH+"PackageFilter" , {method: "POST", body: post_data});
    fetch(request).then(res => {
    res.json().then(json => {	
        this.setState({list: json});	            
      });
    }).catch(err => {
        this.setState({message: <div class="danger">{err.message}</div>});
        console.log("Error in Get menu", err.message);
    });
  };

  handlerlisting( ItemList ) {
    var picture = "";
    if(ItemList && ItemList.length > 0){
      return ItemList.map( (item) => {
        picture = item?.attraction_picture ? item?.attraction_picture?.split(",")?.[0] : "upload/default.png";
        picture = process.env.REACT_APP_FILE_PATH+picture;
        console.log('picture...', picture)
        var param = {
          picture: picture, 
          title: item.title,
          category: item.category,
          username: item.user_name,
          booked: item.booked,
          remaining: item.business_seats + item.folding_seats - item.booked,
          price: item.price,
          couple_price: item.couple_price,
          child_price: item.child_price,
          infant_price: item.infant_price,
          item_list: item.fromcity.split(",").slice(0,4),
          date: item.departure_date.split("T")[0],
          link: {pathname:"/Trip+Packages/"+item.category?.replaceAll(' ', '+')+"/"+4+"/"+item.subcategory_id, state: this.state.filter_data}
        }

        return (
          <ListBox2 param={param} key={item.id} />
        )
      })
    }
  }
  render() {
    return (
        <div class="row">
          { this.handlerlisting(this.state.list) }
        </div>
    );
  }
}

export default (HomePackageListing);
