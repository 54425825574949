import React, { Component } from 'react'
import GuideFilter from './GuideFilter';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Header from './../../../Layout/Header';
import Footer from './../../../Layout/Footer';
import ListBox1 from './../../ListBox1';

class GuideListing extends React.Component {
  constructor(props) {
    super(props);
    props.match.params.class = "col-lg-12 col-md-12 col-sm-12 col-xs-12";
    var search_filter = this.props.location.search_filter ? this.props.location.search_filter : {};//if data posted from home page filter
    this.state = { page: props.match.params.id, list: [], filter_data: [], search_filter: search_filter };
    this.handlerlisting = this.handlerlisting.bind(this);
    this.GuideSearching = this.GuideSearching.bind(this);
  }
  GuideSearching(post_data, pre_state) {
    this.setState({ filter_data: pre_state });
    console.log("tnsbay", this.state.filter_data);
    const request = new Request(process.env.REACT_APP_BASE_PATH + "GuideFilter", { method: "POST", body: post_data });
    fetch(request).then(res => {
      res.json().then(json => {
        this.setState({ list: json });
      });
    }).catch(err => {
      this.setState({ message: <div class="danger">{err.message}</div> });
      console.log("Error in Get menu", err.message);
    });
  };
  handlerlisting(ItemList) {
    const { classes } = this.props;
    const { state } = this;
    var picture = "";
    var booked_label = "";
    if (ItemList && ItemList.length > 0) {
      return ItemList.map((item) => {
        picture = item.picture ? item.picture.split(",")[0] : "upload/default.png";
        picture = process.env.REACT_APP_FILE_PATH + picture;
        booked_label = "";
        if (item.booked > 0) {
          booked_label = <span class='label label-danger'>Booked</span>;
        }

        var param = {
          picture: picture, 
          title: item.category,
          category: item.category,
          price: item.price,
          username: item.user_name,
          item_list: item.city.split(",").slice(0,4),
          ooked: item.booked > 0 ? "YES" : "NO",
          remaining: item.booked > 0 ? "NO" : "YES",
          type : "guide",
          rating: 3,
          link: {pathname:"/Guidedetail/"+item.category+"/"+item.subcategory_id+"/"+item.id, state: this.state.filter_data }
        }

        return (<ListBox1 param={param} key={item.id} />)

      })
    }
  }
  render() {
    return (
      <>
        <Header />
        <div className="listing-details-head">
          <div className="container">
            <div className="hotel-booking">
              <GuideFilter page="listing" params={this.props.match.params} GuideSearching={this.GuideSearching} search_filter={this.state.search_filter}></GuideFilter>          </div>
            <div className="hotel-booking">
              <div class="row">{this.handlerlisting(this.state.list)}</div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default (GuideListing);
