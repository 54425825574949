import React, { Component } from "react";
import StayFilter from "./StayFilter";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Header from './../../../Layout/Header';
import Footer from './../../../Layout/Footer';
import ListBox1 from './../../ListBox1';


class StayListing extends React.Component {
  constructor(props) {
    super(props);
    props.match.params.class = "col-lg-12 col-md-12 col-sm-12 col-xs-12";
    var search_filter = this.props.location.search_filter ? this.props.location.search_filter : {};//if data posted from home page filter
    this.state = { page: props.match.params.id, list: [], filter_data: [],search_filter:search_filter };
    this.handlerlisting = this.handlerlisting.bind(this);
    this.StaySearching = this.StaySearching.bind(this);
  }
  StaySearching(post_data, pre_state) {
    this.setState({ filter_data: pre_state });
    const request = new Request(
      process.env.REACT_APP_BASE_PATH + "StayFilter",
      { method: "POST", body: post_data }
    );
    fetch(request)
      .then((res) => {
        res.json().then((json) => {
          this.setState({ list: json });
        });
      })
      .catch((err) => {
        this.setState({ message: <div class="danger">{err.message}</div> });
        console.log("Error in Get menu", err.message);
      });
  }
  handlerlisting(ItemList) {
    const { classes } = this.props;
    const { state } = this;
    var price = 0;
    var picture = "";
    var facilities = "";
    if (ItemList && ItemList.length > 0) {
      return ItemList.map((item) => {
        picture = item.room_picture
          ? item.room_picture.split(",")[0]
          : "upload/default.png";
        picture = process.env.REACT_APP_FILE_PATH + picture;
        var remaining = item.rooms - item.booked;
        var param = {
          picture: picture,
          title: item.title,
          category: item.category,
          price: item.service_price + '/night',
          username: item.user_name,
          address: item.address,
          booked: item.booked,
          remaining: remaining,
          type : "stay",
          rating: 3,
          link: { pathname: "/Staydetail/" + item.category + "/" + item.subcategory_id + "/" + item.id, state: this.state.filter_data }
        }

        return (
          <ListBox1 param={param} key={item.id} />
        )
      });
    }
  }
  render() {
    return (
      <>
        <Header />
        <div className="listing-details-head">
          <div className="container">
            <div className="hotel-booking">
              <StayFilter
                page="listing"
                params={this.props.match.params}
                search_filter={this.state.search_filter}
                StaySearching={this.StaySearching}
              ></StayFilter>
            </div>
            <div className="hotel-booking">
              <div class="row">{this.handlerlisting(this.state.list)}</div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default StayListing;
