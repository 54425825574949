import React from 'react'
import ListBox1 from './../../ListBox1';

class HomeGuideListing extends React.Component {
  constructor(props){
    super(props);
    this.state = {page:null};
    this.setState({list:[]});

    this.handlerlisting = this.handlerlisting.bind(this);
    this.GuideSearching  = this.GuideSearching.bind(this);

    this.GuideSearching();
  }
  GuideSearching() {
    var post_data  = new FormData();
    post_data.set("limit", "4");
    const request = new Request(process.env.REACT_APP_BASE_PATH+"GuideFilter" , {method: "POST", body: post_data});
    fetch(request).then(res => {
    res.json().then(json => {	
        this.setState({list: json});	            
      });
    }).catch(err => {
        this.setState({message: <div class="danger">{err.message}</div>});
        console.log("Error in Get menu", err.message);
    });
  };

  handlerlisting( ItemList ) {
    var picture = "";
    if(ItemList && ItemList.length > 0){
      return ItemList.map( (item) => {
        picture = item.picture  ? item.picture.split(",")[0] : "upload/default.png";
        picture = process.env.REACT_APP_FILE_PATH+picture;
        console.log('item.category', item.category);
        var param = {
          picture: picture, 
          title: item.category,
          category: item.category,
          price: item.price,
          username: item.user_name,
          item_list: item.city.split(",").slice(0,4),
          booked: item.booked > 0 ? "YES" : "NO",
          remaining: item.booked > 0 ? "NO" : "YES",
          rating: 3,
          type : "guide",
          link: {pathname:"/Guide/"+item?.category?.replaceAll(' ', '+')+"/"+item.id+"/"+item.subcategory_id, state: this.state.filter_data }
        }

        return (<ListBox1 param={param} key={item.id} />)
      })
    }
  }
  render() {
    return (
        <div class="row">
          { this.handlerlisting(this.state.list) }
        </div>
    );
  }
}

export default (HomeGuideListing);
