import React, { Component } from 'react';


class CommingSoon extends React.Component {
  constructor(props){
    super(props);
    this.state = {photo_gallary:[]};
  }
 
  render() {
    
    return (
    <div class="panel panel-primary col-sm-10 ">
       <img src="/assets/img/comingsoon.jpg" style={{width:"100%"}} ></img>   
    </div>
    );
  }
}


export default (CommingSoon);
