import React from 'react'
import Autocomplete from 'react-google-autocomplete';
import Select from 'react-select';
import { Link } from "react-router-dom";
import { withGoogleMap, GoogleMap, DirectionsRenderer } from 'react-google-maps';



class RideFilter extends React.Component {
  constructor(props) {
    super(props);
    var myclass = "col-lg-4 col-md-4 col-sm-12 col-xs-12";
    if (this.props.page == "home") {
      //myclass = "col-lg-6 col-md-6 col-sm-12 col-xs-12";
    }
    var cat_id = this.props.params ? this.props.params.id : 0;
    var today = new Date();
    var date = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
    var rent_type_list = [{ value: 1, label: 'Per KM' }, { value: 2, label: 'Per Day' }, { value: 3, label: 'Per Seat' }];
    this.state = { city_id: 0, city_list: [], page: this.props.page, date: date, def_cat: cat_id, rent_type_list: rent_type_list, rent_type: 1, myclass: myclass, cat_list: [], distance: { text: "0 KM", value: null }, duration: { text: "0 Min", value: null }, from_lat: null, from_lng: null, to_lat: null, to_lng: null, from_city: '', from_country: '', to_city: '', to_country: '' };
    this.displayRoute = this.displayRoute.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }
  MapLoad() {
    this.google = window.google;
    this.directionsService = new this.google.maps.DirectionsService();
  }
  componentDidMount() {
    this.getList();
    this.getCityList();
    
    if (!window.google) {
      var s = document.createElement("script");
      s.type = "text/javascript";
      s.src = "https://maps.google.com/maps/api/js?libraries=places&key=" + process.env.REACT_APP_GOOGLE_KEY;
      var x = document.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
      s.addEventListener("load", e => {
        this.MapLoad();
      });
    } else {
      this.MapLoad();
    }
    //if data posted from home page filter
    if(this.props.search_filter && Object.keys(this.props.search_filter).length > 0){
      this.setState(this.props.search_filter);
      this.btnSubmit.click();
    }
  }

  componentDidUpdate(prevProps) {
    var cat_id = this.props.params ? this.props.params.id : 0;
    var pre_id = prevProps.params ? prevProps.params.id : 0;
    if (cat_id !== pre_id) {
      this.setState({ def_cat: cat_id });
      if (this.props.page == "listing") {
        //this.btnSubmit.click();
      }
    }
  }

  displayRoute() {
    if (this.state.from_lat != null && this.state.to_lat != null) {
      const origin = { lat: this.state.from_lat, lng: this.state.from_lng };
      const destination = { lat: this.state.to_lat, lng: this.state.to_lng };
      this.request = {
        origin: origin,
        destination: destination,
        travelMode: this.google.maps.TravelMode.DRIVING
      };

      this.directionsService.route(this.request, (directionsResult, status) => {
        if (status === this.google.maps.DirectionsStatus.OK) {
          var distance = directionsResult.routes[0].legs[0].distance;
          var duration = directionsResult.routes[0].legs[0].duration;
          this.setState({ distance: distance, duration: duration });
          distance.text = distance.value / 1000 + " KM";
        } else {
          console.log("Directions request failed due to " + status);
        }
      });
    }
  }


  getCityList = () => {
    const request = new Request(process.env.REACT_APP_BASE_PATH + "City?ride=1&allow_booking=1", { method: "GET" });
    fetch(request).then(res => {
      res.json().then(obj => {
        var listItems = [];
        Object.keys(obj).forEach(function (key) {
          listItems.push({ "value": obj[key].id, "label": obj[key].title });
        });
        this.setState({ city_list: listItems });
      });
    }).catch(err => {
      console.log("Error in Get menu", err);
    });
  };

  handleSearch = (e) => {
    e.preventDefault();
  }
  getList = () => {
    const request = new Request(process.env.REACT_APP_BASE_PATH + "ServiceSubCategory?ServiceCategoryId=1&status=true", { method: "GET" });
    fetch(request).then(res => {
      res.json().then(obj => {
        var listItems = [];
        listItems.push({ "value": "", "label": "All" });
        Object.keys(obj).forEach(function (key) {
          listItems.push({ "value": obj[key].id, "label": obj[key].title });
        });
        this.setState({ cat_list: listItems });
        try{
          document.getElementById("ride_from").setAttribute("autocomplete", "off");
          document.getElementById("ride_to").setAttribute("autocomplete", "off");
        } catch(e){}

        return obj;
      });
    }).catch(err => {
      console.log("Error in Get menu", err);
    });
  };
  handleSubmit(event) {
    event.preventDefault();
    this.setState({ form: event.target });
    var post_data = new FormData(event.target);
    console.log('submit is called.');
    //if (post_data.get("ride_from") != "" && post_data.get("ride_to") != "") {
      this.props.RideSearching(post_data, this.state);
    //}
  };


  render() {
    var button = <button type="submit" class="btn btn-primary btnsearch" ref={input => this.btnSubmit = input}><span class="fa fa-search"></span> Search </button>
    if (this.state.page == "home") {

      var search_filter = {
        to_lat: this.state.to_lat,
        from_lat: this.state.from_lat,
        to_lng: this.state.to_lng,
        from_lng: this.state.from_lng,
        from_city: this.state.from_city,
        from_country: this.state.from_country,
        to_city: this.state.to_city,
        to_country: this.state.to_country,
        duration: this.state.duration,
        distance: this.state.distance,
        rent_type: this.state.rent_type,
        city_id: this.state.city_id,
        def_cat: this.state.def_cat,
        address: this.state.address,
        city: this.state.city,
        country: this.state.country,
        data: this.state.date
      };
      button = <Link to={{pathname:'Ride/Car/1/'+this.state.def_cat,search_filter:search_filter}} ><button type="submit" class="btn btn-primary btnsearch" id="btnsearch"><span class="fa fa-search"></span> Search </button></Link>
    }
    return (
      <div class="form-horizontal">
        <form name="tktSrchFrom" onSubmit={this.handleSubmit} class="tktSrchFrom" id="tktSrchFrom" ref="form">
          <div class="row">
            <div class={this.state.myclass}>
              <label class="">Select City</label>
              <div id="label_1">
                <Select id="city_id" name="city_id"
                  value={this.state.city_list.filter(option => option.value == this.state.city_id)}
                  onChange={value => this.setState({ city_id: value.value })}
                  options={this.state.city_list} />
              </div>
            </div>
            <div class={this.state.myclass}>
              <label class="">Select Category</label>
              <div id="label_1">
                <Select id="category_id" name="category_id"
                  value={this.state.cat_list.filter(option => option.value == this.state.def_cat)}
                  onChange={value => this.setState({ def_cat: value.value })}
                  options={this.state.cat_list} />
              </div>
            </div>

            <div class={this.state.myclass}>
              <label class="">Rent Type</label>
              <div id="label_1">
                <Select id="rent_type" name="rent_type"
                  value={this.state.rent_type_list.filter(option => option.value == this.state.rent_type)}
                  onChange={value => this.setState({ rent_type: value.value })}
                  options={this.state.rent_type_list} />
              </div>
            </div>

            <div class={this.state.myclass}>
              <label class="">Departure From</label>
              <div id="label_1">
                <Autocomplete
                  name="ride_from"
                  id="ride_from"
                  class="form-control address"
                  defaultValue={this.state.from_city}
                  onPlaceSelected={(place) => {
                    console.log(place);
                    this.setState({
                      ride_from: place.formatted_address,
                      from_lat: place.geometry.location.lat(),
                      from_lng: place.geometry.location.lng(),
                      from_city: place.address_components[0].long_name,
                      from_country: place.address_components[place.address_components.length - 1].long_name
                    });
                    this.displayRoute();
                  }}
                  required=""
                  types={['(regions)']}
                                /*componentRestrictions={{country: "pk"}}*/ />
                <input type="hidden" id="from_lat" name="from_lat" value={this.state.from_lat} ></input>
                <input type="hidden" id="from_lng" name="from_lng" value={this.state.from_lng} ></input>
                <input type="hidden" id="from_city" name="from_city" value={this.state.from_city} ></input>
                <input type="hidden" id="from_country" name="from_country" value={this.state.from_country} ></input>
              </div>
            </div>
            <div class={this.state.myclass}>
              <label class="">Arrival To</label>
              <div id="label_2">
                <Autocomplete
                  name="ride_to"
                  id="ride_to"
                  class="form-control address"
                  defaultValue={this.state.to_city}
                  onPlaceSelected={(place) => {
                    this.setState({
                      ride_to: place.formatted_address,
                      to_lat: place.geometry.location.lat(),
                      to_lng: place.geometry.location.lng(),
                      to_city: place.address_components[0].long_name,
                      to_country: place.address_components[place.address_components.length - 1].long_name
                    });
                    this.displayRoute();
                  }}
                  types={['(regions)']}
                  required=""
                                /*componentRestrictions={{country: "pk"}}*/ />
                <input type="hidden" id="to_lat" name="to_lat" value={this.state.to_lat} ></input>
                <input type="hidden" id="to_lng" name="to_lng" value={this.state.to_lng} ></input>
                <input type="hidden" id="to_city" name="to_city" value={this.state.to_city} ></input>
                <input type="hidden" id="to_country" name="to_country" value={this.state.to_country} ></input>
              </div>
            </div>
            <div class={this.state.myclass}>
              <label class=""></label>
              <div class="input-group">
                <span class="input-group-text">{this.state.distance.text}</span>
                <span class="input-group-text">{this.state.duration.text}</span>
              </div>

              <input type="hidden" id="distance" name="distance" value={this.state.distance.value} ></input>
              <input type="hidden" id="duration" name="duration" value={this.state.duration.value} ></input>
            </div>

            <div class={this.state.myclass}>
              <label class="">Date</label>
              <div class="input-group">
                <input type="date" id="date" class="form-control" name="date" defaultValue={this.state.date} onChange={value => this.setState({ date: value.value })} ></input>
              </div>
            </div>
            <div class={this.state.myclass}>
              <label class=""></label>
              <div class="input-group">
              {button}
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default (RideFilter);
