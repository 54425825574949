import React, { Component } from 'react'
import Autocomplete from 'react-google-autocomplete';
import Select from 'react-select';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { Link } from "react-router-dom";
import 'react-google-places-autocomplete/dist/assets/index.css';

class HelplineFilter extends React.Component {
  constructor(props) {
    super(props);
    var myclass = "col-lg-6 col-md-6 col-sm-12 col-xs-12";
    if (this.props.page == "home") {
      //myclass = "col-lg-6 col-md-6 col-sm-12 col-xs-12";
    }
    var cat_id = this.props.params ? this.props.params.id : 0;
    var catid = this.props.params ? this.props.params.catid : 4;
    this.state = { catid: catid, page: this.props.page, from_date: null, to_date: null, cat_list: [], country: '', def_cat: cat_id, country: localStorage.getItem("country"), city: localStorage.getItem("city"), address: localStorage.getItem("address"), myclass: myclass };
    this.getList();
    this.handleSubmit = this.handleSubmit.bind(this);
    var post_data = new FormData();
    this.props.HelplineSearching(0);
  }
  getList = () => {
    const request = new Request(process.env.REACT_APP_BASE_PATH + "City?status=true", { method: "GET" });
    fetch(request).then(res => {
      res.json().then(obj => {
        var listItems = [];
        listItems.push({ "value": "", "label": "All" });
        Object.keys(obj).forEach(function (key) {
          listItems.push({ "value": obj[key].id, "label": obj[key].title });
        });
        this.setState({ cat_list: listItems });
        return obj;
      });
    }).catch(err => {
      console.log("Error in Get menu", err);
    });
  };
  handleSubmit(event) {
    event.preventDefault();
    this.props.HelplineSearching(this.state.def_cat);
  };

  render() {
    var button = <button type="submit" class="btn btn-primary btnsearch" ref={input => this.btnSubmit = input}><span class="fa fa-search"></span> Search </button>
    return (
      <form name="tktSrchFrom" onSubmit={this.handleSubmit} class="tktSrchFrom row" method="post" id="tktSrchFrom">
        <div class={this.state.myclass}>
          <label class="">Select City</label>
          <Select id="city" name="city"
            class="form-control"
            value={this.state.cat_list.filter(option => option.value == this.state.def_cat)}
            onChange={value => this.setState({ def_cat: value.value })}
            options={this.state.cat_list} />
        </div>
        <div class={this.state.myclass}>
          <label class=""></label>
          {button}
        </div>
      </form>
    );
  }
}

export default (HelplineFilter);
