import React, { Component } from 'react'
import PackageFilter from './PackageFilter';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Header from './../../../Layout/Header';
import Footer from './../../../Layout/Footer';
import ListBox2 from './../../ListBox2';


class PackageListing extends React.Component {
  constructor(props) {
    super(props);
    props.match.params.class = "col-lg-12 col-md-12 col-sm-12 col-xs-12";
    var search_filter = this.props.location.search_filter ? this.props.location.search_filter : {};//if data posted from home page filter

    this.state = { page: props.match.params.id, list: [], filter_data: [], search_filter: search_filter };

    this.handlerlisting = this.handlerlisting.bind(this);
    this.PackageSearching = this.PackageSearching.bind(this);
  }
  PackageSearching(post_data, pre_state) {
    this.setState({ filter_data: pre_state });
    const request = new Request(process.env.REACT_APP_BASE_PATH + "PackageFilter", { method: "POST", body: post_data });
    fetch(request).then(res => {
      res.json().then(json => {
        this.setState({ list: json });
      });
    }).catch(err => {
      this.setState({ message: <div class="danger">{err.message}</div> });
      console.log("Error in Get menu", err.message);
    });
  };
  handlerlisting(ItemList) {
    var picture = "";
    if (ItemList && ItemList.length > 0) {
      return ItemList.map((item) => {
        picture = item?.attraction_picture ? item?.attraction_picture?.split(",")?.[0] : "upload/default.png";
        picture = process.env.REACT_APP_FILE_PATH+picture;
        var param = {
          picture: picture, 
          title: item.title,
          category: item.category,
          username: item.user_name,
          booked: item.booked,
          remaining: item.business_seats + item.folding_seats - item.booked,
          price: item.price,
          couple_price: item.couple_price,
          child_price: item.child_price,
          infant_price: item.infant_price,
          item_list: item.fromcity.split(",").slice(0,4),
          date: item.departure_date.split("T")[0],
          link: {pathname:"/Packagedetail/"+item.category+"/"+item.subcategory_id+"/"+item.id, state: this.state.filter_data}
        }

        return (
          <ListBox2 param={param} key={item.id}/>
        )
      })
    }
  }
  render() {
    return (

      <>
        <Header />
        <div className="listing-details-head">
          <div className="container">
            <div className="hotel-booking">
              <PackageFilter page="listing" params={this.props.match.params} PackageSearching={this.PackageSearching} search_filter={this.state.search_filter}></PackageFilter>
            </div>
            <div className="hotel-booking">
              <div class="row">{this.handlerlisting(this.state.list)}</div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default (PackageListing);
