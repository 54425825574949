// import Slider from "react-slick";
import { Link } from 'react-router-dom';
import Slick3 from './Slick3';
import axios from "axios";

import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";

import { login } from "../redux/actions/auth";

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};


const Login = (props) => {
    const form = useRef();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const { isLoggedIn } = useSelector(state => state.auth);
    const { message } = useSelector(state => state.message);

    const dispatch = useDispatch();


    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const handleLogin = (e) => {
        e.preventDefault();

        setLoading(true);

        form.current.validateAll();

        dispatch(login(username, password, ""))
            .then(() => {
                props.history.push("/member/profile");
                //window.location.reload();
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };
    const responseFacebook = (response) => {
        var facebook = response.id;
        var email = response.email;
        setLoading(true);
        dispatch(login(email, "", facebook))
        .then(() => {
            props.history.push("/member/profile");
            //window.location.reload();
            setLoading(false);
        })
        .catch(() => {
            setLoading(false);
        });
    }
    if (isLoggedIn) {
        props.history.push("/member/profile");
        //return <Redirect to="/member/profile" />;
    }


    return (
        <div>
            <div class="page-content dlab-login font-roboto">
                <div class="container-fluid p-lr0">
                    <div class="row m-lr0 login-form-box">
                        <div class="col-lg-4 p-lr0">
                            <div class="login-form">
                                <div class="logo logo-header">
                                    <Link to={'./'}><img src={require('./../../images/logo-2.png')} alt="" /></Link>
                                </div>
                                <div id="login" class="tab-pane">
                                <FacebookLogin
                                    appId= {process.env.REACT_APP_FACEBOOK_ID}
                                    autoLoad={false}
                                    fields="name,email,picture"
                                    callback={responseFacebook}
                                    textButton=" Login with Facebook"
                                    cssClass="my-facebook-button-class"
                                    icon="fa-facebook"
                                />
                                    <Form class="dlab-form" onSubmit={handleLogin} ref={form}>
                                        <div class="form-head">
                                            <h3 class="form-title m-t0">We Are <span class="text-primary">AbzoTrip</span></h3>
                                            <p class="title-text">Welcome back, Abzo partner account can also be used here.</p>
                                        </div>
                                        <div class="form-group-bx">
                                            <div class="form-group input-form">
                                                <label>Email Address</label>
                                                <Input name="dzName" className="form-control" placeholder="info123@example.com" type="text"
                                                    value={username}
                                                    onChange={onChangeUsername}
                                                    validations={[required]} />
                                            </div>
                                            <div class="form-group input-form">
                                                <label>Password</label>
                                                <Input name="dzName" required="" className="form-control " placeholder="Type Password" type="password"
                                                    value={password}
                                                    onChange={onChangePassword}
                                                    validations={[required]} />
                                            </div>
                                        </div>
                                        <div class="form-group field-btn text-left">
                                            <Link to={'./forgot'} class="btn-link forgot-password"> Forgot Password</Link>
                                        </div>
                                        <div class="form-group">
                                            <button class="site-button black m-r10" disabled={loading}>
                                                {loading && (
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                )}
                                                <span>Login</span>
                                            </button>
                                            <Link to={'./register'} class="site-button outline">Sign Up</Link>
                                            {message && (
                                                <div className="form-group">
                                                    <div className="alert alert-danger" role="alert">
                                                        {message}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </Form>
                                </div>


                            </div>
                        </div>
                        <div class="col-lg-8 p-lr0">
                            <Slick3 />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Login;