import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ListBox1 extends Component {
    constructor(props) {
        super(props);
        this.state = props.param;
    }
    render() {
        var description = this.state.description;
        if(this.state.type == "ride"){
            description = <div><b>Booked:</b> {this.state.booked} <br /> <b>Available:</b> {this.state.remaining} </div>;
        } else if(this.state.type == "stay"){
            description = <div><b>Booked:</b> {this.state.booked} <br /> <b>Available :</b> {this.state.remaining} <br /> <b>Address:</b> {this.state.address} </div>;
        }  else if(this.state.type == "guide"){
            description = <div><b>Booked:</b> {this.state.booked} <br /> <b>Available:</b> {this.state.remaining} </div>;
        } 
                    
        var item_list_html = this.state.item_list ?  this.state.item_list.map((val) => {
            return <li><span>{val}</span></li>
        }) : "";

        return (
            <div className="col-lg-6">
                <div className="dlab-box packages-bx">

                    <div className="dlab-media">
                        <Link to={this.state.link}>
                            <img  class="thumnail" src={this.state.picture} alt={this.state.title} />
                            <span className="tag yellow">{this.state.category}</span>
                        </Link>
                    </div>
                    <div className="dlab-info">
                        <div className="head-title">
                            <Link to={this.state.link}><span className="time">{this.state.title}</span></Link>
                            <h4 className="dlab-title">{this.state.username}</h4>
                            <span className="location">{description}</span>
                            <ul className="icon-box-list list-col-4">{item_list_html}</ul>
                        </div>
                        <div className="package-content">
                            <div className="price-bx">
                                PKR. {this.state.price}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ListBox1;