import React, { Component } from 'react'
import Autocomplete from 'react-google-autocomplete';
import Select from 'react-select';
import { Button } from 'react-bootstrap';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { Link } from "react-router-dom";
import 'react-google-places-autocomplete/dist/assets/index.css';

class PackageFilter extends React.Component {
  constructor(props) {
    super(props);
    var myclass = "col-lg-4 col-md-4 col-sm-12 col-xs-12";
    if (this.props.page == "home") {
      //myclass = "col-lg-6 col-md-6 col-sm-12 col-xs-12";
    }
    var today = new Date();
    var date = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);

    var cat_id = this.props.params ? this.props.params.id : 0;
    var catid = this.props.params ? this.props.params.catid : 5;
    this.state = {
      catid: catid, page: this.props.page, from_date: date, to_date: date, cat_list: [], city_id: 0, city_list: [], country: '', def_cat: cat_id,
      from_country: localStorage.getItem("country"),
      from_city: localStorage.getItem("city"),
      from_address: localStorage.getItem("address"),
      myclass: myclass
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount(){
    this.getList();
    this.getCityList();
    //if data posted from home page filter
    if(this.props.search_filter){
      this.setState(this.props.search_filter);
      setTimeout(() => {
        this.btnSubmit.click();
      }, 2000);
      
    }
  }
  componentDidUpdate(prevProps) {
    var cat_id = this.props.params ? this.props.params.id : 0;
    var pre_id = prevProps.params ? prevProps.params.id : 0;
    if (cat_id !== pre_id) {
      this.setState({ def_cat: cat_id });
      if (this.props.page == "listing") {
        this.btnSubmit.click();
      }
    }
  }
  getCityList = () => {
    const request = new Request(process.env.REACT_APP_BASE_PATH + "City?stay=1&allow_booking=1", { method: "GET" });
    fetch(request).then(res => {
      res.json().then(obj => {
        var listItems = [];
        Object.keys(obj).forEach(function (key) {
          listItems.push({ "value": obj[key].id, "label": obj[key].title });
        });
        this.setState({ city_list: listItems });
      });
    }).catch(err => {
      console.log("Error in Get menu", err);
    });
  };
  getList = () => {
    const request = new Request(process.env.REACT_APP_BASE_PATH + "ServiceSubCategory?ServiceCategoryId=" + this.state.catid + "&status=true", { method: "GET" });
    fetch(request).then(res => {
      res.json().then(obj => {
        var listItems = [];
        listItems.push({ "value": "", "label": "All" });
        Object.keys(obj).forEach(function (key) {
          listItems.push({ "value": obj[key].id, "label": obj[key].title });
        });
        this.setState({ cat_list: listItems });
        return obj;
      });
    }).catch(err => {
      console.log("Error in Get menu", err);
    });
  };
  handleSubmit(event) {
    event.preventDefault();
    var post_data = new FormData(event.target);
    this.setState({ from_date: post_data.get("from_date"), to_date: post_data.get("from_date") })
    this.state.from_date = post_data.get("from_date");
    this.state.to_date = post_data.get("from_date");
    this.props.PackageSearching(post_data, this.state);
  };

  render() {
    var button = <button type="submit" class="btn btn-primary btnsearch"  style={{ "margin-right": "10px" }} ref={input => this.btnSubmit = input}><span class="fa fa-search"></span> Search </button>
    if (this.state.page == "home") {
      var search_filter = {
        city_id: this.state.city_id,
        def_cat: this.state.def_cat,
        from_address: this.state.from_address,
        from_city: this.state.from_city,
        from_country: this.state.from_country
      };
      button = <Link to={{pathname:'Package/any/5/'+this.state.def_cat,search_filter:search_filter}} ><button type="submit" class="btn btn-primary btnsearch" id="btnsearch"><span class="fa fa-search"></span> Search </button></Link>
    }
    return (
      <div class="form-horizontal">
        <form name="tktSrchFrom" onSubmit={this.handleSubmit} class="tktSrchFrom" method="post" id="tktSrchFrom">
          <input type="hidden" id="package" name="package" value="pilgrim" ></input>
          <div class="row">
            <div class={this.state.myclass}>
              <label class="">Select City</label>
              <div id="label_1">
                <Select id="city_id" name="city_id"
                  value={this.state.city_list.filter(option => option.value == this.state.city_id)}
                  onChange={value => this.setState({ city_id: value.value })}
                  options={this.state.city_list} />
              </div>
            </div>

            <div class={this.state.myclass}>
              <label class="">Select Category</label>
              <div id="label_1">
                <Select id="category_id" name="category_id"
                  value={this.state.cat_list.filter(option => option.value == this.state.def_cat)}
                  onChange={value => this.setState({ def_cat: value.value })}
                  options={this.state.cat_list} />
              </div>
            </div>
            <div class={this.state.myclass}>
              <label class="">Departure City</label>
              <div id="label_1">
                <GooglePlacesAutocomplete
                  class="form-control"
                  initialValue={this.state.from_address}
                  onSelect={(place) => (
                    this.setState({
                      from_address: place.description,
                      from_city: place.terms[place.terms.length - 2].value,
                      from_country: place.terms[place.terms.length - 1].value
                    })
                  )} />
                <input type="hidden" id="from_city" name="from_city" value={this.state.from_city} ></input>
                <input type="hidden" id="from_country" name="from_country" value={this.state.from_country} ></input>
              </div>
            </div>

            <div class={this.state.myclass}>
              <label class=""></label>
              <div class="input-group">
                {button}
                <Link to="/custompackage" class="right"><Button variant="success" style={{ "margin-left": "10px" }}><span class="fa fa-plus"></span> CUSTOMIZED PACKAGE</Button></Link>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default (PackageFilter);
