import React, { Component } from "react";
import Select from 'react-select';
import {Link,Redirect  } from "react-router-dom";

export default class Complain extends Component {
    state = {value: 0};
    constructor(props){
        super(props);
        var id = this.props.match.params ? this.props.match.params.id : 0;
        this.state = {rating:0,message: "", id:id,cat_list:[]};
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getList();
    };
    getList = () => {
        const request = new Request(process.env.REACT_APP_BASE_PATH+"ComplainCategory?status=true", {	method: "GET"});
        fetch(request).then(res => {
          res.json().then(obj => {		
            var listItems = [];
            Object.keys(obj).forEach(function(key) {
              listItems.push({"value":obj[key].id, "label":obj[key].title});
            });
            this.setState({ cat_list: listItems });
          });
        }).catch(err => {
            console.log("Error in Get menu", err);
        });
    };
    handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.target);
        data.append("user_by", localStorage.getItem("id"));
        data.append("user_to", this.state.id);
        this.SubmitData(data);

    };
    SubmitData = (data) => {
        const request = new Request(process.env.REACT_APP_BASE_PATH+"complain", {method: "POST", body: data});
        fetch(request).then(res => {
        res.json().then(json => {	
                if(json.error){
                    this.setState({message: <div class="danger">{json.message}</div>});
                } else {
                    this.setState({message: <div class="success">congratulation! successfully posted</div>});
                }			            
            });
        }).catch(err => {
            this.setState({message: <div class="danger">{err.message}</div>});
        });
    }
    
    render() {
        const { classes, drawerOpen, menuOptions } = this.props
        if(this.state.message != ""){
            return (
                <div class="myform1 col-lg-8 col-md-12 col-sm-12">
                    <h3>Complain</h3>
                    {this.state.message}
                </div>
            )
        } else {
            return (
                <form id="myform" onSubmit={this.handleSubmit} >
                    <div class="myform1 col-lg-8 col-md-12 col-sm-12">
                        <h3>Complain</h3>
                        {this.state.message}
                        <div className="form-group">
                            <label>Select Category</label>
                            <Select id="category_id" name="category_id" options={this.state.cat_list}  required
                            onChange={this.handleChangeCategory} />
                        </div>
                        <div className="form-group">
                            <label>Comments</label>
                            <textarea id="comments" className="form-control" name="comments" data="" />
                        </div>
                        <button type="submit" className="btn btn-primary btn-block">Submit</button>
                    </div>
                </form>
            );
        }
    }
}