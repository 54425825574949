import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ListBox2 extends Component {
    constructor(props) {
        super(props);
        this.state = props.param;
    }
    render() {
        var seat_info = this.state.package == "pilgrim" ? "" :
                        <>
                            <b>Couple Price:</b> {this.state.couple_price}<br />
                            <b>Booked:</b> {this.state.booked}<br /> 
                            <b>Remaining Seats:</b> {this.state.remaining}<br />
                        </> ;
        var description = <div>
            <b>Adult Price:</b> {this.state.price} <br /> 
            <b>Child Price:</b> {this.state.child_price}<br /> 
            <b>Infant Price:</b> {this.state.infant_price}<br /> 
            
            {seat_info}
            <b>Departure Date:</b> {this.state.date}<br /> 
        </div>;
        var item_list_html = this.state.item_list ?  this.state.item_list.map((val) => {
            return <li><span>{val}</span></li>
        }) : "";
        return (
            <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="featured-bx style2">
                    <div className="featured-media">
                        <img src={this.state.picture} alt="" />
                        <div className="featured-tag">{this.state.category}</div>
                    </div>
                    <div className="featured-content text-white">
                        <div>
                            <h2 className="title"><Link to={'packages'}>{this.state.title}</Link></h2>
                            <p>{description}</p>
                            <ul className="icon-box-list list-col-4">{item_list_html}</ul>
                            <Link to={this.state.link} className="site-button outline white">View Detail</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ListBox2;