import React, { Component } from 'react'
import Autocomplete from 'react-google-autocomplete';
import { Link, Redirect } from "react-router-dom";
import { Button, Card } from 'react-bootstrap';
import Carousel from "react-bootstrap/Carousel";
import Select from 'react-select';
import YouTube from 'react-youtube';
import Header from './../../Layout/Header';
import Footer from './../../Layout/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';


class InfoPageListing extends React.Component {
  constructor(props) {
    super(props);
    var myclass = "col-lg-4 col-md-4 col-sm-12 col-xs-12 form-group";
    console.log("param", this.props.match.params.id);
    var cat_id = this.props.match ? this.props.match.params.id : 0;
    var show_to_city = cat_id == 1 ? true : false;
    var location_title = cat_id == 1 ? "Departure From" : "Location";
    this.state = { subcat_list: [], filter_data: [], location_title: location_title, data_list: [], subcategory_id: 0, cat_id: cat_id, info: {}, myclass: myclass, show_to_city: show_to_city };
    this.handlerlisting = this.handlerlisting.bind(this);
    this.InfoPageSearching = this.InfoPageSearching.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlercoursal = this.handlercoursal.bind(this);
    this.handleChangeSubCategory = this.handleChangeSubCategory.bind(this);
    this.getInfo(cat_id);
    this.getSubList(cat_id);
  }
  componentDidUpdate(prevProps) {
    var id = this.props.match.params ? this.props.match.params.id : 0;
    var pre_id = prevProps.match.params ? prevProps.match.params.id : 0;
    if (id !== pre_id) {
      var show_to_city = id == 1 ? true : false;
      var location_title = id == 1 ? "Departure From" : "Location";
      this.setState({ cat_id: id, show_to_city: show_to_city, location_title: location_title });
      this.getInfo(id);
      this.getSubList(id);
    }
  }

  getInfo = (id) => {
    const request = new Request(process.env.REACT_APP_BASE_PATH + "InfoCategory/" + id, { method: "GET" });
    fetch(request).then(res => {
      res.json().then(obj => {
        this.setState({ info: obj });
        return obj;
      });
    }).catch(err => {
      console.log("Error in Get menu", err);
    });
  };
  handleChangeSubCategory = selected => {
    console.log(selected);
    this.setState({ 'subcategory_id': selected.value });
  };
  getSubList = (cat_id) => {
    const request = new Request(process.env.REACT_APP_BASE_PATH + "InfoSubCategory?status=true&category_id=" + cat_id, { method: "GET" });
    fetch(request).then(res => {
      res.json().then(obj => {
        var listItems = [];
        Object.keys(obj).forEach(function (key) {
          listItems.push({ "value": obj[key].id, "label": obj[key].title });
        });
        this.setState({ subcat_list: listItems });
      });
    }).catch(err => {
      console.log("Error in Get menu", err);
    });
  };
  handleSubmit(event) {
    event.preventDefault();
    this.InfoPageSearching({ category_id: this.state.cat_id, subcategory_id: this.state.subcategory_id, to_city: this.state.to_city, from_city: this.state.from_city });
  };
  InfoPageSearching(param) {
    console.log(param);
    var where = "?category_id=" + param.category_id;
    if (param.to_city) {
      where += "&to_city=" + param.to_city;
    }
    if (param.from_city) {
      where += "&from_city=" + param.from_city;
    }
    if (param.subcategory_id) {
      where += "&subcategory_id=" + param.subcategory_id;
    }
    const request = new Request(process.env.REACT_APP_BASE_PATH + "InfoPages" + where, { method: "GET" });
    fetch(request).then(res => {
      res.json().then(json => {
        this.setState({ data_list: json });
      });
    }).catch(err => {
      this.setState({ message: <div class="danger">{err.message}</div> });
      console.log("Error in Get menu", err.message);
    });
  };
  handlercoursal(ItemList) {

    if (ItemList && ItemList.length > 0) {
      return ItemList.map((item) => {
        console.log("picture array", item);
        item = process.env.REACT_APP_BASE_PATH + item;
        return (
          <Carousel.Item>
            <img
              className="d-block"
              src={item}
              alt='NO IMAGE'
            />

          </Carousel.Item>
        )
      })
    }
  }
  handlerlisting() {
    const { classes } = this.props;
    const { state } = this;
    if (this.state) {
      var ItemList = this.state.data_list;

      if (ItemList && ItemList.length > 0) {
        return ItemList.map((item) => {
          var picture_array = item.picture ? item.picture.split(',') : [];
          var video_link = "";
          if (item.video != "") {
            var video_id = item.video.split('v=')[1];
            var ampersandPosition = video_id.indexOf('&');
            if (ampersandPosition != -1) {
              video_id = video_id.substring(0, ampersandPosition);
            }
            video_link = <YouTube videoId={video_id} onReady={this._onReady} />
          }
          return (
            <Card style={{ 'marginTop': '10px' }}>
              <Card.Header>{item.title} Posted By {item.user_id.name}</Card.Header>
              <Card.Body>
                <Card.Title>
                  <Carousel>{this.handlercoursal(picture_array)} </Carousel>
                </Card.Title>
                <Card.Text>
                  <div class="box_text" dangerouslySetInnerHTML={{ __html: item.description }}></div>
                </Card.Text>
                {video_link}
              </Card.Body>
            </Card>
          )
        })
      }
    }
  }
  render() {
    var to_city = "";
    if (this.state.show_to_city) {
      to_city = <div class={this.state.myclass}>
        <label class="">Arrival To</label>
        <Autocomplete
          name="ride_to"
          id="ride_to"
          class="input-group address"
          onPlaceSelected={(place) => {
            this.setState({
              to_city: place.address_components[0].long_name
            });
          }}
          types={['(regions)']}
          required=""
                            /*componentRestrictions={{country: "pk"}}*/ />
        <input type="hidden" id="to_city" name="to_city" value={this.state.to_city} ></input>
      </div>
    }

    //user id login 
    var add_button = "";
    if (localStorage.getItem("id") > 0) {
      add_button = <Link to="/member/infopageadd" class="right"><Button variant="success"><span class="fa fa-plus"></span>Add</Button></Link>
    }

    var bg3 =  require('./../../../images/banner/bnr1.jpg').default;
    return (
      <>
        <Header />
        <div className="listing-details-head">
          <div className="container">
            <div className="dlab-bnr-inr overlay-black-middle" style={{ backgroundImage: "url(" + bg3 + ")", backgroundSize: 'cover' }}>
              <div className="container">
                <div className="dlab-bnr-inr-entry">
                  <h1 className="text-white">{this.state.info.title}</h1>
                  <div className="content-body" dangerouslySetInnerHTML={{ __html: this.state.info.description }}></div>
                </div>
              </div>
            </div>
            <div className="hotel-booking">
              <div class="form-horizontal">
                <form name="tktSrchFrom" onSubmit={this.handleSubmit} class="tktSrchFrom row" method="post" id="tktSrchFrom">

                  <div class={this.state.myclass}>
                    <label>Sub Category</label>
                    <Select id="subcategory_id" name="subcategory_id" options={this.state.subcat_list} required
                      onChange={this.handleChangeSubCategory} />
                  </div>

                  <div class={this.state.myclass}>
                    <label class="">{this.state.location_title}</label>
                    <Autocomplete
                      name="ride_from"
                      id="ride_from"
                      class="input-group address"
                      onPlaceSelected={(place) => {
                        this.setState({
                          from_city: place.address_components[0].long_name
                        });
                      }}
                      required=""
                      types={['(regions)']}
                                /*componentRestrictions={{country: "pk"}}*/ />
                    <input type="hidden" id="from_city" name="from_city" value={this.state.from_city} ></input>
                  </div>
                  {to_city}

                  <div class={this.state.myclass}>
                    <label></label>
                    <div class="input-group">
                      <button type="submit" class="btn btn-primary btnsearch" style={{ "margin-right": "10px" }} ref={input => this.btnSubmit = input}><span class="fa fa-search"></span> Search </button>
                      {add_button}
                    </div>
                  </div>
                </form>

              </div>
              <div className="hotel-booking">
                <h5>{this.state.info.title}</h5>
                <div class="row"> {this.handlerlisting()}</div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default (InfoPageListing);
