import React from 'react'
import ListBox1 from './../../ListBox1';


class HomeStayListing extends React.Component {
  constructor(props){
    super(props);
    this.state = {page:null};
    this.setState({list:[]});

    this.handlerlisting = this.handlerlisting.bind(this);
    this.StaySearching  = this.StaySearching.bind(this);

    this.StaySearching();
  }
  StaySearching() {
    var post_data  = new FormData();
    post_data.set("limit", "4");
    const request = new Request(process.env.REACT_APP_BASE_PATH+"StayFilter" , {method: "POST", body: post_data});
    fetch(request).then(res => {
    res.json().then(json => {	
        this.setState({list: json});	            
      });
    }).catch(err => {
        this.setState({message: <div class="danger">{err.message}</div>});
        console.log("Error in Get menu", err.message);
    });
  };

  handlerlisting( ItemList ) {
    var picture = "";
    if(ItemList && ItemList.length > 0){
      return ItemList.map( (item) => {
        picture = item.room_picture ? item.room_picture.split(",")[0] : "upload/default.png";
        picture = process.env.REACT_APP_FILE_PATH+picture;
        var remaining = item.rooms - item.booked;
        var param = {
          picture: picture, 
          title: item.title,
          category: item.category,
          price: item.service_price +'/night',
          username: item.user_name,
          address: item.address,
          booked: item.booked,
          remaining: remaining,
          type : "stay",
          rating: 3,
          link: {pathname:"/Stay/"+item.category?.replaceAll(' ', '+')+"/"+2+'/'+item.subcategory_id, state: this.state.filter_data }
        }

        return (
          <ListBox1 param={param} key={item.id} />
        )
      })
    }
  }
  render() {
    return (
        <div class="row">
          { this.handlerlisting(this.state.list) }
        </div>
    );
  }
}

export default (HomeStayListing);
