import React from 'react';
import { Table, Button, Alert } from 'react-bootstrap';
import { Link } from "react-router-dom";
import UserPopUp from '../Common/UserPopUp';


class MyBookingList extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    var user_id = localStorage.getItem("id");
    var category_id = 1;
    if(props.params.category == "stay"){
      category_id = 2;
    } else if(props.params.category == "guide"){
      category_id = 3;
    } else if(props.params.category == "package"){
      category_id = 4;
    } else if(props.params.category == "pilgrim"){
      category_id = 5;
    }
    
    this.state = {
      error: null,
      user_id: user_id,
      category_id: category_id,
      products: [],
      response: {}
    }
    this.getInfo = this.getInfo.bind(this);
    this.UserInfo = this.UserInfo.bind(this);
    this.PaymentButton = this.PaymentButton.bind(this);
    this.getInfo();
  }

  componentDidUpdate(prevProps) {
    console.log("tnsbay my booking", this.props.params.category, prevProps.params.category);
    if (this.props.params.category !== prevProps.params.category) {
      var category_id = 1;
      if(this.props.params.category == "stay"){
        category_id = 2;
      } else if(this.props.params.category == "guide"){
        category_id = 3;
      } else if(this.props.params.category == "package"){
        category_id = 4;
      }
      this.setState({category_id: category_id});
      this.state.category_id = category_id;

      this.getInfo();
    }
  }
  getInfo = () => {
    const request = new Request(process.env.REACT_APP_BASE_PATH+"MyBooking?user_id="+this.state.user_id+"&category_id="+this.state.category_id, {method: "GET"});
    fetch(request)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({products: result});
        },
        (error) => {
          this.setState({ error });
        }
      )
  }

  deleteProduct(productId) {
    if(window.confirm("Are you sure you want to delete? ")){
      const request = new Request(process.env.REACT_APP_BASE_PATH+"Ride/"+productId , {method: "DELETE", body: {}});
      fetch(request).then(res => {
      res.json().then(json => {	
              this.componentDidMount();
        });
      }).catch(err => {
          this.setState({message: <div class="danger">{err.message}</div>});
      });
    }
  }
  UserInfo(product) {
    if(product.payment_status == "paid"){
      return <UserPopUp param={{id: product.user_id_by, name: product.user_name}}></UserPopUp>
    } else {
      return product.user_name
    }
  }
  PaymentButton(product) {
    var remaining = product.paid > 0 ? product.total - product.paid : Math.ceil(process.env.REACT_APP_PAID_PERCENT*product.total);
    var btn_payment = remaining > 0 ?  
    <Link class="right"  to={{pathname:"/Payment/"+product.id, amount: remaining}} > <button type="button" class="btn btn-success">PAY ({remaining})</button> </Link> : "";
    return btn_payment;
  }

  render() {
    const { error, products} = this.state;
    if(this.state.category_id == 1) {
      return(<div class="panel panel-primary">
      <div class="panel-body table-responsive">
        <div>
        <h2 class="m-b10">Ride Booking List</h2>
        {this.state.response.message && <Alert variant="info">{this.state.response.message}</Alert>}
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Title</th>
              <th>Category</th>
              <th>Method</th> 
              <th>Source - Destination</th>
              <th>Date</th>
              <th>User By</th> 
              <th>Total</th> 
              <th>Paid</th> 
              <th>Status</th>
              <th>Payment</th>
              <th>PAY</th>
              <th>Vendor Name</th> 
              <th>Vendor Mobile</th> 
              <th>Vendor Email</th> 
            </tr>
          </thead>
          <tbody>
            {products.map(product => (    
              <tr key={product.id}>
                <td>{product.id}</td>
                <td>{product.service}</td>
                <td>{product.category}</td>
                <td>{product.method}</td>
                <td>{product.dest_address} - {product.dest_address}</td>
                <td>{product.date_from}</td>
                <td>{this.UserInfo(product)}</td>
                <td>{product.total}</td>
                <td>{product.paid}</td>
                <td><span class="badge badge-info">{product.status}</span></td>
                <td><span class="badge badge-info">{product.payment_status}</span></td>
                <td>{this.PaymentButton(product)}</td>
                <td>{product.user_name}</td>
                <td>{product.user_mobile}</td>
                <td>{product.user_email}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
    </div>)
    } else if(this.state.category_id == 2) {
      return(<div class="panel panel-primary">
      <div class="panel-body table-responsive">
        <div>
        <h2 class="m-b10">Stay Booking List</h2>
        {this.state.response.message && <Alert variant="info">{this.state.response.message}</Alert>}
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Title</th>
              <th>Category</th>
              <th>Method</th> 
              <th>City</th>
              <th>Country</th>
              <th>Date</th>
              <th>User By</th> 
              <th>Total</th> 
              <th>Paid</th> 
              <th>Status</th>
              <th>Payment</th>
              <th>PAY</th>
              <th>Vendor Name</th> 
              <th>Vendor Mobile</th> 
              <th>Vendor Email</th> 
            </tr>
          </thead>
          <tbody>
            {products.map(product => (
              <tr key={product.id}>
                <td>{product.id}</td>
                <td>{product.service}</td>
                <td>{product.category}</td>
                <td>{product.method}</td>
                <td>{product.city}</td>
                <td>{product.country}</td>
                <td>{product.date_from}-{product.date_to}</td>
                <td>{this.UserInfo(product)}</td>
                <td>{product.total}</td>
                <td>{product.paid}</td>
                <td><span class="badge badge-info">{product.status}</span></td>
                <td><span class="badge badge-info">{product.payment_status}</span></td>
                <td>{this.PaymentButton(product)}</td>
                <td>{product.user_name}</td>
                <td>{product.user_mobile}</td>
                <td>{product.user_email}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
    </div>)
    } else if(this.state.category_id == 3) {
      return(
        <div class="panel panel-primary">
          <div class="panel-body table-responsive">
            <div>
            <h2 class="m-b10">Guide Booking List</h2>
            {this.state.response.message && <Alert variant="info">{this.state.response.message}</Alert>}
            <Table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Title</th>
                  <th>Category</th>
                  <th>Method</th> 
                  <th>City</th>
                  <th>Country</th>
                  <th>Date</th>
                  <th>User By</th> 
                  <th>Total</th> 
                  <th>Paid</th> 
                  <th>Status</th>
                  <th>Payment</th>
                  <th>PAY</th>
                  <th>Vendor Name</th> 
                  <th>Vendor Mobile</th> 
                  <th>Vendor Email</th> 
                </tr>
              </thead>
              <tbody>
                {products.map(product => (
                  <tr key={product.id}>
                    <td>{product.id}</td>
                    <td>{product.service}</td>
                    <td>{product.category}</td>
                    <td>{product.method}</td>
                    <td>{product.city}</td>
                    <td>{product.country}</td>
                    <td>{product.date_from}-{product.date_to}</td>
                    <td>{this.UserInfo(product)}</td>
                    <td>{product.total}</td>
                    <td>{product.paid}</td>
                    <td><span class="badge badge-info">{product.status}</span></td>
                    <td><span class="badge badge-info">{product.payment_status}</span></td>
                    <td>{this.PaymentButton(product)}</td>
                    <td>{product.user_name}</td>
                    <td>{product.user_mobile}</td>
                    <td>{product.user_email}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
        </div>
      )
    } else if(this.state.category_id == 4 || this.state.category_id == 5) {
      return(
        <div class="panel panel-primary">
          <div class="panel-body table-responsive">
            <div>
            <h2 class="m-b10">Package Booking List</h2>
            {this.state.response.message && <Alert variant="info">{this.state.response.message}</Alert>}
            <Table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Title</th>
                  <th>Category</th>
                  <th>Method</th> 
                  <th>City</th>
                  <th>Country</th>
                  <th>Date</th>
                  <th>User By</th> 
                  <th>Amount</th> 
                  <th>Paid</th> 
                  <th>Status</th>
                  <th>Payment</th>
                  <th>PAY</th>
                  <th>Vendor Name</th> 
                  <th>Vendor Mobile</th> 
                  <th>Vendor Email</th> 
                </tr>
              </thead>
              <tbody>
                {products.map(product => (
                  <tr key={product.id}>
                    <td>{product.id}</td>
                    <td>{product.service}</td>
                    <td>{product.category}</td>
                    <td>{product.method}</td>
                    <td>{product.city}</td>
                    <td>{product.country}</td>
                    <td>{product.date_from}-{product.date_to}</td>
                    <td>{product.total}</td>
                    <td>{product.paid}</td>
                    <td>Admin</td>
                    <td><span class="badge badge-info">{product.status}</span></td>
                    <td><span class="badge badge-info">{product.payment_status}</span></td>
                    <td>{this.PaymentButton(product)}</td>
                    <td>{product.user_name}</td>
                    <td>{product.user_mobile}</td>
                    <td>{product.user_email}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
        </div>
      )
    }
  }
}

export default MyBookingList;