import React from 'react';
import { Link } from "react-router-dom";
import Calendar from 'react-calendar-multiday';
import YouTube from 'react-youtube';
import Header from './../../../Layout/Header';
import Footer from './../../../Layout/Footer';
import { TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
var moment = require("moment");



class RideDetail extends React.Component {
  state = {value: 0};
  constructor(props){
    super(props);

    var today = new Date();
    var date  = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
    var date  = props.location.state ? props.location.state.date : date;
    var pre_state =  props.location.state;
    var id = this.props.match.params ? this.props.match.params.infoid : 0;
    var allow_book = props.location.state && parseInt(localStorage.getItem("id")) > 0 ? true : false; 
    var quantity = pre_state && pre_state.rent_type == 1 ? parseInt(pre_state.distance.value/1000) : 1;
    this.state = {activeTab: "1",service_title: 'TWO WAY',two_way:1, message:'',booked_days:{},date_array:[],id: id, info:{city_list:[], date_list:[]},pre_state:props.location.state, allow_book : allow_book, show_book:false, quantity: quantity, from_date: date,to_date:date};
    this.getInfo(id);
    this.AddView(id);
    this.TwowayChange = this.TwowayChange.bind(this);
    this.CheckBooking = this.CheckBooking.bind(this);
    this.SetInput = this.SetInput.bind(this);
    console.log("tnsbay pre", this.state.pre_state);
  }
  toggle = tab => {
    this.setState({activeTab: tab});
  }
  componentDidUpdate(prevProps) {
    var id = this.props.match.params ? this.props.match.params.infoid : 0;
    var pre_id = prevProps.match.params ? prevProps.match.params.infoid : 0;
    if (id !== pre_id) {
      this.getInfo(id);
    }
  }
  getInfo = (id) => {
		const request = new Request(process.env.REACT_APP_BASE_PATH+"RideInfo/"+id, {method: "GET"});
		fetch(request).then(res => {
					res.json().then(obj => {					            
            var date_array = [];
           obj.date_list.forEach(dateinfo => {  
              date_array.push((dateinfo["date"].split("T"))[0]);   
            })
            if(obj.rent_type == 2){
              this.setState({service_title: 'DAYS'});
            } else if(obj.rent_type == 3){
              this.setState({service_title: 'SEATS'});
            }

            this.setState({ info: obj, date_array: date_array, booked_days:JSON.stringify(obj.booked_days)});
						return obj;
					});
			}).catch(err => {
				console.log("Error in Get menu", err);
			});
  };
  AddView = (id) => {
		const request = new Request(process.env.REACT_APP_BASE_PATH+"AddView/1/"+id, {method: "GET"});
		fetch(request).then(res => {
					console.log(res);
			}).catch(err => {
				console.log("Error in Get menu", err);
			});
  };
  CheckBooking(evt){
    console.log("tnsbay clicked",this.state.info.rent_type, this.state.date_array,  this.state.booked_days, this.state.from_date, this.state.to_date);
    var date = "";
    var booked_days = JSON.parse(this.state.booked_days);
    var html_messsage = [];
    var show_book = true;
    var loop = this.state.info.rent_type == 3 ? this.state.quantity : 1; 
    for(var i = 0; i < loop; i++){
      date = moment(this.state.from_date,"YYYY-MM-DD").add(i, 'days').format("YYYY-MM-DD");
      if(!this.state.date_array.includes(date)){
        show_book = false;
        html_messsage.push("Date: "+date+" is not avaible");
      } else if (date in booked_days){
        if(this.state.info.rent_type == 3){ //per seats
          var booked_seats = 0;
          booked_days[date].map((val) => {
            booked_seats += parseInt(val);
          })
          console.log("booked seats",booked_seats,this.state.quantity,booked_seats+parseInt(this.state.quantity), this.state.info.seats);
          if((booked_seats+parseInt(this.state.quantity)) > parseInt(this.state.info.seats)){
            show_book = false;
            html_messsage.push("Date: "+date+" out of total "+this.state.info.seats + " already booked "+booked_seats+", So only "+(this.state.info.seats-booked_seats)+" seats are available");
          }
        } else {
          show_book = false;
          html_messsage.push("Date: "+date+" is already booked");
        }
      }
    }
    html_messsage = html_messsage.join(", ");
    this.setState({show_book: show_book, message: show_book ? <div class="success">Congratulation! Booking is available, you can book now!</div> : <div class="danger">{html_messsage}</div>});
  }

  TwowayChange(e){
    console.log("Two way", e.target.value);
    this.setState({two_way: e.target.value});
  }

  handlerlisting(ItemList) {
      if (ItemList && ItemList.length > 0) {
        return ItemList.map((item) => {
          return (            
            <li>
            <span className="check-km" title="Light Gallery Grid 1">
                <div className="dlab-post-thum"><img src={item} alt="" /></div>
            </span>
          </li>
          )
        })
      }
  }
  SetInput(e) {
    this.setState({ [e.target.name]: e.target.value });
    if(this.state.info.rent_type == 2){
      const quantity = e.target.name == "quantity" ? e.target.value : this.state.quantity;
      const from_date = e.target.name == "from_date" ? e.target.value : this.state.from_date;
      var to_date = moment(from_date, "YYYY-MM-DD").add(quantity, 'days').format("YYYY-MM-DD");
      this.setState({ to_date: to_date,[e.target.name]: e.target.value });
    }
  }
  
  render() {
    var price = 0;
   
    if(this.state.info.rent_type == 2){
      price = this.state.info.price_day ? this.state.quantity*this.state.info.price_day : 0;
    } else if(this.state.info.rent_type == 3) {
      price = this.state.info.price_seat ? this.state.quantity*this.state.info.price_seat : 0;
    } else {
      var distance = this.state.pre_state ? parseInt(this.state.pre_state.distance.value/1000) : 0;
      if(distance <= 25){
        price = (this.state.info.price ? this.state.info.price : 0);
      } else if(distance <= 50){
        price = (this.state.info.price_25_50_km ? this.state.info.price_25_50_km : 0);
      } else {
        price = distance*(this.state.info.price_50_500_km ? this.state.info.price_50_500_km : 0);
      }
    }

    price = this.state.two_way == 1 && this.state.info.one_way_discount > 0 ? price - parseInt(this.state.info.one_way_discount*price/100) : price;

    var picture_array = this.state.info.picture ? this.state.info.picture.split(',') : [];
    var available = this.state.info.available ? JSON.parse(this.state.info.available).join(', '): "";
    var photo_gallary = [];
    picture_array.forEach(photo => {  
      photo_gallary.push(process.env.REACT_APP_FILE_PATH+photo);   
    })
    var gallery = "";
    if(photo_gallary.length > 0){
      gallery =  <ul>{this.handlerlisting(photo_gallary)}</ul>
    }

    const video_option = {
      height: '390',
      width: '640',
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 0
      }
    };
    var video = "";
    if(this.state.info.video && this.state.info.video != ""){
    var videoid = this.state.info.video;
    var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = videoid.match(regExp);
    videoid = (match && match[2].length == 11) ? match[2] : false;
    
    video = <YouTube
        videoId={videoid}
        opts={video_option}
        onReady={this._onReady}
      />
    }


    var date_array = [];
    this.state.info.date_list.forEach(dateinfo => {  
      date_array.push(dateinfo.date);   
    })
    var shareUrl = window.location.href;
    var quantity_input = "";
    var to_date_input = "";
    if(this.state.info.rent_type == 1){ //per KM
      quantity_input = //<div class="input-group-btn col-md-12"><input disabled={this.state.show_book ? true : false} onChange={this.TwowayChange} type="checkbox" value="2" id="two_way" /> Two Way </div>
      <div>
        <div>
          <input type="radio" checked={this.state.two_way == "1"}  disabled={this.state.show_book ? true : false} onClick={this.TwowayChange} value="1" /> One Way <br />
          <input type="radio" checked={this.state.two_way == "2"}  disabled={this.state.show_book ? true : false} onClick={this.TwowayChange} value="2"  /> Two Way
        </div>
      </div>
    } else if(this.state.info.rent_type == 2){ //per Day
      quantity_input = <div class="input-group-btn col-md-12"><input disabled={this.state.show_book ? true : false} type="number" onChange={this.SetInput} class="form-control mytextbox" name="quantity" value={this.state.quantity} min="1" /></div>;
      to_date_input = <div class="input-group-btn col-md-12"><input type="date" class="form-control mytextbox" name="to_date" value={this.state.to_date} ></input></div>;
    } else if(this.state.info.rent_type == 3) {//per seat
      quantity_input = <div class="input-group-btn col-md-12"><input disabled={this.state.show_book ? true : false} type="number" max={this.state.info.seats} onChange={this.SetInput} class="form-control mytextbox" name="quantity" value={this.state.quantity} min="1" /></div>
    }
    var rent_title = "";
    var rent_price = "";
    if(this.state.info.rent_type == 1){ //KM
      rent_title = "Rent/KM";
      rent_price =  <ul className="icon-box-list list-col-4">
          <li><span>From 0-25/KM: {this.state.info.price}</span></li>
          <li><span>From 25-50/KM: {this.state.info.price_25_50_km}</span></li>
          <li><span>From 50_500/KM: {this.state.info.price_50_500_km}</span></li>
        </ul>;
    } else if(this.state.info.rent_type == 3) { //Seat
      rent_title = "Rent/Seat";
      rent_price =  this.state.info.price_seat+'/Seat';  
    } else { //day
      rent_title = "Rent/Day";
      rent_price =  this.state.info.price_day+'/Day';   
    }

    var bookpath = <button type="button" class="btn btn-info">{this.state.info.currency} {price}</button>
    if(this.state.allow_book){
      if(!this.state.show_book){
        bookpath = 
        <div className="tour-booking-form">
          <div className="tour-booking-head">
            <span>{this.state.info.currency} {price}</span>
          </div>
          <form className="row sp15"> 
          {quantity_input}
          <div class="form-group col-md-12">
            <span class="input-group-addon">Date</span>
            <input type="date" onChange={this.SetInput} class="form-control mytextbox" name="from_date" value={this.state.from_date} ></input>
          </div>
          {to_date_input}
          <div class="form-group-btn col-md-12">
            <button type="button" onClick={this.CheckBooking} class="btn btn-success">Check <span class="fa fa-caret-right"></span></button>
          </div>
        </form>
        </div>
      } else {
        bookpath = 
        <div className="tour-booking-form">
          <div className="tour-booking-head">
            <span>{this.state.info.currency} {price}</span>
          </div>
          <form className="row sp15"> 
          {quantity_input}
          <div class="input-group-btn col-md-12">
          <span class="input-group-addon">Date</span>
          <input type="date" disabled="true"  class="form-control mytextbox" name="from_date" value={this.state.from_date} ></input>
          </div>
          {to_date_input}
          <div class="input-group-btn col-md-12">
            <Link class="right"  to={{pathname:"/Booking/Ride/"+this.state.info.id, state:{info: this.state.info,quantity:this.state.quantity,two_way:this.state.two_way, from_date:this.state.from_date,to_date:this.state.to_date, pre_state: this.state.pre_state,price: price, rent_type: this.state.info.rent_type}}} >
              <button type="button" class="btn btn-success">Book <span class="fa fa-caret-right"></span></button>
            </Link>
          </div>
        </form>
        </div>
      }
    }

    return (
      <div>
        <Header />
        <div className="listing-details-head">
          <div className="container">
            <div className="listing-info-box">
              <div className="listing-info">
                <div className="listing-info-left">
                  <h3 className="title">{this.state.info.title}</h3>
                </div>
                <div className="listing-info-right text-center">
                  <FacebookShareButton url={shareUrl}>
                    {" "}
                    <FacebookIcon size={32} round={true} />{" "}
                  </FacebookShareButton>
                  <TwitterShareButton url={shareUrl}>
                    {" "}
                    <TwitterIcon size={32} round={true} />{" "}
                  </TwitterShareButton>
                  <LinkedinShareButton url={shareUrl}>
                    {" "}
                    <LinkedinIcon size={32} round={true} />{" "}
                  </LinkedinShareButton>
                  <EmailShareButton url={shareUrl}>
                    {" "}
                    <EmailIcon size={32} round={true} />{" "}
                  </EmailShareButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="listing-details-nav">
          <div className="container">
            <ul className="listing-nav nav justify-content-center">
              <li>
                <Link className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => { this.toggle('1'); }}><i className="la la-home"></i><span>Home</span>
                </Link>
              </li>
              <li>
                <Link className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggle('2'); }}><i className="la la-file-text"></i><span>Description</span>
                </Link>
              </li>
              <li>
                <Link className={classnames({ active: this.state.activeTab === '4' })}
                  onClick={() => { this.toggle('4'); }}><i className="la la-image"></i><span>Photos</span>
                </Link>
              </li>
              <li>
                <Link className={classnames({ active: this.state.activeTab === '5' })}
                  onClick={() => { this.toggle('5'); }}><i className="la la-video-camera"></i><span>Videos</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>


        <div className="section-full listing-details-content">
          <div className="container">
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <div className="row">
                  <div className="col-xl-8 col-lg-7 col-md-12">
                    <div className="content-box">
                      <div className="content-header">
                        <h3 className="title"><i className="la la-star-o m-r5"></i> Information</h3>
                      </div>
                      <div className="content-body row">
                        <div class="icon-content col-md-6">
                          <h6 class="text-uppercase m-tb0 dlab-tilte">Category</h6>
                          <p>{this.state.info.category}</p>
                        </div>
                        <div class="icon-content col-md-6">
                          <h6 class="text-uppercase m-tb0 dlab-tilte">{rent_title}</h6>
                          <p>{rent_price}</p>
                        </div>
                        <div class="icon-content col-md-6">
                          <h6 class="text-uppercase m-tb0 dlab-tilte">Vehicle Info</h6>
                          <p>{this.state.info.company}</p>
                        </div>
                        <div class="icon-content col-md-6">
                          <h6 class="text-uppercase m-tb0 dlab-tilte">Seats</h6>
                          <p>{this.state.info.seats}</p>
                        </div>
                        <div class="icon-content col-md-6">
                          <h6 class="text-uppercase m-tb0 dlab-tilte">Rent</h6>
                          <p>{this.state.info.rent_title}</p>
                        </div>
                        <div class="icon-content col-md-6">
                          <h6 class="text-uppercase m-tb0 dlab-tilte">City</h6>
                          <p>{this.state.info.city_title}</p>
                        </div>
                        <div class="icon-content col-md-6">
                          <h6 class="text-uppercase m-tb0 dlab-tilte">City From</h6>
                          <p>{this.state.info.city_from}</p>
                        </div>
                        <div class="icon-content col-md-6">
                          <h6 class="text-uppercase m-tb0 dlab-tilte">City To</h6>
                          <p>{this.state.info.city_to}</p>
                        </div>
                        <div class="icon-content col-md-6">
                          <h6 class="text-uppercase m-tb0 dlab-tilte">Vehicle Info</h6>
                          <p>{this.state.info.year+', '+this.state.info.plate+', '+this.state.info.company+', '+this.state.info.transmission+', '+this.state.info.engine}</p>
                        </div>

                        <div class="icon-content col-md-6">
                          <h6 class="text-uppercase m-tb0 dlab-tilte">Posted By</h6>
                          <p>{this.state.info.user_name}</p>
                        </div>
                      </div>
                      <Calendar
                            reset={false}
                            selected={date_array}
                            isMultiple={true}
                          />
                    </div>
                    <div className="content-box">
                      <div className="content-header">
                        <h3 className="title"><i className="la la-file-text m-r5"></i> description</h3>
                      </div>
                      <div className="content-body"
                        dangerouslySetInnerHTML={{
                          __html: this.state.info.description,
                        }} >
                      </div>
                    </div>
               
                    <div className="content-box">
                      <div className="content-header">
                        <h3 className="title"><i className="la la-file-image-o"></i> photos</h3>
                      </div>
                      <div className="content-body">
                        <div className="widget widget_gallery gallery-grid-4 lightgallery">
                          {gallery}
                        </div>
                      </div>
                    </div>
                    <div className="content-box">
                      <div className="content-header">
                        <h3 className="title"><i className="la la-video-camera m-r5"></i>videos</h3>
                      </div>
                      <div className="content-body">
                        <div className="widget widget_video video-grid-4">
                          <ul>
                            <li>
                              <div className="dlab-post-thum video-bx">
                                {video}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-5 col-md-12 sticky-top">
                    <aside className="side-bar listing-side-bar">
                      <div className="content-box">
                        <div className="content-header">
                          <h3 className="title"><i className="la la-bullhorn m-r5"></i>Booking Panel</h3>
                        </div>
                        <div className="content-body">
                          {this.state.message}
                          {bookpath}
                        </div>
                      </div>
                    </aside>
                  </div>

                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="content-box">
                      <div className="content-header">
                        <h3 className="title">description</h3>
                      </div>
                      <div className="content-body"
                        dangerouslySetInnerHTML={{
                          __html: this.state.info.description,
                        }} >
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
             
              <TabPane tabId="4">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="content-box">
                      <div className="content-header">
                        <h3 className="title">photos</h3>
                      </div>
                      <div className="content-body">
                        <div className="widget widget_gallery gallery-grid-4 lightgallery">
                          {gallery}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="5">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="content-box">
                      <div className="content-header">
                        <h3 className="title">videos</h3>
                      </div>
                      <div className="content-body">
                        <div className="widget widget_video video-grid-4">
                          <ul>
                            <li>
                              <div className="dlab-post-thum video-bx">
                                {video}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}


export default (RideDetail);
