import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import 'react-google-places-autocomplete/dist/assets/index.css';
import Autocomplete from 'react-google-autocomplete';
import Calendar from 'react-calendar-multiday';
import Select from 'react-select';
import Header from './../../../Layout/Header';
import Footer from './../../../Layout/Footer';
import ContactInfo from './../../Common/ContactInfo';
var bg3 = require('./../../../../images/banner/bnr1.jpg').default;
class CustomPackage extends Component {
    constructor(props) {
        super(props);
        var vehicle_list = [
            {"value":"Car", "label":"Car"},
            {"value":"Fortuner", "label":"Fortuner"},
            {"value":"Prado", "label":"Prado"},
            {"value":"BRV", "label":"BRV"},
            {"value":"Hiace", "label":"Hiace"},
            {"value":"A.C Saloon coaster 22 seater", "label":"A.C Saloon coaster 22 seater"},
            {"value":"A.C Luxury Coach 35 to 53 seater", "label":"A.C Luxury Coach 35 to 53 seater"}
        ];
        var seat_list = [
            {"value":"4 Seater", "label":"4 Seater"},
            {"value":"7 Seater", "label":"7 Seater"},
            {"value":"12 Seater", "label":"12 Seater"},
            {"value":"29 seater with 6 folding seat", "label":"29 seater with 6 folding seat"},
            {"value":"22 seater (Business)", "label":"22 seater (Business)"},
            {"value":"35 to 53 seater (A.C Luxury Coach )", "label":"35 to 53 seater (A.C Luxury Coach )"}
        ];
        var hotel_list = [
            {"value":"Normal Hotel", "label":"Normal Hotel"},
            {"value":"Standard Hotel", "label":"Standard Hotel"},
            {"value":"Luxurary Hotel", "label":"Luxurary Hotel"},
            {"value":"2 star Hotel", "label":"2 star Hotel"},
            {"value":"3 star Hotel", "label":"3 star Hotel"},
            {"value":"4 star Hotel", "label":"4 star Hotel"},
            {"value":"5 star Hotel", "label":"5 star Hotel"},
            {"value":"Camping stay", "label":"Camping stay"},
            {"value":"Woods Hut", "label":"Woods Hut"},
            {"value":"Camping pods", "label":"Camping pods"},
            {"value":"Camping pods", "label":"Camping pods"}
        ];
        this.state = { message: "",items:[],vehicle_list:vehicle_list,seat_list:seat_list,hotel_list:hotel_list,def_dates:[], vehicle: [],seat:[],hotel:[],
        name: localStorage.getItem("name"),mobile: localStorage.getItem("mobile"),email: localStorage.getItem("email") };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.addItem = this.addItem.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.createTasks = this.createTasks.bind(this);
        this.onReset = this.onReset.bind(this);
        this.reactToChange = this.reactToChange.bind(this);

        this.handleChangeVehicle = this.handleChangeVehicle.bind(this);
        this.handleChangeSeat = this.handleChangeSeat.bind(this);
        this.handleChangeHotel = this.handleChangeHotel.bind(this);
    };
    handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.target);
        data.append("destination_places", this.state.items.join(","));

        var vehicle_item = [];
        this.state.vehicle.forEach(function (obj) {
            vehicle_item.push(obj.value);
        });

        var seat_item = [];
        this.state.seat.forEach(function (obj) {
            seat_item.push(obj.value);
        });

        var hotel_item = [];
        this.state.hotel.forEach(function (obj) {
            hotel_item.push(obj.value);
        });

        data.append("vehicle_item", vehicle_item.join(","));
        data.append("seat_item", seat_item.join(","));
        data.append("hotel_item", hotel_item.join(","));
        this.SubmitData(data);
    };
  
    handleChangeHotel(option) {
        this.setState(state => {
          return {
            hotel: option
          };
        });
    }
    handleChangeSeat(option) {
        this.setState(state => {
          return {
            seat: option
          };
        });
    }
    handleChangeVehicle(option) {
        this.setState(state => {
          return {
            vehicle: option
          };
        });
    }

      

    addItem(item) {
        const items = [...this.state.items, item]
        this.setState({ items: items });
    }
    deleteItem = key => {
        var items = this.state.items;
        var filteredItems = items.filter(function (value, index, arr) { return value != key; });
        this.setState({
            items: filteredItems,
        })
    }
    createTasks(item) {
        return <li key={item} onClick={() => this.deleteItem(item)}>{item}</li>
    }

    reactToChange = (ob) => {
        this.setState({avilable: ob.selected});
        console.log("tnsbay",  ob.selected);
    }

    onReset() {
    this.setState({
        channels: {},
        currentChannel: 0
    })
    }

    SubmitData = (data) => {
        const request = new Request(process.env.REACT_APP_BASE_PATH + "custompackage", { method: "POST", body: data });
        fetch(request).then(res => {
            res.json().then(json => {
                if (json.error) {
                    this.setState({ message: <div class="danger">{json.message}</div> });
                } else {
                    this.setState({ message: <div class="success">{json.message}</div> });
                }
            });
        }).catch(err => {
            this.setState({ message: <div class="danger">{err.message}</div> });
        });
    }
    render() {
        const todoEntries = this.state.items;
        const listItems = todoEntries.map(this.createTasks);
        return (
            <div>
                <Header />
                <div className="dlab-bnr-inr overlay-black-middle" style={{ backgroundImage: "url(" + bg3 + ")", backgroundSize: 'cover' }}>
                    <div className="container">
                        <div className="dlab-bnr-inr-entry">
                            <h1 className="text-white">Customized Package</h1>
                        </div>
                    </div>
                </div>
                <div className="section-full content-inner bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <ContactInfo />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="p-a30 m-b30 	bg-gray clearfix">
                                    <h4>Request for Customized Package</h4>
                                    <div className="dzFormMsg">{this.state.message}</div>
                                    <form id="myform" onSubmit={this.handleSubmit}>
                                        <input type="hidden" value="Contact" name="dzToDo" />
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label>Name</label>
                                                    <input name="name" type="text" required className="form-control" placeholder="Your Name" defaultValue={this.state.name} />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label>Mobile</label>
                                                    <input name="mobile" type="mobile" className="form-control" required placeholder="Your Mobile" defaultValue={this.state.mobile} />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label>Email</label>
                                                    <input name="email" type="email" className="form-control" required placeholder="Your Email" defaultValue={this.state.email} />
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <label class="">Departure City</label>
                                                <div id="label_1">
                                                    <GooglePlacesAutocomplete
                                                        class="form-control"
                                                        initialValue={this.state.from_address}
                                                        onSelect={(place) => (
                                                            this.setState({
                                                                from_address: place.description,
                                                                from_city: place.terms[place.terms.length - 2].value,
                                                                from_country: place.terms[place.terms.length - 1].value
                                                            })
                                                        )} />
                                                    <input type="hidden" id="from_city" name="from_city" value={this.state.from_city} ></input>
                                                    <input type="hidden" id="from_country" name="from_country" value={this.state.from_country} ></input>
                                                </div>
                                            </div>
                                            <div className="form-group col-lg-12">
                                                <label>Visiting Places/Cities</label>
                                                <div class="input-group">
                                                    <div class="input-group-addon"><span class="fa fa-map-marker"></span></div>
                                                    <Autocomplete
                                                        class="form-control"
                                                        onPlaceSelected={(place) => {
                                                            this.addItem(place.address_components[0].long_name);
                                                        }}
                                                        types={['(regions)']}
                                                        autocomplete="off" />

                                                </div>
                                                <ul className="theList">{listItems}</ul>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label>Tour Duration Days</label>
                                                    <input type="number" name="days" className="form-control" required ></input>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label>Food Details</label>
                                                    <textarea name="food_detail" rows="4" className="form-control" required></textarea>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label>Tour Detail(Days Wise)</label>
                                                    <textarea name="tour_detail" rows="4" className="form-control" required></textarea>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label>Person (Adult)</label>
                                                    <input type="number" name="adult" className="form-control" required></input>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label>Child (Age 4-9 Year)</label>
                                                    <input type="number" name="child" className="form-control" required></input>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label>Infant (Age 1-4 Year)</label>
                                                    <input type="number" name="infant" className="form-control" required></input>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label>Couple</label>
                                                    <input type="number" name="couple" className="form-control" required></input>
                                                </div>
                                            </div>

                                            <div className="form-group col-lg-12">
                                                <label>Vehicle Type</label>
                                                <Select name="vehicle[]" options={this.state.vehicle_list}  required
                                                value={this.state.vehicle}
                                                onChange={this.handleChangeVehicle}  isMulti />
                                            </div>
                                            <div className="form-group col-lg-12">
                                                <label>Seat Capacity</label>
                                                <Select name="seat[]" options={this.state.seat_list}  required
                                                value={this.state.seat}
                                                onChange={this.handleChangeSeat} isMulti />
                                            </div>
                                            <div className="form-group col-lg-12">
                                                <label>Hotel Type</label>
                                                <Select name="hotel[]" options={this.state.hotel_list}  required
                                                value={this.state.hotel}
                                                onChange={this.handleChangeHotel} isMulti />
                                            </div>
                                            <div className="form-group col-lg-12">
                                                <label>Departure Dates (Select multiple)</label>
                                                <Calendar
                                                    reset={true}
                                                    isMultiple={true}
                                                    selected={this.state.def_dates}
                                                    onChange={this.reactToChange} />
                                            </div>

                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label>Other Detail</label>
                                                    <textarea name="other_detail" rows="4" className="form-control" required></textarea>
                                                </div>
                                            </div>
                                            


                                            <div className="col-lg-12">
                                                <button name="submit" type="submit" value="Submit" className="site-button "> <span>Submit</span> </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
export default CustomPackage;