import React, { Component } from 'react'
import HelplineFilter from './HelplineFilter';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Header from './../../Layout/Header';
import Footer from './../../Layout/Footer';

class HelplineListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = { page: null };
    props.match.params.class = "col-lg-12 col-md-12 col-sm-12 col-xs-12";
    this.setState({ page: props.match.params.id, list: [], filter_data: [] });

    this.handlerlisting = this.handlerlisting.bind(this);
    this.HelplineSearching = this.HelplineSearching.bind(this);
  }
  HelplineSearching(city_id) {
    var where = city_id != "" ? "&city=" + city_id : "";
    const request = new Request(process.env.REACT_APP_BASE_PATH + "assistant?status=1&mystatus=1&is_show=true" + where, { method: "GET" });
    fetch(request).then(res => {
      res.json().then(json => {
        this.setState({ list: json });
      });
    }).catch(err => {
      this.setState({ message: <div class="danger">{err.message}</div> });
      console.log("Error in Get menu", err.message);
    });
  };
  handlerlisting(ItemList) {
    const { classes } = this.props;
    const { state } = this;
    if (ItemList && ItemList.length > 0) {
      return ItemList.map((item) => {
        return (
          <div className="col-lg-4 col-md-6 d-lg-flex d-md-flex">
            <div className="p-a30 border m-b30 contact-area border-1 align-self-stretch ">
              <ul className="no-margin">
                <li className="icon-bx-wraper left m-b30">
                    <center><h6 className="text-uppercase m-tb0 dlab-tilte">{item.name}</h6></center>
                </li>
                <li className="icon-bx-wraper left m-b30">
                  <div className="icon-bx-xs border-1"> <Link className="icon-cell"><i className="ti-location-pin"></i></Link> </div>
                  <div className="icon-content">
                    <h6 className="text-uppercase m-tb0 dlab-tilte">Address:</h6>
                    <p>{item.address}</p>
                  </div>
                </li>
                <li className="icon-bx-wraper left  m-b30">
                  <div className="icon-bx-xs border-1"> <Link className="icon-cell"><i className="ti-email"></i></Link> </div>
                  <div className="icon-content">
                    <h6 className="text-uppercase m-tb0 dlab-tilte">Email:</h6>
                    <p>{item.email}</p>
                  </div>
                </li>
                <li className="icon-bx-wraper left">
                  <div className="icon-bx-xs border-1"> <Link className="icon-cell"><i className="ti-mobile"></i></Link> </div>
                  <div className="icon-content">
                    <h6 className="text-uppercase m-tb0 dlab-tilte">PHONE</h6>
                    <p>{item.mobile}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

        )
      })
    }
  }
  render() {
    return (
      <>
        <Header />
        <div className="listing-details-head">
          <div className="container">
            <div className="hotel-booking">
              <HelplineFilter page="listing" params={this.props.match.params} HelplineSearching={this.HelplineSearching}></HelplineFilter>
            </div>
          </div>
          <div className="container">
            <div className="hotel-booking">
              <h5>ABZO office Listing</h5>
              <div class="row">
                {this.handlerlisting(this.state.list)}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default (HelplineListing);
