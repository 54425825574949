import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Slick3 from './Slick3';

export default class Forgot extends Component {
    state = { value: 0 };
    constructor(props) {
        super(props);
        this.state.message = "";
        this.handleSubmit = this.handleSubmit.bind(this);
    };
    handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.target);
        this.SubmitData(data);
    };
    SubmitData = (data) => {
        const request = new Request(process.env.REACT_APP_BASE_PATH + "webforgot", { method: "POST", body: data });
        fetch(request).then(res => {
            res.json().then(json => {
                if (json.error) {
                    this.setState({ message: <div class="danger">{json.message}</div> });
                } else {
                    this.setState({ message: <div class="success">{json.message}</div> });
                }
            });
        }).catch(err => {
            this.setState({ message: <div class="danger">{err.message}</div> });
            console.log("Error in Get menu", err.message);
        });
    }

    render() {
        const { classes, drawerOpen, menuOptions } = this.props
        return (
            <div>
                <div class="page-content dlab-login font-roboto">
                    <div class="container-fluid p-lr0">
                        <div class="row m-lr0 login-form-box">
                            <div class="col-lg-4 p-lr0">
                                <div class="login-form">
                                    <div class="logo logo-header">
                                        <Link to={'./'}><img src={require('./../../images/logo-2.png')} alt="" /></Link>
                                    </div>
                                    <div id="forgot-password" class="tab-pane">
                                        <form id="myform" onSubmit={this.handleSubmit} >
                                            <h3>Forgot Password</h3>
                                            {this.state.message}

                                            <div className="form-group">
                                                <label>Email address</label>
                                                <input type="email" id="email" name="email" className="form-control" placeholder="Enter email" required />
                                            </div>

                                            <button type="submit" className="btn btn-primary btn-block">Submit</button>
                                            <p className="forgot-password text-right">
                                                <Link to="/login">Login</Link>
                                            </p>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-8 p-lr0">
                                <Slick3 />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}