import React from 'react';
import { ListGroup, ListGroupItem,Badge  } from 'react-bootstrap';
import { Link } from "react-router-dom";
import UserPopUp from '../Common/UserPopUp';
import moment from 'moment';



class NotificationList extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    var user_id = localStorage.getItem("id");
    this.state = {
      user_id: user_id,
      infolist: []
    }
    this.getInfo = this.getInfo.bind(this);
    this.getInfo();
  }

  getInfo = () => {
    const request = new Request(process.env.REACT_APP_BASE_PATH+"Notification?read=0&user_to="+this.state.user_id+"&sort=createdAt%20desc", {method: "GET"});
    fetch(request)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({infolist: result});
        },
        (error) => {
          this.setState({ error });
        }
      )
  }

  render() {
    const { error, infolist} = this.state;
    return(
      <div class="panel panel-primary">
        <div class="panel-body">
            <div>
            <h2 class="m-b10">Notification List</h2>
              <ListGroup>
                {infolist.map(info => (
                  <ListGroupItem> <Badge pill>{moment.unix(info.createdAt/1000).format("DD MMM YYYY hh:mm a")}</Badge> {info.title}</ListGroupItem>
                ))}
              </ListGroup>
            </div>
        </div>
      </div>
    )
  }
}

export default NotificationList;