import React, { Component } from 'react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { Link } from "react-router-dom";
import 'react-google-places-autocomplete/dist/assets/index.css';
import Select from 'react-select';

class GuideFilter extends React.Component {
  constructor(props) {
    super(props);
    var myclass = "col-lg-4 col-md-4 col-sm-12 col-xs-12";
    if (this.props.page == "home") {
      //myclass = "col-lg-6 col-md-6 col-sm-12 col-xs-12";
    }
    var today = new Date();
    var date = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
    var cat_id = this.props.params ? this.props.params.id : 0;
    this.state = { city_id: 0, city_list: [], date: date, page: this.props.page, cat_list: [], def_cat: cat_id, country: localStorage.getItem("country"), city: localStorage.getItem("city"), address: localStorage.getItem("address"), myclass: myclass };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount(){
    this.getList();
    this.getCityList();
    //if data posted from home page filter
    if(this.props.search_filter && Object.keys(this.props.search_filter).length > 0){
      this.setState(this.props.search_filter);
      this.btnSubmit.click();
    }
  }
  componentDidUpdate(prevProps) {
    var cat_id = this.props.params ? this.props.params.id : 0;
    var pre_id = prevProps.params ? prevProps.params.id : 0;
    if (cat_id !== pre_id) {
      this.setState({ def_cat: cat_id });
      if (this.props.page == "listing") {
        //this.btnSubmit.click();
      }
    }
  }
  getCityList = () => {
    const request = new Request(process.env.REACT_APP_BASE_PATH + "City?guide=1&allow_booking=1", { method: "GET" });
    fetch(request).then(res => {
      res.json().then(obj => {
        var listItems = [];
        Object.keys(obj).forEach(function (key) {
          listItems.push({ "value": obj[key].id, "label": obj[key].title });
        });
        this.setState({ city_list: listItems });
      });
    }).catch(err => {
      console.log("Error in Get menu", err);
    });
  };
  getList = () => {
    const request = new Request(process.env.REACT_APP_BASE_PATH + "ServiceSubCategory?ServiceCategoryId=3&status=true", { method: "GET" });
    fetch(request).then(res => {
      res.json().then(obj => {
        var listItems = [];
        listItems.push({ "value": "", "label": "All" });
        Object.keys(obj).forEach(function (key) {
          listItems.push({ "value": obj[key].id, "label": obj[key].title });
        });
        this.setState({ cat_list: listItems });
        return obj;
      });
    }).catch(err => {
      console.log("Error in Get menu", err);
    });
  };
  handleSubmit(event) {
    event.preventDefault();
    this.setState({ form: event.target });
    var post_data = new FormData(event.target);
    this.setState({ date: post_data.get("date") })
    this.state.date = post_data.get("date");
    this.props.GuideSearching(post_data, this.state);
  };
  render() {
    var button = <button type="submit" class="btn btn-primary btnsearch" ref={input => this.btnSubmit = input}><span class="fa fa-search"></span> Search </button>
    if (this.state.page == "home") {
      var search_filter = {
        city_id: this.state.city_id,
        def_cat: this.state.def_cat,
        address: this.state.address,
        city: this.state.city,
        country: this.state.country,
        from_date: this.state.from_date
      };
      button = <Link to={{pathname:'Guide/Tour/3/'+this.state.def_cat,search_filter:search_filter}} ><button type="submit" class="btn btn-primary btnsearch" id="btnsearch"><span class="fa fa-search"></span> Search </button></Link>
    }
    return (
      <div class="form-horizontal">
        <form name="tktSrchFrom" onSubmit={this.handleSubmit} class="tktSrchFrom" id="tktSrchFrom">
          <div class="row">
            <div class={this.state.myclass}>
              <label class="">Select City</label>
              <div id="label_1">
                <Select id="city_id" name="city_id"
                  value={this.state.city_list.filter(option => option.value == this.state.city_id)}
                  onChange={value => this.setState({ city_id: value.value })}
                  options={this.state.city_list} />
              </div>
            </div>
            <div class={this.state.myclass}>
              <label class="">Select Category</label>
              <div id="label_1">
                <Select id="category_id" name="category_id"
                  class="form-control"
                  value={this.state.cat_list.filter(option => option.value == this.state.def_cat)}
                  onChange={value => this.setState({ def_cat: value.value })}
                  options={this.state.cat_list} />
              </div>
            </div>

            <div class={this.state.myclass}>
              <label class="">City</label>
              <div id="label_1">
                <GooglePlacesAutocomplete
                  class="form-control"
                  initialValue={this.state.address}
                  onSelect={(place) => (
                    this.setState({
                      address: place.description,
                      city: place.terms[place.terms.length - 2].value,
                      country: place.terms[place.terms.length - 1].value
                    })
                  )} />
                <input type="hidden" id="city" name="city" value={this.state.city} ></input>
                <input type="hidden" id="country" name="country" value={this.state.country} ></input>
              </div>
            </div>


            <div class={this.state.myclass}>
              <label class="">Date</label>
              <div id="label_1">
                <input class="form-control" type="date" id="date" name="date" defaultValue={this.state.date} onChange={value => this.setState({ date: value.value })}  ></input>
              </div>
            </div>
            <div class={this.state.myclass}>
              <label class=""></label>
              <div class="input-group">
                {button}
              </div>
            </div>

          </div>
        </form>
      </div>
    );
  }
}

export default (GuideFilter);
