import React from 'react';
import { Table, Button, Alert } from 'react-bootstrap';
import { Link } from "react-router-dom";

class MyRideList extends React.Component {
  constructor(props) {
    super(props);
    var user_id = localStorage.getItem("id");
    this.state = {
      error: null,
      user_id: user_id,
      products: [],
      response: {},
      currency: localStorage.getItem("currency")
    }
  }

  componentDidMount() {
    const request = new Request(process.env.REACT_APP_BASE_PATH+"InfoPages?sort=id DESC&user_id="+this.state.user_id, {method: "GET"});
    fetch(request)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({products: result});
        },
        (error) => {
          this.setState({ error });
        }
      )
  }

  deleteProduct(productId) {
    if(window.confirm("Are you sure you want to delete? ")){
      const request = new Request(process.env.REACT_APP_BASE_PATH+"InfoPages/"+productId , {method: "DELETE"});
      fetch(request).then(res => {
      res.json().then(json => {	
              this.componentDidMount();
        });
      }).catch(err => {
          this.setState({message: <div class="danger">{err.message}</div>});
      });
    }
  }

  render() {
    const { error, products} = this.state;

    if(error) {
      return (
        <div>Error: {error.message}</div>
      )
    } else {
      return(
          <div class="panel panel-primary">
  
        <div class="panel-body table-responsive">
            <div >
            <h2 class="m-b10">My Information Pages List</h2>
          <Link  to="/member/infopageadd" class="right"><Button variant="success"><span class="fa fa-plus"></span>Add</Button></Link>
          {this.state.response.message && <Alert variant="info">{this.state.response.message}</Alert>}
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Category</th>
                <th>Sub Category</th>
                <th>From City</th>
                <th>To City</th>
                <th>View</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {products.map(product => (
                <tr key={product.id}>
                  <td>{product.id}</td>
                  <td>{product.category_id.title}</td>
                  <td>{product.subcategory_id.title}</td>
                  <td>{product.from_city}</td>
                  <td>{product.to_city}</td>
                  <td>{product.view}</td>
                  <td><span class="badge badge-info">{product.status}</span></td>
                  <td>
                    &nbsp;<Button variant="danger" onClick={() => this.deleteProduct(product.id)}><span class="fa fa-remove"></span></Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
   
        </div>
        </div>
      )
    }
  }
}

export default MyRideList;