import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";

class Dashboard extends React.Component {
  state = { value: 0 };
  constructor(props) {
    super(props);
    var user_id = localStorage.getItem("id");
    this.state = {slide_list:[],info:[],id: user_id};  
    this.handlerlisting = this.handlerlisting.bind(this);  
    this.getInfo(user_id);
    this.getSlides();
  }
  getInfo = id => {
    const request = new Request(
      process.env.REACT_APP_BASE_PATH + "GetServiceLimit/" + id,
      { method: "GET" }
    );
    fetch(request)
      .then(res => {
        res.json().then(obj => {
          this.setState({ info: obj });
          console.log("tnsbay", obj);
          return obj;
        });
      })
      .catch(err => {
        console.log("Error in Get menu", err);
      });
  };
  getSlides() {
    const request = new Request(
      process.env.REACT_APP_BASE_PATH + "slides?category=1",
      { method: "GET" }
    );
    fetch(request)
      .then(res => {
        res.json().then(obj => {
          this.setState({ slide_list: obj });
          return obj;
        });
      })
      .catch(err => {
        console.log("Error in Get menu", err);
      });
  };
  handlerlisting() {
    const { classes } = this.props;
    const { state } = this;
    if (this.state) {
      var ItemList = this.state.slide_list;
      if (ItemList && ItemList.length > 0) {
        return ItemList.map((item) => {
          var image_path = process.env.REACT_APP_FILE_PATH+"assets/uploads/picture/"+item.picture;
          return (            
              <Carousel.Item>
              <img
                className="d-block w-100"
                src={image_path}
                alt={item.title}
              />
              <Carousel.Caption>
                <h3>{item.title}</h3>
              </Carousel.Caption>
            </Carousel.Item>
          )
        })
      }
    }
  }
  render() {
    var index = 0;
    return (
      <div class="panel panel-primary">
        <div class="panel-body">
          <div class="page_heading">
            <span>My ABZO Dashboard</span>
          </div>
          <div class="row" style={{ padding: "20px" }}>
            <Link to='/member/RideApproval' class="col-lg-3 btn btn-primary" >Ride Approval</Link>
            <Link to='/member/StayApproval' class="col-lg-3 btn btn-success" >Stay Approval</Link>
            <Link to='/member/GuideApproval' class="col-lg-3 btn btn-info" >Guide Approval</Link>
            <a href={process.env.REACT_APP_FILE_PATH} target="_blank" class="col-lg-3 btn btn-info" >Admin Panel</a>
           
            <div class="col-lg-12">
              <Carousel>
                {this.handlerlisting()}
              </Carousel>
            </div>
            <div class="col-lg-12" style={{'marginTop':'10px'}}></div>
            <div class="col-lg-3">
              <div class="card text-white bg-primary">
                <div class="card-body">
                  <h5 class="card-title">
                    <i class="fa fa-cab"></i> {this.state.info.ride_limit}
                  </h5>
                  <p class="card-text">Allowed Ride</p>
                </div>
              </div>
            </div>

            <div class="col-lg-3">
              <div class="card text-white bg-success">
                <div class="card-body">
                  <h5 class="card-title">
                    <i class="fa fa-hotel"></i> {this.state.info.stay_limit}
                  </h5>
                  <p class="card-text">Allowed Stay</p>
                </div>
              </div>
            </div>

            <div class="col-lg-3">
              <div class="card text-white bg-info">
                <div class="card-body">
                  <h5 class="card-title">
                    <i class="fa fa-info-circle"></i>{" "}
                    {this.state.info.guide_limit}
                  </h5>
                  <p class="card-text">Allowed Guide</p>
                </div>
              </div>
            </div>

            <div class="col-lg-3">
              <div class="card text-white bg-danger">
                <div class="card-body">
                  <h5 class="card-title">
                    <i class="fa fa-clipboard"></i>{" "}
                    {this.state.info.package_limit}
                  </h5>
                  <p class="card-text">Allowed package</p>
                </div>
              </div>
            </div>

            <div class="col-lg-12" style={{'marginTop':'10px'}}></div>

            <div class="col-lg-3">
              <div class="card text-white bg-primary">
                <div class="card-body">
                  <h5 class="card-title">
                    <i class="fa fa-cab"></i> {this.state.info.ride_process}
                  </h5>
                  <p class="card-text">Process Ride</p>
                </div>
              </div>
            </div>

            <div class="col-lg-3">
              <div class="card text-white bg-success">
                <div class="card-body">
                  <h5 class="card-title">
                    <i class="fa fa-hotel"></i> {this.state.info.stay_process}
                  </h5>
                  <p class="card-text">Process Stay</p>
                </div>
              </div>
            </div>

            <div class="col-lg-3">
              <div class="card text-white bg-info">
                <div class="card-body">
                  <h5 class="card-title">
                    <i class="fa fa-info-circle"></i>{" "}
                    {this.state.info.guide_process}
                  </h5>
                  <p class="card-text">Process Guide</p>
                </div>
              </div>
            </div>

            <div class="col-lg-3">
              <div class="card text-white bg-danger">
                <div class="card-body">
                  <h5 class="card-title">
                    <i class="fa fa-clipboard"></i>{" "}
                    {this.state.info.package_process}
                  </h5>
                  <p class="card-text">Process package</p>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
