import React, { Component } from 'react'
import { Link, Redirect } from "react-router-dom";
import FacebookLogin from 'react-facebook-login';
import Slick3 from './../Pages/Slick3';
class Register extends React.Component {
    constructor(props) {
        super(props);
        var refer_by = this.props.match.params.id ? this.props.match.params.id : 0;
        this.state = { message: "", refer_by: refer_by, redirect: false };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.responseFacebook = this.responseFacebook.bind(this);
        this.SubmitData = this.SubmitData.bind(this);
    };
    handleSubmit(event) {
        event.preventDefault();
        this.refs.btnsubmit.setAttribute("disabled", "disabled");
        const data = new FormData(event.target);
        this.SubmitData(data);

    };

    SubmitData = (data) => {
        data.append("refer_by", this.state.refer_by);
        const request = new Request(process.env.REACT_APP_BASE_PATH + "UserSignUp", { method: "POST", body: data });
        fetch(request).then(res => {
            res.json().then(json => {
                this.refs.btnsubmit.removeAttribute("disabled");
                if (json.message) {
                    this.setState({ message: <div class="danger">{json.message}</div> });
                } else {
                    this.setState({ redirect: true });
                    this.setState({ message: <div class="success">Account Created successfully!</div> });
                }
            });
        }).catch(err => {
            this.setState({ message: <div class="danger">{err.message}</div> });
            console.log("Error in Get menu", err.message);
        });
    }

    responseFacebook = (response) => {
        var facebook = response.id;
        var email = response.email;
        var name = response.name;
        var picture = response.picture.data.url;
        const data = new FormData();
        data.append("name", name);
        data.append("email", email);
        //data.append("picture", picture);
        data.append("facebook", facebook);
        this.SubmitData(data);
        console.log("tnsbay fb responce", data, response);
    }

    render() {
        const { classes, drawerOpen, menuOptions } = this.props
        if (this.state.redirect) {
            return <Redirect to='/login' />
        }
        return (
            <div>
                <div class="page-content dlab-login font-roboto">
                    <div class="container-fluid p-lr0">
                        <div class="row m-lr0 login-form-box">
                            <div class="col-lg-4 p-lr0">
                                <div class="login-form">
                                    <div class="logo logo-header">
                                        <Link to={'./'}><img src={require('./../../images/logo-2.png')} alt="" /></Link>
                                    </div>
                                    <div id="register" class="tab-pane"></div>
                                    <form id="myform" onSubmit={this.handleSubmit}>
                                        <h3>Sign Up</h3>
                                        {this.state.message}
                                        <FacebookLogin
                                            appId={process.env.REACT_APP_FACEBOOK_ID}
                                            autoLoad={false}
                                            fields="name,email,picture"
                                            textButton=" Signup with Facebook"
                                            callback={this.responseFacebook}
                                            cssClass="my-facebook-button-class"
                                            icon="fa-facebook"
                                        />

                                        <div className="form-group">
                                            <label>Name</label>
                                            <input type="text" id="name" name="name" className="form-control" placeholder="Name" required />
                                        </div>

                                        <div className="form-group">
                                            <label>Mobile</label>
                                            <input type="phone" id="mobile" name="mobile" className="form-control" placeholder="Enter mobile" required />
                                        </div>

                                        <div className="form-group">
                                            <label>Email</label>
                                            <input type="email" id="email" name="email" className="form-control" placeholder="Enter email" required />
                                        </div>
                                        
                                        <button type="submit" ref="btnsubmit" className="site-button black m-r10">Sign Up</button>
                                        <p className="forgot-password text-right">
                                            Already registered <a href="#"><Link to="/login">Signin</Link></a>
                                        </p>
                                    </form>
                                </div>
                            </div>
                            <div class="col-lg-8 p-lr0">
                                <Slick3 />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}


export default (Register);
