import React, { Component } from 'react'
import Select from 'react-select';

class Invite extends React.Component {
  state = {value: 0};
  constructor(props){
    super(props);
    var user_id = localStorage.getItem("id");
    var invitelink = process.env.REACT_APP_SELF_PATH+"signup/"+user_id;
    this.state = {id: user_id, invitelink:invitelink, refer_list:{}};
    this.handlerlisting = this.handlerlisting.bind(this);
    this.getList();
  }
  getList = () => {
    const request = new Request(process.env.REACT_APP_BASE_PATH+"User?refer_by="+this.state.id, {	method: "GET"});
    fetch(request).then(res => {
      res.json().then(obj => {		
        this.setState({ refer_list: obj });
      });
    }).catch(err => {
        console.log("Error in Get menu", err);
    });
};
handlerlisting( ItemList ) {
  if(ItemList && ItemList.length > 0){
    return ItemList.map( (item) => {
      return (
        <li href="#" class="list-group-item">{item.id} - {item.name}</li>
      )
    })
  }
}

  render() {
    return (
        <div class="panel panel-primary">
        <div class="panel-body">
            <div class="ramadan-packages print-hajj-packages">
              <div class="page_heading"><span >Invite and Earn</span></div>
                  <div class="myform1 col-lg-12">
                    <div class="input-group">
                      <input id="referlink" type="text" class="form-control" name="referlink" defaultValue={this.state.invitelink}  />
                      <span class="input-group-addon"><i class="fa fa-user"></i> Copy</span>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <ul class="list-group">
                      <li href="#" class="list-group-item active">Your Refer List</li>
                      {this.handlerlisting(this.state.refer_list)}
                    </ul>
                  </div>
                  
            </div>
            
        </div>
    </div>
    );
  }
}


export default (Invite);
