import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import CKEditor from 'ckeditor4-react';

export default class CallBack extends Component {
    state = { value: 0 };
    constructor(props) {
        super(props);
        this.state = { message: "", description: '' };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onEditorChange1 = this.onEditorChange1.bind(this);
    };
    handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.target);
        data.append("description", this.state.description);
        this.SubmitData(data);

    };
    SubmitData = (data) => {
        const request = new Request(process.env.REACT_APP_BASE_PATH + "webcallback", { method: "POST", body: data });
        fetch(request).then(res => {
            res.json().then(json => {
                if (json.error) {
                    this.setState({ message: <div class="danger">{json.message}</div> });
                } else {
                    this.setState({ message: <div class="success">{json.message}</div> });
                }
            });
        }).catch(err => {
            this.setState({ message: <div class="danger">{err.message}</div> });
        });
    }
    onEditorChange1(evt) {
        this.setState({
            description: evt.editor.getData()
        });
    }


    render() {
        const { classes, drawerOpen, menuOptions } = this.props
        if (this.state.message != "") {
            return (
                <div className="widget widget_getintuch">
                    <h6 className="m-b15 h6 text-uppercase">Quick Callback</h6>
                    <div className="dlab-separator bg-white"></div>
                    <p>{this.state.message}</p>
                </div>
            )
        } else {
            return (
                <div className="widget widget_getintuch">
                    <h6 className="m-b15 h6 text-uppercase">Quick Callback</h6>
                    <div className="dlab-separator bg-white"></div>
                    <div className="search-bx">
                        <div className="dzFormMsg"></div>
                        <form method="post" className="myform row" onSubmit={this.handleSubmit} >
                            <input type="hidden" value="Contact" name="dzToDo" />
                            <div className="input-group col-lg-12">
                                <input name="name" type="text" required className="form-control" placeholder="Your Name" />
                            </div>
                            <div className="input-group col-lg-4">
                                <select id="network" name="network" className="form-control">
                                    <option value="jaaz">Jaaz</option>
                                    <option value="ufone">Ufone</option>
                                    <option value="warid">Warid</option>
                                    <option value="telenor">Telenor</option>
                                    <option value="ptcl">PTCL</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                            <div className="input-group col-lg-8">
                                <input name="mobile" type="text" required className="form-control" placeholder="Your Mobile" />
                            </div>
                            <div className="input-group col-lg-12">
                                <textarea name="description" rows="4" className="form-control" required placeholder="Your Message"></textarea>
                            </div>
                            <div className="input-group col-lg-12">
                                <button type="submit" className="btn btn-primary btn-block">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            );
        }
    }
}