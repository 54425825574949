import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import YouTube from 'react-youtube';
var moment = require('moment');

class InfoPageDetail extends React.Component {
  state = {value: 0};
  constructor(props){
    super(props);
    var id = this.props.match.params ? this.props.match.params.id : 0;
    this.state = {id:id, info:{}};    
    this.getInfo(id);
  }
  componentDidUpdate(prevProps) {
    var id = this.props.match.params ? this.props.match.params.id : 0;
    var pre_id = prevProps.match.params ? prevProps.match.params.id : 0;
    if (id !== pre_id) {
      this.getInfo(id);
    }
  }
  getInfo = (id) => {
		const request = new Request(process.env.REACT_APP_BASE_PATH+"InfoPages/"+id, {method: "GET"});
		fetch(request).then(res => {
					res.json().then(obj => {					                       
            this.setState({ info: obj});
						return obj;
					});
			}).catch(err => {
				console.log("Error in Get menu", err);
			});
	};
 
  render() {
    var picture_array = this.state.info.picture ? this.state.info.picture.split(',') : [];
    var photo_gallary = [];
    console.log("tnsbay", picture_array);
    picture_array.forEach(photo => {  
      photo_gallary.push({original:process.env.REACT_APP_BASE_PATH+photo, thumbnail: process.env.REACT_APP_BASE_PATH+photo});   
    })
    var gallery = "";
    if(photo_gallary.length > 0){
      gallery =  <ImageGallery items={photo_gallary} showFullscreenButton="false" useBrowserFullscreen="false" showPlayButton="false" />
    }

    return (
        <div class="col-sm-10 col-xs-12">
          <div class="panel panel-primary">
        <div class="panel-body">
            <div class="ramadan-Helplines print-hajj-Helplines">
            <div class="page_heading">
                  <span>{this.state.info.title}</span>
                </div>
                {gallery}
                <div dangerouslySetInnerHTML={ { __html: this.state.info.description } }></div>
            </div>
        </div>
        </div>
    </div>
    );
  }
}


export default (InfoPageDetail);
