import React, { Component } from 'react'
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';

class PageContent extends React.Component {
  state = { value: 0 };
  constructor(props) {
    super(props);
    this.state.info = [];
    this.getInfo(this.props.match.params.id);
  }
  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.getInfo(this.props.match.params.id);
    }
  }
  getInfo = (id) => {
    const request = new Request(process.env.REACT_APP_BASE_PATH + "pages/" + id, { method: "GET" });
    fetch(request).then(res => {
      res.json().then(obj => {
        this.setState({ info: obj });
        this.state.info = obj;
        return obj;
      });
    }).catch(err => {
      console.log("Error in Get menu", err);
    });
  };

  render() {
    var bg3 = this.state.info.banner ? this.state.info.banner : require('./../../images/banner/bnr1.jpg').default;
    return (
      <div>
        <Header />
        <div className="dlab-bnr-inr overlay-black-middle" style={{ backgroundImage: "url(" + bg3 + ")", backgroundSize: 'cover' }}>
          <div className="container">
            <div className="dlab-bnr-inr-entry">
              <h1 className="text-white">{this.state.info.title}</h1>
            </div>
          </div>
        </div>
        <div className="section-full content-inner bg-white">
          <div className="container">
            <div dangerouslySetInnerHTML={{ __html: this.state.info.description }}></div>
            <p>Tags: {this.state.info.keywords}</p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}


export default (PageContent);
